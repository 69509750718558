<ng-container *ngIf="useReadMore(); else contentTemplate">
  <div #bodyWrapper [style.max-height]="maxHeight()" class="content-wrapper col-12">
    <div #bodyContent class="row" data-rr-shared="true">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div [class.fade-out]="showFadeOut() && !isBodyExpanded() && isBodyLong()" [style.background]="background()"
         class="fade-box"></div>
  </div>
  <div *ngIf="isBodyLong() || isBodyExpanded()" class="read-more-btn">
    <p (click)="toggleBody()" *ngIf="!isBodyExpanded()">
      Læs mere
      <fa-icon [icon]="faAngleDown"></fa-icon>
    </p>
    <p (click)="toggleBody()" *ngIf="isBodyExpanded()">
      Læs mindre
      <fa-icon [icon]="faAngleUp"></fa-icon>
    </p>
  </div>
</ng-container>

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
