import {Injectable} from '@angular/core';
import {v4 as uuidv4} from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class RuntimeIdService {

  runtimeId: string;

  getRuntimeId() {
    if (!this.runtimeId) {
      this.runtimeId = uuidv4();
    }
    return this.runtimeId;
  }


}
