import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {StoryblokStylingService} from '../../../../../core/services/storyblok-styling.service';
import {isPlatformBrowser} from '@angular/common';
import {StoryblokDirective} from '../../../../../core/directives/storyblok.directive';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {VimeoService} from '../../../../../core/services/vimeo.service';

@Component({
  selector: 'app-storyblok-vimeo',
  templateUrl: './storyblok-vimeo.component.html',
  styleUrls: ['./storyblok-vimeo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective]
})
export class StoryblokVimeoComponent extends StoryblokBaseComponent implements OnInit, AfterViewInit {

  @Input() vimeoId: string;
  @Input() loop: boolean|undefined;
  @Input() _editable: any;
  @Input() general_styling: any;
  @Input() format_ratio?: string;

  @HostBinding('class') class: any;

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    private elementRef: ElementRef<HTMLElement>,
    private vimeoService: VimeoService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    super();
  }

  ngOnInit(): void {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12}, this.col);
  }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    const element = this.vimeoService.getVimeoElement(this.vimeoId, this.loop);
    this.elementRef.nativeElement.querySelector('.videoWrapper')?.appendChild(element);
  }
}
