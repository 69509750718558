import {Component, computed, input} from '@angular/core';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {StoryblokService} from '@core/services/storyblok.service';
import {Maybe, ProductAuthor} from '@core/interfaces/generated/graphql';

@Component({
  selector: 'app-product-author',
  templateUrl: './product-author.component.html',
  styleUrls: ['./product-author.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage
  ]
})
export class ProductAuthorComponent {
  productAuthor = input<Maybe<ProductAuthor> | undefined>()

  constructor(
    protected storyblokService: StoryblokService
  ) {
  }

  getAuthorImage = computed(() => {
    if (!this.productAuthor()?.image) {
      return {imgWidth: 675, imgHeight: 675, optimizedUrl: ''};
    }
    return this.storyblokService.getImageObject({filename: this.productAuthor()?.image ?? ''}, 120, 120);
  });
}

