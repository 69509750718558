import {ChangeDetectionStrategy, Component} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgOptimizedImage} from '@angular/common';
import {TrustpilotComponent} from '@layout/components/general/trustpilot/trustpilot.component';
import {RouterLink} from '@angular/router';
import {RouterContextDirective} from '@core/directives/router-context.directive';

@Component({
  selector: 'footer[app-footer]',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgOptimizedImage, TrustpilotComponent, RouterLink, RouterContextDirective]
})
export class FooterComponent {

  constructor(
    private modal: NgbModal
  ) {
  }

  newsletterSignup() {
    import('./components/footer-newsletter-modal/footer-newsletter-modal.component').then(comp => {
      this.modal.open(comp.FooterNewsletterModalComponent);
    })
  }

  memberConditions() {
    import('../../modals/basic-page-modal/basic-page-modal.component').then(comp => {
      const ref = this.modal.open(comp.BasicPageModalComponent);
      (ref.componentInstance).path = 'info/medlemsbetingelser';
    })
  }

}
