import {Component, Input} from '@angular/core';
import {BreadcrumbService} from '../../../../../../../core/services/breadcrumb.service';
import {NgIf} from '@angular/common';


@Component({
  selector: 'app-search-page-title',
  templateUrl: './search-page-title.component.html',
  styleUrls: ['./search-page-title.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class SearchPageTitleComponent {

  private _title: string;


  get title(): string {
    return this._title;
  }

  @Input()
  set title(value: string) {
    this._title = value;

    if (this.title) {
      if (this.breadcrumbService.isAutoChangeEnabled) {
        this.breadcrumbService.updateStyle('light');
      }
    }
  }

  constructor(
    private breadcrumbService: BreadcrumbService
  ) {
  }

}
