@if (ready()) {
  <div #productList [class.default-spacing]="useDefaultSpacing()" [style.grid-template-columns]="'repeat(auto-fill, minmax(' + hitMinWidthPx() + 'px, 1fr))'">
    @for (item of hits(); track trackByFn; let i = $index) {
      <ng-container *ngTemplateOutlet="hitsTemplate(); context: {item: item, index: i, sendEvent: state()?.sendEvent}"></ng-container>
    }
  </div>

  <div #bottomElement></div>

  @if (!state()?.isLastPage) {
    <app-loading-icon></app-loading-icon>
  }
}
