import {Component, computed, forwardRef, Inject, Input, signal, WritableSignal} from '@angular/core';
import {AlgoliaService} from '@core/services/algolia.service';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {AlgoliaFacetFilter} from '@core/types/algolia-filter';
import connectCurrentRefinements, {
  CurrentRefinementsConnectorParams,
  CurrentRefinementsConnectorParamsRefinement,
  CurrentRefinementsRenderState,
  CurrentRefinementsWidgetDescription
} from '../../../../../../../core/instantsearch-connectors/connectCurrentRefinments';
import {noop} from 'instantsearch.js/es/lib/utils';
import {ClearRefinementsComponent} from '../clear-refinements/clear-refinements.component';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {TrackEventService} from '@core/services/track-event.service';

type FilterListType = CurrentRefinementsConnectorParamsRefinement & { facetLabel: string };

@Component({
  selector: 'app-current-refinements',
  templateUrl: './current-refinements.component.html',
  styleUrls: ['./current-refinements.component.scss'],
  standalone: true,
  imports: [NgForOf, NgIf, ClearRefinementsComponent, AsyncPipe]
})
export class CurrentRefinementsComponent extends TypedBaseWidgetComponent<
  CurrentRefinementsWidgetDescription,
  CurrentRefinementsConnectorParams> {

  @Input() shownFilters: AlgoliaFacetFilter[];

  showAll = signal(false)

  public override state: WritableSignal<CurrentRefinementsRenderState> = signal({
    createURL: () => '#',
    refine: noop,
    items: [],
    canRefine: false,
  });

  currentFilters = computed(() => this.getCurrentFilters(this.state()))
  shouldShowAllBtn = computed(() => this.currentFilters().length > 5);


  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    private algoliaService: AlgoliaService,
    private trackEventService: TrackEventService
  ) {
    super('CurrentRefinements');
  }


  public override ngOnInit() {
    this.createWidget(
      connectCurrentRefinements,
      {},
      {
        $$widgetType: 'ais.currentRefinements',
      }
    );
    super.ngOnInit();
  }

  get filter() {
    let filters = this.shownFilters;
    filters = filters.filter(filter => ['refinement-list', 'ratings-filter', 'range-slider', 'range-input'].indexOf(filter.instantSearchType ?? '') !== -1);
    return this.getReviewersFilter(filters);
  }

  removeRefinement(event: any, refinement: any) {
    event.preventDefault();
    this.trackEventService.trackClickEvent('remove-refinement', {
      attribute: refinement.attribute,
      value: refinement.label
    });
    this.state().refine(refinement);
  }

  toggleShowAll() {
    this.showAll.update(d => !d);
    this.trackEventService.trackClickEvent('toggle-show-all-refinements', {showAll: this.showAll()});
  }

  trackBy(index: any, item: FilterListType) {
    return `${item.label}_${item.attribute}`;
  }

  showFilterAttribute(attribute: string): boolean {
    if (!this.shownFilters) {
      return true;
    }
    return this.getFilterAttributes().indexOf(attribute) !== -1;
  }

  getCurrentFilters(state: CurrentRefinementsRenderState) {
    let filters = state.items;
    filters = filters.filter(filter => this.showFilterAttribute(filter.attribute));
    return filters.reduce<FilterListType[]>((acc, filter) => {
      const filterArray = filter.refinements.map((refinement) => {
        return {
          facetLabel: filter.label,
          ...refinement
        }
      })
      acc.push(...filterArray);
      return acc;
    }, []);
  }

  shouldShowFilter(index: number, showAll: boolean) {
    return showAll || index < 5;
  }

  getLabel(item: string, subItem: string) {
    const itemLabel = this.filter.find(value => value.algoliaAttribute === item)?.label ?? item;
    return itemLabel + ': ' + this.algoliaService.getLabel(subItem);
  }

  getFilterAttributes(): string[] {
    return this.filter.map(filter => filter.algoliaAttribute) as string[];
  }

  protected getReviewersFilter(filters: AlgoliaFacetFilter[]) {
    filters.find(filter => filter.instantSearchType === 'ratings-filter')?.reviewers?.forEach((reviewer) => {
      filters.push({
        algoliaAttribute: 'ratings.' + reviewer,
        label: 'Anmelder',
        instantSearchType: 'range-slider',
        id: reviewer,
      })
    });
    return filters;
  }

}
