<ng-container *ngIf="products$|async as products">
  <app-carousel [uniqueToken]="'sb-product-' + getUniqueToken(products)" [appStoryblok]="_editable" [dataSource]="slides"
                [inputs]="carouselTile">
    <ngu-tile *nguCarouselDef="let item; let i = index">

      <div *ngIf="item.visma_id then thenblok; else productNotFound"></div>
      <ng-template #thenblok>
        <ng-container *ngIf="findProduct(item.visma_id, products) as product; else productNotFound">
          <a *ngIf="storyblokService.getImageObject(item.imgUrl, 1250) as imageObject"
             [routerLink]="'/' + product.nodePath">
            <img [height]="imageObject.imgHeight" [ngSrc]="imageObject.optimizedUrl" [priority]="i === 0"
                 [width]="imageObject.imgWidth" class="d-md-block">
          </a>
          <form [product]="product" class="rr-block" add-to-cart eventSourceName="product-slider"></form>
        </ng-container>
      </ng-template>

      <ng-template #productNotFound>
        <div class="text-center py-3">
          <h3>Product not found</h3>
        </div>
      </ng-template>

    </ngu-tile>
    <div NguCarouselPrev class="carousel-navigator carousel-left">
      <fa-icon [icon]="faChevronLeft"></fa-icon>
    </div>
    <div NguCarouselNext class="carousel-navigator carousel-right">
      <fa-icon [icon]="faChevronRight"></fa-icon>
    </div>
  </app-carousel>
</ng-container>


