<div class="results container">
  <div class="row">
    <div class="col-12 col-md-6">
      <ng-container *ngIf="hits.length > 0; else noResults">
        <app-hits-item *ngFor="let hit of hits; trackBy: tracker" [product]="hit"></app-hits-item>
      </ng-container>
    </div>
    <div class="categories col-12 col-md-6">
      <div class="category">
        <h6>Områder</h6>
        <app-refinement-list
          [transformItems]="transformAreaAndDistrictItems"
          [limit]="10"
          [showMore]="true"
          [showMoreLimit]="100"
          [sortBy]="['name:asc']"
          [searchable]="false"
          attribute="field_area"
          [trackEventName]="'quickSearchArea'"
        >
        </app-refinement-list>
      </div>
      <div class="category">
        <h6>Distrikter</h6>
        <app-refinement-list
          [limit]="10"
          [searchable]="false"
          [showMoreLimit]="100"
          [showMore]="true"
          [sortBy]="['name:asc']"
          [transformItems]="transformAreaAndDistrictItems"
          attribute="field_district"
          [trackEventName]="'quickSearchDistrict'"
        >
        </app-refinement-list>
      </div>
      <div class="category">
        <h6>Producenter</h6>
        <app-refinement-list
          attribute="field_producer"
          [limit]="10"
          [sortBy]="['name:asc']"
          [searchable]="false"
          [showMore]="true"
          [showMoreLimit]="100"
          [trackEventName]="'quickSearchProducer'"
        >
        </app-refinement-list>
      </div>
    </div>
    <a [queryParams]="{'Products[query]': getSearchValue()}" class="no-search-result col-12 text-center"
       [additionalContext]="{searchQuery: getSearchValue()}" [appRouterContext]="'quickSearchSeeAllResults'" [resetPage]="true"
       routerLink="/searchpage">
      {{ 'Se alle' }} {{ state?.results?.nbHits }} {{ 'resultater for' }} {{ getSearchValue() }}
    </a>
  </div>
</div>

<ng-template #noResults>
  <p class="no-search-result">Ingen resultater</p>
</ng-template>
