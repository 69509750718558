<div class="filter-container">
  <span class="filter-description">FILTRER EFTER:</span>

  <div [class.hide-extra-filters]="hideExtraFilters()"
       #topFilterFacets
       [style.overflow]="initFilterInProgress() ? 'hidden' : 'visible'"
       class="facets-settings rr-block">
    <div app-top-filter-facet
         #filterFacet
         (selectChange)="setSelectedAttribute(attribute.algoliaAttribute ?? '', filterFacet)"
         *ngFor="let attribute of shownFilters; trackBy: trackBy()"
         [attribute]="attribute"
         (hideFilter)="hideFilter($event, attribute.algoliaAttribute ?? '')"
         [isSelected]="selectedAttribute === attribute.algoliaAttribute"
         [hideExtraFilters]="hideExtraFilters()"
         [class.d-none]="attribute.hidden"
         [hasLoadedHits]="hasLoadedHits()"
    ></div>
    <button (click)="toggleFilters()" *ngIf="!hideExtraFilters()" class="limit-btn">
      Vis færre filtre
    </button>
  </div>
  <button (click)="toggleFilters()" [class.hidden]="!showMoreFilter()" class="limit-btn">
    Vis flere filtre
  </button>
</div>

