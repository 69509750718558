/// <reference types="@angular/localize" />

import {ApplicationRef, enableProdMode} from '@angular/core';
import {environment} from '@env/environment';
import 'hammerjs';
import {bootstrapApplication, enableDebugTools} from '@angular/platform-browser';
import {config} from '@fortawesome/fontawesome-svg-core';
import * as Sentry from '@sentry/angular-ivy';
import {AppComponent} from './app/app.component';
import {appConfig} from './app/app.config';

// Prevent fontawesome from adding its CSS since we did it manually above:
config.autoAddCss = false;

if (environment.production) {
  enableProdMode();
}
Sentry.init({
  enabled: environment.sentry.enabled,
  ignoreErrors: ['0 Unknown Error', 'Non-Error exception captured with keys: error, headers, message, name, ok', 'Can\'t find variable: _AutofillCallbackHandler'],
  dsn: environment.sentry.DSN,
  environment: environment.sentry.environment,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  denyUrls: [
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
});


bootstrapApplication(AppComponent, appConfig)
  .then(moduleRef => {
    if (!environment.production) {
      const applicationRef = moduleRef.injector.get(ApplicationRef);
      const componentRef = applicationRef.components[0];
      // allows to run `ng.profiler.timeChangeDetection();`
      enableDebugTools(componentRef);
    }
  })
  .catch(err => console.error(err));
