<div
  class="ais-RefinementList"
  *ngIf="showResults && state().items.length > 0"
>
  <ul class="ais-RefinementList-list">
    <ng-content/>
    <ng-container *ngFor="let item of items(); trackBy: trackBy">
      <label class="ais-RefinementList-label" *ngIf="!template">
        <input
          class="ais-RefinementList-checkbox"
          type="checkbox"
          value="{{item.value}}"
          (change)="preventDefault($event, item)"
          [checked]="item.isRefined"
        />
        <span class="ais-RefinementList-labelText">
          {{item.label}}
        </span>
        <span class="ais-RefinementList-count">{{item.count}}</span>
      </label>
      <ng-container *ngTemplateOutlet="template; context: {item: item}"></ng-container>
    </ng-container>
    <li (click)="toggleShowMore($event)" *ngIf="showMoreLimit && state().canToggleShowMore"
        class="ais-RefinementList-item">
      {{state().isShowingMore ? 'Vis færre' : 'Vis alle'}}
    </li>
  </ul>
</div>
