import {Component, Input} from '@angular/core';
import {CurrentRefinementsComponent} from '../current-refinements/current-refinements.component';
import {AlgoliaFacetFilter} from '@core/types/algolia-filter';

@Component({
  selector: 'app-top-filter-active',
  templateUrl: './top-filter-active.component.html',
  styleUrls: ['./top-filter-active.component.scss'],
  standalone: true,
  imports: [CurrentRefinementsComponent]
})
export class TopFilterActiveComponent {

  @Input() shownFilters: AlgoliaFacetFilter[] = [];

}
