import {Component, computed, forwardRef, Inject, Input} from '@angular/core';
import {NgForOf, NgIf} from '@angular/common';
import connectClearRefinements, {
  ClearRefinementsConnectorParams,
  ClearRefinementsWidgetDescription
} from '../../../../../../../core/instantsearch-connectors/connectClearRefinements';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {TrackEventService} from '@core/services/track-event.service';

@Component({
  selector: 'app-clear-refinements',
  templateUrl: './clear-refinements.component.html',
  styleUrls: ['./clear-refinements.component.scss'],
  standalone: true,
  imports: [NgForOf, NgIf]
})
export class ClearRefinementsComponent extends TypedBaseWidgetComponent<
  ClearRefinementsWidgetDescription,
  ClearRefinementsConnectorParams> {

  @Input() public resetLabel: string = 'Clear refinements';
  @Input() public includedAttributes: ClearRefinementsConnectorParams['includedAttributes'];

  isHidden = computed(() => !this.state()?.canRefine);

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    private trackEventService: TrackEventService
  ) {
    super('ClearRefinements');
  }

  public override ngOnInit() {
    this.createWidget(
      connectClearRefinements,
      {
        includedAttributes: this.includedAttributes,
      },
      {
        $$widgetType: 'ais.clearRefinements',
      }
    );

    super.ngOnInit();
  }

  public handleClick($event: MouseEvent) {
    $event.preventDefault();
    const state = this.state();
    if (state?.canRefine) {
      state.refine();
      this.trackEventService.trackClickEvent('clear-refinements');
    }
  }
}
