<div>
  <div class="groups">
    <ng-container *ngFor="let blok of body">
      <div>
        <p [class.selected]="blok.title === selected" (click)="selected = blok.title">{{blok.title}}</p>
      </div>
    </ng-container>
  </div>
  <ng-container *ngFor="let blok of body">
    <ng-container *ngFor="let item of blok.body">
      <div class="row" *ngIf="blok.title === selected">
        <ng-template *ngIf="components" [ngComponentOutlet]="components[item.component]" [ndcDynamicInputs]="item">
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</div>
