<ng-container *ngIf="filters()">
  @if (voucher_list() && loadingGroupVoucher()) {
    <div class="d-flex align-items-center flex-column p-4 text-center">
      <h2>Vent et øjeblik - vi er i gang med at hente gode priser til dig</h2>
      <app-loading-icon/>
    </div>
  } @else if (voucher_list() && !groupVoucherSignal().groupVoucher?.code) {
    <div class="d-flex align-items-center flex-column p-4 text-center">
      <h2>Hov! Du har ikke aktiveret et gruppekøb</h2>
      <button class="btn btn-primary mt-4" (click)="openGroupVoucherModal()">AKTIVÉR ET GRUPPEKØB</button>
    </div>
  } @else {
    <app-search-page
      [filter]="filters()"
      [voucherList]="voucher_list()"
      [hideDiscountFilter]="voucher_list()"/>
  }
</ng-container>
