import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  PLATFORM_ID,
  TrackByFunction
} from '@angular/core';
import {AlgoliaProduct} from '@core/interfaces/algolia-product';
import {AlgoliaState} from '@core/interfaces/algolia-state';
import {fromEvent, Subscription} from 'rxjs';

import {RefinementListComponent} from '../refinement-list/refinement-list.component';
import {RouterModule} from '@angular/router';
import {HitsItemComponent} from '../hits-item/hits-item.component';
import {isPlatformBrowser, NgForOf, NgIf} from '@angular/common';
import {RefinementListItem} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import {RouterContextDirective} from '@core/directives/router-context.directive';

@Component({
  selector: 'app-hits-container',
  templateUrl: './hits-container.component.html',
  styleUrls: ['./hits-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgForOf, RefinementListComponent, RouterModule, HitsItemComponent, RouterContextDirective]
})
export class HitsContainerComponent implements AfterViewInit, OnDestroy {

  @Input() hits: AlgoliaProduct[];
  @Input() state: AlgoliaState;
  @Input() searchQuery: string;

  @Output() disableResultsVisibility = new EventEmitter();
  private documentClickEvent: Subscription

  clickout(event: any) {
    this.disableResultsVisibility.emit();
  }

  constructor(
    protected ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
  }

  transformAreaAndDistrictItems(items: RefinementListItem[]) {
    return items.filter(({label}) => !label.includes('Øvrige'));
  }

  getSearchValue() {
    return this.searchQuery;
  }

  ngAfterViewInit() {
    this.initClickEvent();
  }

  ngOnDestroy() {
    this.documentClickEvent?.unsubscribe();
  }

  initClickEvent() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.documentClickEvent = fromEvent(window.document, 'click').subscribe(event => this.clickout(event))
    })
  }

  tracker: TrackByFunction<AlgoliaProduct> = (index: number, item: AlgoliaProduct) => item.objectID;

}
