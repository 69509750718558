<div class="rate-and-like text-center col-12">
  <h5>Gem til senere</h5>
  <div class="rate-and-like-row">
    <span>Min rating</span>
    <app-product-rate [product]="this.getProduct()"></app-product-rate>
  </div>
  <div class="rate-and-like-row remove-line">
    <span>Gem som favorit</span>
    <app-product-like [product]="this.getProduct()"></app-product-like>
  </div>
</div>
