import {Component, forwardRef, Inject, Input, OnInit} from '@angular/core';
import {RefinementListItem,} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import {NgFor, NgIf, NgOptimizedImage} from '@angular/common';
import {ProductService} from '@core/services/product.service';
import {RoutingService} from '@core/services/routing.service';
import {StoryblokService} from '@core/services/storyblok.service';
import {AlgoliaService} from '@core/services/algolia.service';
import {AddValuesToFacet} from '@core/utils/AddExtraFiltersList';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {
  InstantSearchBaseRefinementListComponent
} from '@core/instantsearch/components/instantsearch-base-refinement-list.component';
import {TrackEventService} from '@core/services/track-event.service';


@Component({
  selector: 'app-search-refinement-list',
  templateUrl: './refinement-list.component.html',
  styleUrls: ['./refinement-list.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgFor]
})
export class RefinementListComponent extends InstantSearchBaseRefinementListComponent implements OnInit {

  @Input() hideList: boolean = false;
  productStoryblok: any = undefined;

  trackBy(index: any, item: RefinementListItem) {
    return `${item.label}_${item.value}_${item.isRefined}`;
  }

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
      instantSearchInstance: InstantSearchComponent,
    protected routingService: RoutingService,
    public productService: ProductService,
    protected storyblokService: StoryblokService,
    protected algoliaService: AlgoliaService,
    protected trackEventService: TrackEventService
  ) {
    super(instantSearchInstance);
  }

  getIcon(label: string) {
    if (!this.productStoryblok) {
      return;
    }
    return this.productService.getItemIcon(
      (this.algoliaService.getIconName(label) as string).replace('icon-', ''),
      this.productStoryblok
    );
  }

  override ngOnInit() {
    this.storyblokService.getStoryblokIcons().then((icons) => {
      this.productStoryblok = icons;
    });
    super.ngOnInit();
  }

  clickRefinement(event: MouseEvent, item: RefinementListItem) {
    this.refine(event, item);
    this.trackEventService.trackClickEvent('refinement-list', {
      label: item.label,
      value: item.value,
      isRefined: item.isRefined,
      attribute: this.attribute,
    });
  }

  clickShowMore() {
    this.state().toggleShowMore();
    this.trackEventService.trackClickEvent('refinement-list', {
      label: 'show-more',
      attribute: this.attribute,
    });
  }

  override refine(event: MouseEvent, item: RefinementListItem) {
    const isRefined = item.isRefined;
    super.refine(event, item);

    if (!isRefined) {
      this.activateExtraFilters(item.label, event);
    }
  }

  getLabel(label: string | undefined): string | undefined {
    if (!label) {
      return;
    }
    return this.algoliaService.getLabel(label);
  }

  private activateExtraFilters(activateFilter: string, event: MouseEvent) {
    AddValuesToFacet.forEach(({facetName, facetValue, addFacetValues}) => {
      if (this.attribute !== facetName || activateFilter !== facetValue) {
        return;
      }
      addFacetValues.forEach((addFacetValue) => {
        const item = this.state().items.find(item => item.label === addFacetValue);
        if (!item || item?.isRefined) {
          return;
        }
        this.refine(event, {
          value: addFacetValue,
          count: 0,
          label: addFacetValue,
          isRefined: true,
          highlighted: addFacetValue,
        });
      });
    });
  }
}
