import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {AsyncPipe, Location, NgClass, NgIf, NgOptimizedImage} from '@angular/common';
import {PrintService} from '@core/services/print.service';
import {CountdownLiveComponent} from '@layout/components/product/countdown-live/countdown-live.component';
import {RouterModule} from '@angular/router';
import {ProductImagesComponent} from '../product-image/product-images.component';
import {ProductSaveLabelComponent} from '@layout/components/product/product-save-label/product-save-label.component';
import {ProductRightComponent} from '../product-right/product-right.component';
import {ProductDescriptionComponent} from '../product-description/product-description.component';
import {ProductFoodsComponent} from '../product-foods/product-foods.component';
import {ProductUnitsComponent} from '../product-units/product-units.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ExistsPipe} from '@core/pipes/exists.pipe';
import {StoryblokService} from '@core/services/storyblok.service';
import {ProductTagsComponent} from '@layout/components/product/product-tags/product-tags.component';
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {ProductTopCtaComponent} from '@layout/components/product/product-top-cta/product-top-cta.component';

@Component({
  selector: 'app-product-left',
  templateUrl: './product-left.component.html',
  styleUrls: ['./product-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgClass, AsyncPipe, ExistsPipe, CountdownLiveComponent, RouterModule, ProductImagesComponent, ProductSaveLabelComponent, ProductRightComponent, ProductDescriptionComponent, ProductFoodsComponent, ProductUnitsComponent, FontAwesomeModule, ProductTagsComponent, ProductTopCtaComponent]
})
export class ProductLeftComponent {

  @Input() product: WineProduct | undefined;

  constructor(
    private _location: Location,
    public printService: PrintService,
    public storyblokService: StoryblokService
  ) {
  }

  backClicked() {
    this._location.back();
  }

  printPDF() {
    this.printService.printDocument();
  }

  protected readonly faAngleLeft = faAngleLeft;
}
