export const AddValuesToFacet = [
  {
    facetName: 'district',
    facetValue: 'Bourgogne',
    addFacetValues: [
      'Saint-Aubin',
      'Chablis',
      'Corton',
      'Macon-Villages',
      'Mercurey',
      'Rully',
      'Santenay',
      'Pommard',
      'Meursault',
      'Clos de Vougeot',
      'Cote de Nuits',
      'Côte de Nuits-Villages',
      'Maranges',
      'Volnay',
      'Beaune',
      'Beaujolais',
    ]
  },
  {
    facetName: 'district',
    facetValue: 'Bordeaux',
    addFacetValues: [
      'Saint-Émilion',
      'Saint-Emilion',
      'Saint Emilion',
      'BORDEAUX EN PRIMEUR',
      'Margaux',
      'Pomerol',
      'Pauillac',
      'Saint-Julien',
      'Saint Julien',
      'Saint-Estèphe',
      'Saint Estephe',
      'Saint-Espèphe',
      'Pessac-Leognan',
      'Haut Medoc',
      'Haut-Médoc',
      'Sauternes',
      'Castillon-Côtes de Bordeaux',
      'Lalande de Pomerol',
      'Lussac-Saint-Emilion',
      'Lussac Saint Emilion',
      'Moulis En Medoc',
    ]
  },
  {
    facetName: 'district',
    facetValue: 'Toscana',
    addFacetValues: [
      'Brunello di Montalcino',
      'Bolgheri',
      'Chianti',
    ]
  },
  {
    facetName: 'district',
    facetValue: 'Piemonte',
    addFacetValues: [
      'Barolo',
      'Langhe',
    ]
  },
  {
    facetName: 'district',
    facetValue: 'Rhône',
    addFacetValues: [
      'Châteauneuf-du-Pape',
      'Chateauneuf du Pape',
      'Côtes du Rhône',
      'Côtes du Rhône-Villages',
      'Gigondas',
      'Cairanne',
      'Lirac',
      'Côtes du Ventoux',
      'Rasteau',
      'Vacqueyras',
      'Beaumes de Venises',
      'IGP Vaucluse',
    ]
  }
];
