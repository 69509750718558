import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import {PageInfo, ProductPage, WineProduct} from '@core/interfaces/generated/graphql';
import {RoutingService} from '@core/services/routing.service';
import {UserService} from '@core/services/user.service';
import {environment} from '@env/environment';
import {Router} from '@angular/router';
import {PrintService} from '@core/services/print.service';
import {ToastService} from '@core/services/toast.service';

import {ProductLeftComponent} from './components/product-left/product-left.component';
import {ProductRightComponent} from './components/product-right/product-right.component';
import {PrintPdfComponent} from './components/print-pdf/print-pdf.component';
import {ExistsPipe} from '@core/pipes/exists.pipe';
import {AsyncPipe, isPlatformBrowser, NgForOf, NgIf} from '@angular/common';
import {ProductService} from '@core/services/product.service';
import {GoogleService} from '@core/services/google.service';
import {LoadingIconComponent} from '@layout/components/general/loading-icon/loading-icon.component';
import {ProductRecommendationsComponent} from './components/product-recommendations/product-recommendations.component';
import {ProductTrendingItemsComponent} from './components/product-trending-items/product-trending-items.component';
import {GridService} from '@core/services/grid.service';
import {TrackEventService} from '@core/services/track-event.service';
import {ProductStickyBuyBarComponent} from './components/product-sticky-buy-bar/product-sticky-buy-bar.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, NgIf, AsyncPipe, ProductLeftComponent, ProductRightComponent, PrintPdfComponent, ExistsPipe, LoadingIconComponent, ProductRecommendationsComponent, ProductTrendingItemsComponent, ProductStickyBuyBarComponent]
})
export class ProductComponent implements OnInit {
  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() pageInfo: PageInfo;

  isAdmin: Signal<boolean> = this.userService.isAdmin();
  adminProductData: WritableSignal<WineProduct | null | undefined> = signal(null);

  stock: Signal<number | null> = computed(() => {
    if (!this.isAdmin()) {
      return null;
    }
    return this.adminProductData()?.stock || null
  });
  baPercentage: Signal<string | null> = computed(() => {
    if (!this.isAdmin()) {
      return null;
    }
    return this.adminProductData()?.baPercentage || null
  });

  productUpdated: boolean;
  errorMessage: string;
  recommendationsHits: number;

  constructor(
    private routingService: RoutingService,
    private router: Router,
    private userService: UserService,
    private productService: ProductService,
    private toastService: ToastService,
    public printPdf: PrintService,
    private googleService: GoogleService,
    public gridService: GridService,
    private trackEventService: TrackEventService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    effect((onCleanup) => {
      if (this.isAdmin()) {
        const sub = this.productService.getProductAdminDataByVismaId(this.currentProduct?.vismaProductNumber ?? '').subscribe((data) => {
          this.adminProductData.set(data);
        });
        onCleanup(() => {
          sub.unsubscribe();
        });
      }
    }, {allowSignalWrites: true});
  }

  get currentProduct(): WineProduct | undefined {
    const pageData = this.pageInfo?.pageData as ProductPage;
    if (!pageData?.product) {
      return undefined;
    }
    return pageData?.product;
  }

  get isMobile() {
    return !this.gridService.isLarge;
  }

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  get nodePath() {
    return this.routingService.getURL();
  }

  ngOnInit(): void {
    this.productUpdated = !!this.routingService.getQueryParams()['updated'];
    if (this.currentProduct) {
      this.googleService.productView(this.currentProduct);
      this.trackEventService.productView(this.currentProduct);
    }
  }

  removeUpdated() {
    this.router.navigate([], {
      queryParams: {
        updated: null,
      },
      queryParamsHandling: 'merge'
    });
  }

  getProductEditUrl(id: string | undefined) {
    return `${environment.supervin.api}supervin_admin/catalog/product/edit/id/${id}`;
  }

  getProductPreviewURL(product: WineProduct | undefined) {
    return `https://${environment.supervin.previewHostname}/${product?.nodePath}?preview=true`;
  }

  copyToClipboard(text: any): void {
    navigator.clipboard.writeText(text).then((e) => {
        this.toastService.showDefault(`${text} has been copied`, {classname: 'bg-success text-light'});
      }
    ).catch((e) => this.toastService.showDanger(e));
  }

  get showRecommendations() {
    const allowedTypes = ['Vin', 'Øl', 'Spiritus'];
    const enoughRecommendationsHits = !(this.recommendationsHits < 3)
    return this.currentProduct && allowedTypes.includes(this.currentProduct.meta?.productType || '') && enoughRecommendationsHits;
  }

  get showTrendingProducts() {
    return this.currentProduct && !this.showRecommendations;
  }

  recommendationsHitsChange($event: number) {
    this.recommendationsHits = $event;
  }
}
