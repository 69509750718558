import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderMenuService {

  _activeMenu = new BehaviorSubject<string | null>(null);

  activeMenu$ = this._activeMenu.asObservable();

  setActiveMenu(menu: string | null) {
    this._activeMenu.next(menu);
  }

  toggleMenu(menu: string) {
    if (this._activeMenu.value === menu) {
      this._activeMenu.next(null);
    } else {
      this._activeMenu.next(menu);
    }
  }
}
