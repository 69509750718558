<div *ngIf="giftcardAdded" class="d-flex justify-content-center">
  <p class="m-0 mb-1 text-orange">Gavekortet er tilføjet til kurven</p>
</div>

<form (ngSubmit)="formSubmit()"
      [appStoryblok]="_editable"
      [formGroup]="formGroup"
      [loading]="loading"
      app-loading
      class="form-container">
  <div class="d-flex flex-column">
    <div class="input-group has-validation">
      <div class="form-floating">
        <input [formField]="formGroup.get('amount')" appFormValidationClass class="form-control"
               formControlName="amount"
               id="giftcard" placeholder="fx 500" type="number">
        <label for="giftcard">Beløb</label>
      </div>
    </div>
    <div *ngIf="formGroup.controls.amount.getError('min')" class="invalid-feedback">
      Beløb skal være over 50 kr
    </div>
    <div *ngIf="formGroup.controls.amount.getError('max')" class="invalid-feedback">
      Beløb skal være under 10.000 kr
    </div>
    <div *ngIf="formGroup.controls.amount.getError('pattern')" class="invalid-feedback">
      Kun hele beløb, fx. "1234"
    </div>
  </div>
  <div>
    <button class="btn btn-primary">LÆG I KURV</button>
  </div>
</form>
