<div
  *ngIf="!isHidden()"
  [class]="cx()"
>
  <button
    (click)="handleClick($event)"
    [class]="cx('button') + (!state()?.hasRefinements ? (' ' + cx('button', 'disabled')) : '')"
    [disabled]="!state()?.canRefine"
  >
    {{resetLabel}}
  </button>
</div>
