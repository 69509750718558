import {Component, computed, input, signal} from '@angular/core';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {StarEmptyIconComponent} from '@core/svg-icons/star-empty-icon.component';
import {HeartEmptyIconComponent} from '@core/svg-icons/heart-empty-icon.component';
import {CustomerReviewsAndWishlistService} from '@core/services/customer-reviews-and-wishlist.service';
import {HeartSolidIconComponent} from '@core/svg-icons/heart-solid-icon.component';
import {StarSolidIconComponent} from '@core/svg-icons/star-solid-icon.component';

@Component({
  selector: 'app-product-like',
  templateUrl: './product-like.component.html',
  styleUrls: ['./product-like.component.scss'],
  standalone: true,
  imports: [
    StarEmptyIconComponent,
    HeartEmptyIconComponent,
    HeartSolidIconComponent,
    StarSolidIconComponent
  ]
})

export class ProductLikeComponent {

  product = input.required<WineProduct | null>();
  wishlist = this.customerReviewsAndWishlistService.wishlist;

  isItemInWishlist = computed(() => {
    return this.wishlist().some(product => product?.id === this.product()?.id);
  });
  useAsButton = input<boolean>(false);
  heartClicked = signal(false);

  constructor(
    private customerReviewsAndWishlistService: CustomerReviewsAndWishlistService,
  ) {
  }

  toggleWishlist(removeFromWishlist: boolean) {
    this.customerReviewsAndWishlistService.adjustWishlist(this.product()!, removeFromWishlist);
    if (!removeFromWishlist) {
      this.heartClicked.set(true);
      setTimeout(() => {
        this.heartClicked.set(false);
      }, 300);
    }
  }
}
