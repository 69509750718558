<span class="label">PRISEN UDLØBER OM: </span>
<span class="timer">
  <strong>{{ days }}</strong>
  dage
  <strong>{{ hours }}</strong>
  timer
  <strong>{{ minutes }}</strong>
  min
  <strong>{{ seconds }}</strong>
  sek
</span>
