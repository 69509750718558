import {Component, forwardRef, Inject, input, OnInit, TrackByFunction} from '@angular/core';
import {RefinementListItem} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';

import {RouterModule} from '@angular/router';
import {NgForOf, NgIf} from '@angular/common';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {
  InstantSearchBaseRefinementListComponent
} from '@core/instantsearch/components/instantsearch-base-refinement-list.component';
import {ALGOLIA_SEPERATOR} from '@core/services/algolia.service';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {TrackEventService} from '@core/services/track-event.service';

@Component({
  selector: 'app-refinement-list',
  templateUrl: './refinement-list.component.html',
  styleUrls: ['./refinement-list.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, RouterModule, RouterContextDirective]
})
export class RefinementListComponent extends InstantSearchBaseRefinementListComponent implements OnInit {

  trackEventName = input<string>();

  constructor(
    private trackEventService: TrackEventService,
    @Inject(forwardRef(() => InstantSearchComponent))
      instantSearchInstance: InstantSearchComponent
  ) {
    super(instantSearchInstance);
  }

  override ngOnInit() {
    const transformItemsFunc = this.transformItems;
    this.transformItems = (items, metadata) => {
      if (transformItemsFunc) {
        items = transformItemsFunc(items, metadata);
      }
      return items.map(item => {
        const [value, label] = item.value.split(ALGOLIA_SEPERATOR);
        return {...item, value, label} as any;
      }).filter(({label}) => label);
    };
    super.ngOnInit();
  }

  toggleShowMore(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.trackEventService.trackClickEvent('show_more_refinement_list', {
      refinementList: this.attribute,
      eventName: this.trackEventName()
    });
    this.state().toggleShowMore();
  }

  trackBy: TrackByFunction<RefinementListItem> = (index, item) => {
    return item.label;
  }

}
