@if (!hide()) {
  <ng-container *ngIf="initialized|async">
    <div *ngIf="reviewers()" class="rating-wrapper">
      <p class="mb-1">Point</p>
      <app-rating-points-range
        (rangeChange)="onRangeChange($event)"
        [max]="100"
        [min]="80"
        [range]="range"
      ></app-rating-points-range>

      <p class="mb-1 mt-1">Anmelder</p>
      <app-rating-review-filter
        (checkedChange)="onInputChange($event, reviewer)"
        *ngFor="let reviewer of reviewers()"
        [checked]="isReviewerChecked(reviewer)"
        [title]="reviewer"
      ></app-rating-review-filter>
    </div>
  </ng-container>
}
