import {ChangeDetectorRef, Component, HostBinding, Input, OnInit} from '@angular/core';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {DynamicModule} from 'ng-dynamic-component';
import {NgComponentOutlet, NgForOf, NgIf} from '@angular/common';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {FormValidationClassDirective} from '@core/directives/form-validation-class.directive';
import {CartService} from '@core/services/cart.service';
import {LoadingComponent} from '@layout/components/general/loading/loading.component';
import {
  AddToCartPopupComponent
} from '@layout/components/product/add-to-cart/add-to-cart-popup/add-to-cart-popup.component';
import {GoogleService} from '@core/services/google.service';


@Component({
  selector: 'app-buy-giftcard',
  templateUrl: './storyblok-buy-giftcard.component.html',
  styleUrls: ['./storyblok-buy-giftcard.component.scss'],
  standalone: true,
  imports: [StoryblokDirective, DynamicModule, NgForOf, NgIf, NgComponentOutlet, ReactiveFormsModule, StoryblokDirective, StoryblokDirective, FormValidationClassDirective, LoadingComponent, AddToCartPopupComponent]
})
export class StoryblokBuyGiftcardComponent implements OnInit {
  @Input() _editable: any;
  @Input() general_styling: any;
  @Input() productId: number = 20000;
  @HostBinding('class') class: any;

  loading = false;
  giftcardAdded = false;

  formGroup: FormGroup<{
    amount: FormControl<string | null>;
  }>;

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    private cartService: CartService,
    private changeDetectorRef: ChangeDetectorRef,
    private googleService: GoogleService
  ) {
    this.formGroup = new FormGroup({
      amount: new FormControl('', {
        validators: [
          Validators.pattern('^[0-9]*$'),
          Validators.min(50),
          Validators.max(10000),
          Validators.required
        ]
      })
    });
  }

  ngOnInit() {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, {
      desktop: {
        'margin-bottom': '.5rem',
        'margin-top': '.5rem'
      }
    }, {'col-': 12});
  }

  formSubmit() {
    if (!this.formGroup.value.amount) {
      return;
    }
    const giftcardAmount = +this.formGroup.value.amount;
    if (this.formGroup.valid && giftcardAmount > 0) {
      this.loading = true;
      this.giftcardAdded = false;
      this.cartService.addToCart('', this.productId, 1, 'giftcard-page', giftcardAmount * 100).then(() => {
        this.formGroup.reset({amount: ''});
        this.giftcardAdded = true;
        this.googleService.addGiftcardToCartEvent(giftcardAmount);
      }).finally(() => {
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      }).catch((reason) => {
        throw reason;
      });
    }
  }

}
