import {Injectable, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {

  constructor(
    private sanitizer: DomSanitizer,
  ) {
  }

  public getVimeoIframeURL(vimeoId: string, loop: boolean|undefined): string {
    let url = `https://player.vimeo.com/video/${vimeoId}?texttrack=da`;
    if (loop !== false) {
      url += '&loop=1';
    }
    return url;
  }

  public getVimeoElement(vimeoId: string, loop: boolean|undefined): HTMLIFrameElement {
    const url = this.getVimeoIframeURL(vimeoId, loop);
    const urlSafe = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(url));
    const iframe = document.createElement('iframe');
    iframe.frameBorder = '0';
    iframe.allowFullscreen = true;
    iframe.setAttribute('src', urlSafe as string);
    return iframe;
  }

}
