import {Component, computed, input, signal} from '@angular/core';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {StarEmptyIconComponent} from '@core/svg-icons/star-empty-icon.component';
import {CustomerReviewsAndWishlistService} from '@core/services/customer-reviews-and-wishlist.service';
import {StarSolidIconComponent} from '@core/svg-icons/star-solid-icon.component';

@Component({
  selector: 'app-product-rate',
  templateUrl: './product-rate.component.html',
  styleUrls: ['./product-rate.component.scss'],
  standalone: true,
  imports: [
    StarEmptyIconComponent,
    StarSolidIconComponent
  ]
})

export class ProductRateComponent {

  product = input.required<WineProduct | null>();
  customerReviews = this.customerReviewsAndWishlistService.customerReviews;

  ratingClicked = signal(false);

  productCustomerReview = computed(() => {
    return this.customerReviews().find(review => review?.product?.id === this.product()?.id);
  });

  constructor(
    private customerReviewsAndWishlistService: CustomerReviewsAndWishlistService,
  ) {
  }

  addReviewProduct(product: WineProduct, rating: number, removeReview: boolean = false) {
    this.ratingClicked.set(true);
    this.customerReviewsAndWishlistService.addReviewProduct(product, rating, removeReview);

    setTimeout(() => {
      this.ratingClicked.set(false);
    }, 600);
  }
}
