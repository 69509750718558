import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {GroupVoucherService} from './groupVoucher.service';

export type StoryblokActionCallback = (component: any) => PromiseLike<any> | any;

@Injectable({
  providedIn: 'root'
})
export class StoryblokActionsService {

  protected actions: { [componentName: string]: StoryblokActionCallback } = {};

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private voucherService: GroupVoucherService,
  ) {
    this.actions = {
      'activate_voucher': this.getActivateActionCallback()
    }
  }

  public async executeActions(actions: any[]) {
    const actionCallbacks = actions.map(action => ({
      callback: this.actions[action.component],
      data: action
    })).filter(action => !!action.callback);
    for (const action of actionCallbacks) {
      const {callback, data} = action;
      await callback(data);
    }
  }

  protected getActivateActionCallback(): StoryblokActionCallback {
    return async (component: { component: string, voucher_code: string }) => {
      if (!isPlatformBrowser(this.platformId)) {
        return;
      }
      await this.voucherService
        .applyGroupVoucher(component.voucher_code)
    }
  }

}
