<span (click)="setHiddenState()"
      class="facet-title"
      data-target="#collapsingNavbar2"
      data-toggle="collapse">
  {{ attribute.label }}<span *ngIf="selected()['nr']" class="fw-bold p-0 facets-selected">({{ selected()['nr'] }}
  )</span>
  <fa-icon *ngIf="!_isSelected()" [icon]="faAngleDown" class="angle-icon"></fa-icon>
  <fa-icon *ngIf="_isSelected()" [icon]="faAngleUp" class="angle-icon"></fa-icon>
</span>

@if (showFilterSubTitle()) {
  <span class="facet-sub-title hide-element-on-desktop-filter-facet">{{ selected()['labels'].join(', ') }}</span>
}

<div [class.rangeOnPrice]="isRangeInput() || isRangeSlider()" [ngClass]="_isSelected() ? 'refinement' : 'd-none'">
  @if (isRangeInput()) {
    <app-search-range-input
      class="rr-block"
      [hide]="!_isSelected()"
      #rangeInput
      (inputSubmit)="updateSelectedRange($event)"
      [attribute]="attribute.algoliaAttribute ?? ''"
      currency="DKK"
      separator="→"
      submitLabel="Søg"/>
  } @else if (isRangeSlider()) {
    <app-search-range-slider
      class="rr-block"
      [hide]="!_isSelected()"
      (inputSubmit)="updateSelectedRange($event)"
      [attribute]="attribute.algoliaAttribute ?? ''"/>
  } @else if (isRatingFilter()) {
    <app-rating-filter
      class="rr-block"
      [hide]="!_isSelected()"
      #ratingFilterInput
      (changeEvent)="onRatingFilterChange($event)"
      [max]="ratingsMax"
      [min]="ratingsMin"/>
  } @else {
    <app-search-refinement-list
      [hideList]="!_isSelected()"
      class="navbar-collapse collapse"
      [ngClass]="[_isSelected() ? 'show' : 'hide']"
      #refinementList
      [attribute]="attribute.algoliaAttribute ?? ''"
      [limit]="300"
      [searchable]="false"
      [sortBy]="sortBy"
      [transformItems]="transformItems"/>
  }
</div>
