import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {
  NguCarouselConfig,
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguTileComponent
} from '@ngu/carousel';
import {StoryblokService} from '@core/services/storyblok.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {ProductService} from '@core/services/product.service';
import {AddToCartComponent} from '@layout/components/product/add-to-cart/add-to-cart.component';
import {RouterModule} from '@angular/router';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {AsyncPipe, NgIf, NgOptimizedImage} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {CarouselComponent} from '@layout/components/product/carousel/carousel.component';
import {WineProduct} from '@core/interfaces/generated/graphql';

@Component({
  selector: 'app-storyblok-product-slider',
  templateUrl: './storyblok-product-slider.component.html',
  styleUrls: ['./storyblok-product-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AddToCartComponent, RouterModule, NgIf, FontAwesomeModule, StoryblokDirective, NgOptimizedImage, AsyncPipe, NguTileComponent, NguCarouselDefDirective, NguCarouselPrevDirective, NguCarouselNextDirective, CarouselComponent]
})
export class StoryblokProductSliderComponent extends StoryblokBaseComponent implements OnInit {
  @Input() slides: any;
  @Input() _editable: any;
  @Input() general_styling: any;

  @Input()
  set elementId(value: any) {
    this.hostElementId = value;
  }

  @HostBinding('attr.id') hostElementId: any;
  @HostBinding('class') class: any;

  carouselTile: NguCarouselConfig;
  products$: Observable<any>;

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    public storyblokService: StoryblokService,
    private productService: ProductService
  ) {
    super();
    this.carouselTile = {
      grid: {
        xs: 1, sm: 1, md: 1, lg: 1, all: 0
      },
      slide: 1,
      speed: 350,
      interval: {
        timing: 10000
      },
      point: {
        visible: true
      },
      load: 2,
      velocity: 0,
      loop: true,
      touch: true,
      easing: 'cubic-bezier(0, 0, 0.2, 1)'
    };
  }

  ngOnInit(): void {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12});

    const productIds = this.slides.map((data: any) => data.visma_id).filter((data: any) => !!data);

    this.products$ = this.productService.getProducts(productIds).pipe(
      map(data => data?.map(item => item.product)),
    );
  }

  getUniqueToken(products: WineProduct[]): string {
    if (!products) {
      return 'empty';
    }
    return products?.map(d => d?.vismaProductNumber).join('-');
  }

  findProduct(productId: string, products: any[]) {
    return products?.find((data: any) => productId === data?.vismaProductNumber);
  }

  protected readonly faChevronLeft = faChevronLeft;
  protected readonly faChevronRight = faChevronRight;
}
