<div *ngIf="product?.id" class="d-flex flex-column">
  @if (product?.topCta?.type) {
    <app-product-top-cta [product]="product" class="hide-this-on-mobile"/>
  }
  <app-price-table (addedToCart)="addedToCart()"
                   [alwaysShowDiscountBtn]="false"
                   [hideAddToCart]="isMobile"
                   [hideProductDefaultCta]="hasGroupVoucherPrice()|async"
                   [hideLikeButton]="true"
                   eventSourceName="product-page"
                   [product]="product">
  </app-price-table>
  <div *ngIf="product?.meta?.energyLabel" class="energy-label">
    <p>Produktets energimærke</p>
    <img (click)="openEnergyLabelDoc()" [ngSrc]="product?.meta?.energyLabel ?? ''" height="50" width="80">
    <p class="mt-3">Tekniske oplysninger om produktet</p>
    <a [href]="product?.meta?.productDataSheet" target="_blank">Produktdatablad</a>
  </div>
  <app-product-like-and-rate [product]="product" class="mt-1"></app-product-like-and-rate>
  <app-product-ratings [product]="product" class="mt-1"></app-product-ratings>

  <app-product-order *ngIf="!product?.hideShippingCountdown" class="order-lg-1 order-2"></app-product-order>

  <div *ngIf="!product?.hideSupervinAdvantages" class="row guarantees small-padding order-lg-2 order-1">
    <div class="col-2">
      <div class="d-flex h-100">
        <div
          *ngIf="this.storyblokService.getImageObject({filename: 'https://a.storyblok.com/f/106593/30x36/5ee58bcce8/wine-quality-gurantee-icon.png'}) as imageObj"
          class="justify-content-center align-self-center">
          <figure>
            <img [ngSrc]="imageObj.optimizedUrl" [width]="imageObj.imgWidth" [height]="imageObj.imgHeight">
          </figure>
        </div>
      </div>
      <div *ngIf="showFreeShipping()|async" class="d-flex h-100">
        <div
          *ngIf="this.storyblokService.getImageObject({filename: 'https://a.storyblok.com/f/106593/29x37/e61a9c162e/free-return-icon.png'}) as imageObj"
          class="justify-content-center align-self-center">
          <figure>
            <img [ngSrc]="imageObj.optimizedUrl" [height]="imageObj.imgHeight" [width]="imageObj.imgWidth">
          </figure>
        </div>
      </div>
      <div class="d-flex h-100">
        <div
          *ngIf="this.storyblokService.getImageObject({filename: 'https://a.storyblok.com/f/106593/38x22/049fc1d90d/free-delivery-icon.png'}) as imageObj"
          class="justify-content-center align-self-center">
          <figure>
            <img [ngSrc]="imageObj.optimizedUrl" [width]="imageObj.imgWidth" [height]="imageObj.imgHeight">
          </figure>
        </div>
      </div>
    </div>
    <div class="col-10">
      <div class="guarantee">
        <p>Supervin's unikke vingaranti</p>
        <div>
          <p>Supervin's unikke vingaranti</p>
          <p>Hvis du ikke er tilfreds med kvaliteten, tilbyder vi at tage vinen retur.
            Du refunderes det fulde beløb for vinen, også for den første åbnede flaske.
            Vi betaler selvfølgelig returfragten. (maks. 1 md. efter levering).
            <a [routerLink]="'/info/betingelser'">Læs mere her.</a></p>
        </div>
      </div>
      <div *ngIf="showFreeShipping()|async" class="guarantee delivery">
        <ng-container *ngIf="totalPriceSignal() as total">
          <p *ngIf="total > 0">Fri fragt v. køb for {{ total|currency }} mere</p>
          <p *ngIf="total <= 0">Fri fragt</p>
        </ng-container>
        <div>
          <p>Fri fragt!</p>
          <p>Køber du for 1000,00 kr. samlet, så sender vi gratis vinen til din hoveddør!</p>
        </div>
      </div>
      <div class="guarantee">
        <p>30 dages udvidet returret</p>
        <div>
          <p>30 dages udvidet returret</p>
          <p>Hos Supervin tilbyder vi 30 dages udvidet returret.</p>
        </div>
      </div>
    </div>
  </div>

</div>
