import {Component, computed, HostBinding, input, OnInit, Signal} from '@angular/core';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {DynamicModule} from 'ng-dynamic-component';
import {NgComponentOutlet, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {StoryblokDynamicInputService} from '@core/services/storyblok-dynamic-input.service';


@Component({
  selector: 'app-icon-with-text',
  templateUrl: './storyblok-icon-with-text.component.html',
  styleUrls: ['./storyblok-icon-with-text.component.scss'],
  standalone: true,
  imports: [StoryblokDirective, DynamicModule, NgForOf, NgIf, NgComponentOutlet, NgOptimizedImage]
})
export class StoryblokIconWithTextComponent extends StoryblokBaseComponent implements OnInit {

  _editable = input<any>();
  general_styling = input<any>();
  title = input<string>();
  text = input<string>();
  icon = input.required<{ filename: string }>();
  color_theme = input<{ value: string }>();
  icon_width = input<string>();
  textHtml: Signal<string>;

  @HostBinding('class') class: any;
  @HostBinding('style.color') color: any;

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    private storyblokDynamicInputService: StoryblokDynamicInputService
  ) {
    super();
  }

  ngOnInit() {
    this.class = computed(() => {
      return this.storyblokStylingService.getClassList(this.general_styling(), {
        desktop: {'margin-bottom': '20px', 'margin-top': '16px'},
      }, {'col-': 12});
    })();
    this.color = this.color_theme()?.value;
    this.textHtml = this.storyblokDynamicInputService.replaceDynamicInputs(this.text() ?? '');
  }
}
