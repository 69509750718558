<div *ngIf="product$|async as product" [appStoryblok]="_editable" class="product">
  <div class="product-image">
    <img [ngSrc]="product?.image?.original ?? ''" alt="" fill/>
  </div>
  <div class="">
    <p class="product-title">
      <span class="pe-2">{{ product.name }}</span>
      <span class="alcohol">
        {{ product.meta?.alcohol }} % ALK.
      </span>
    </p>

    <div *ngFor="let rating of ratings(product)">
      <app-product-rating-points [rating]="rating" class="rating-points"></app-product-rating-points>
      <span class="rating-dot">•</span>
      <span class="rating-label">{{ rating.name }}</span>
    </div>
  </div>

  <div *ngIf="product.meta?.foods?.length" class="product-foods-container">
    <p class="product-foods-title">God til f.eks.:</p>
    <div *ngIf="productStoryblok$|async as productStoryblok" class="product-foods">
      <div *ngFor="let food of getFoods(product)" class="product-food">
        <img [ngSrc]="productService.getItemIcon(food?.iconName ?? '', productStoryblok)" height="52" width="52"/>
        <p class="mb-0 mt-1">{{ food?.label }}</p>
      </div>
    </div>
  </div>
</div>
