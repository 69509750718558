<div *ngIf="activeImage()" class="image-container">
  <div class="image">
    <app-product-image-carousel [(activeImageId)]="activeImageId"
                                (openImageModal)="openImageProductModal()"
                                [images]="carouselImageArray()"></app-product-image-carousel>
  </div>
</div>
<div *ngIf="(product()?.images?.length ?? 0) > 1" class="overflow-auto">
  <app-product-image-thumbnail-carousel [(activeImageId)]="activeImageId"
                                        [images]="carouselImageArray()"></app-product-image-thumbnail-carousel>
</div>
