import {Component, forwardRef, HostBinding, HostListener, Inject, Input} from '@angular/core';
import {HierarchicalMenuItem} from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';
import {InstantsearchHierarchicalMenuComponent} from '../../instantsearch-hierarchical-menu.component';


@Component({
  selector: 'a[app-instantsearch-hierarchical-menu-item]',
  templateUrl: './instantsearch-hierarchical-menu-item.component.html',
  styleUrls: ['./instantsearch-hierarchical-menu-item.component.scss'],
  standalone: true,
})
export class InstantsearchHierarchicalMenuItemComponent {

  @Input() public lvl = 1;
  @Input() public refine: (str: any) => void;
  @Input() public createURL: (str: string) => string;
  @Input() public item: HierarchicalMenuItem;
  @Input() public url: string;
  @Input() public fragment: string;

  private _isHovering = false;

  constructor(
    @Inject(forwardRef(() => InstantsearchHierarchicalMenuComponent))
    public instantsearchHierarchicalMenu: InstantsearchHierarchicalMenuComponent,
  ) {
  }

  @HostBinding('class')
  get hostClass() {
    if (this.item.isRefined) {
      return 'hovering';
    }
    return '';
  }

  @HostBinding('title') get title() {
    return this.getItemLabel(this.item);
  }

  @HostListener('mouseenter')
  mouseEnter() {
    this.isHovering = true;
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this.isHovering = false;
  }

  get isHovering(): boolean {
    return this._isHovering;
  }

  set isHovering(value: boolean) {
    this._isHovering = value;

    if (this.isHovering) {
      setTimeout(() => {
        if (this.isHovering && !this.item.isRefined) {
          this.refine(this.item.value);
        }
      }, 300);
    }
  }

  getItemLabel(item: any): string|null {
    return this.instantsearchHierarchicalMenu.getItemLabel(item)
  }
}
