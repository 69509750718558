import {Component, HostBinding, Input} from '@angular/core';
import {LoadingIconComponent} from '../loading-icon/loading-icon.component';
import {NgIf} from '@angular/common';

@Component({
  selector: '[app-loading]',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  standalone: true,
  imports: [
    LoadingIconComponent,
    NgIf
  ]
})
export class LoadingComponent {

  @Input() loading = false;

  @Input() disabled: boolean | null = false;

  @Input() grey = true;

  @Input() small = true;

  @Input() success: boolean | null = false;

  @Input() text?: string;

  @HostBinding('class.disabled') get disabledClass() {
    return this.disabled || this.loading;
  }

  @HostBinding('class.success') get successClass() {
    return this.success;
  }

}
