import {computed, Injectable, signal, WritableSignal} from '@angular/core';
import {Context, Maybe} from '@core/interfaces/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class PageInfoContextService {

  context: WritableSignal<Maybe<Context>[]> = signal([]);

  setContext(contextArray: Maybe<Context>[]) {
    this.context.set(contextArray);
  }

  getContext(key: string) {
    return computed(() => this.context()?.find(context => context?.key === key));
  }

}
