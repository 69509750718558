import {Component, EventEmitter, HostListener, input, Output} from '@angular/core';
import {RouterLink} from '@angular/router';

type Highlight = {
  title: string;
  link: any;
}

export type HighlightGroup = {
  title: string;
  menuPath: string;
  highlights: Highlight[];
}

@Component({
  selector: 'app-instantsearch-hierarchical-menu-highlights',
  templateUrl: './instantsearch-hierarchical-menu-highlights.component.html',
  styleUrls: ['./instantsearch-hierarchical-menu-highlights.component.scss'],
  standalone: true,
  imports: [
    RouterLink
  ]
})
export class InstantsearchHierarchicalMenuHighlightsComponent {

  highlightGroup = input<HighlightGroup | undefined>();
  @Output() hovering = new EventEmitter<boolean>();

  private _isHovering = false;

  get isHovering(): boolean {
    return this._isHovering;
  }

  set isHovering(value: boolean) {
    this._isHovering = value;

    if (this.isHovering) {
      setTimeout(() => {
        if (this.isHovering) {
          this.hovering.emit(true);
        }
      }, 300);
    }
    this.hovering.emit(false);
  }

  @HostListener('mouseenter')
  mouseEnter() {
    this.isHovering = true;
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this.isHovering = false;
  }
}
