
import {Injectable} from '@angular/core';
import {ShippingMethodService} from './shipping-method.service';
import {PaymentMethodService} from './payment-method.service';
import {HubspotDealStage, HubspotService} from './hubspot.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutContextService {

  constructor(
    private shippingMethodsService: ShippingMethodService,
    private paymentMethodsService: PaymentMethodService,
    private hubspotService: HubspotService
  ) {
  }

  public updateCheckoutData(updateShippingInfo: boolean, updatePaymentMethods: boolean, dealStageStep?: HubspotDealStage) {

    if (updateShippingInfo) {
      this.shippingMethodsService.updateShippingMethods();
    }
    if (updatePaymentMethods) {
      this.paymentMethodsService.updatePaymentMethods();
    }
    if (dealStageStep) {
      this.hubspotService.updateHubspotDeal(dealStageStep);
    }
  }

  public setLoading() {
    this.shippingMethodsService.setShippingMethodToLoading();
    this.paymentMethodsService.setPaymentMethodsToLoading();
  }
}
