import {ApplicationConfig, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID} from '@angular/core';
import {
  provideRouter,
  Router,
  RouteReuseStrategy,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig
} from '@angular/router';
import {routes} from './app.routes';
import {IMAGE_LOADER, registerLocaleData} from '@angular/common';
import {customImageLoader} from './custom-image-loader';
import {CustomErrorHandler} from './custom-error-handler.service';
import {CustomRouteReuseStrategy} from '@core/custom-route-reuse-strategy';
import * as Sentry from '@sentry/angular-ivy';
import {environment} from '@env/environment';
import {provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import dayjs from 'dayjs';
import localeDa from '@angular/common/locales/da';
import {provideClientHydration} from '@angular/platform-browser';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

require('dayjs/locale/da')
dayjs.locale('da');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Copenhagen');

registerLocaleData(localeDa);

export const appConfig: ApplicationConfig = {

  providers: [
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'ignore',
      }),
      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
      })
    ),
    provideClientHydration(),
    {
      provide: IMAGE_LOADER,
      useValue: customImageLoader,
    },
    {
      provide: 'errorHandlerOptions',
      useValue: {
        showDialog: false,
        logErrors: true
      }
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: ''
    },
    {
      provide: LOCALE_ID,
      useValue: 'da-DK'
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: 'googleTagManagerId',
      useValue: environment.GTM.id
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch())
  ]
};
