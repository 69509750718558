<app-carousel [uniqueToken]="'product-img-' + uniqueToken" #carousel (onMove)="onMove($event)" *ngIf="images()" [dataSource]="images()" [inputs]="carouselTile"
              [trackBy]="trackBy()">
  <ngu-tile *nguCarouselDef="let item; let i = index" class="position-relative">
    <div *ngIf="item.is360" class="orbitvu-viewer">
      <div app-orbitvu-360 [orbitvuId]="item.id" [orbitvuScriptId]="item.scriptId" [style.background-image]="'url(' + item.large + ')'"></div>
    </div>

    <img *ngIf="i === 0 && !item.is360"
         [loaderParams]="{height: 600}" [ngSrc]="item?.large || ''" draggable="false"
         fill priority>
    <img [loaderParams]="{height: 600}"
         *ngIf="i !== 0 && !item.is360" [ngSrc]="item?.large || ''" fill
         draggable="false" loading="lazy">
    <fa-icon (click)="openImageModal.emit()" [icon]="faMagnifyingGlass" class="zoom-icon"></fa-icon>
  </ngu-tile>
</app-carousel>
