import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  forwardRef,
  Inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Signal,
  signal,
  WritableSignal
} from '@angular/core';
import {noop, Subject, Subscription} from 'rxjs';
import {connectHierarchicalMenu} from 'instantsearch.js/es/connectors';
import {
  HierarchicalMenuItem,
  HierarchicalMenuRenderState
} from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';

import {
  InstantsearchHierarchicalMenuItemComponent
} from './components/instantsearch-hierarchical-menu-item/instantsearch-hierarchical-menu-item.component';
import {RouterModule} from '@angular/router';
import {
  InstantsearchHierarchicalMenuLastComponent
} from './components/instantsearch-hierarchical-menu-last/instantsearch-hierarchical-menu-last.component';
import {NgForOf, NgIf} from '@angular/common';
import {debounceTime, filter} from 'rxjs/operators';
import {HeaderMenuService} from '@core/services/header-menu.service';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {BaseWidgetComponent} from '@core/instantsearch/base-widget.component';
import {RoutingService} from '@core/services/routing.service';
import {ALGOLIA_SEPERATOR} from '@core/services/algolia.service';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {
  HighlightGroup,
  InstantsearchHierarchicalMenuHighlightsComponent
} from '@layout/components/general/header/components/instantsearch-hierarchical-menu/components/instantsearch-hierarchical-menu-highlights/instantsearch-hierarchical-menu-highlights.component';

@Component({
  selector: 'app-instantsearch-hierarchical-menu',
  templateUrl: './instantsearch-hierarchical-menu.component.html',
  styleUrls: ['./instantsearch-hierarchical-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgForOf, InstantsearchHierarchicalMenuItemComponent, RouterModule, InstantsearchHierarchicalMenuLastComponent, RouterContextDirective, InstantsearchHierarchicalMenuHighlightsComponent]
})
export class InstantsearchHierarchicalMenuComponent extends BaseWidgetComponent<any, any> implements OnInit, OnDestroy {

  // connector options
  @Input() public attributes: string[];
  @Input() public separator = ' > ';
  @Input() public rootPath?: string;
  @Input() public showParentLevel?: boolean;
  @Input() public limit?: number | string = 10;

  highlights = input<HighlightGroup[]>([]);

  isHidden = computed(() => this.state().items.length === 0);
  items: Signal<HierarchicalMenuItem[]> = computed(() => {
    const items = this.state().items;

    return typeof this.transformItems === 'function'
      ? this.transformItems(items)
      : items;
  });

  highlightLvl0Items = computed(() => {
    return this.highlights()?.find(highlight => highlight.menuPath === '');
  });
  highlightLvl1Items = computed(() => {
    return this.highlights()?.find(highlight => highlight.menuPath === this.getItemLabel(this.activeLvl0Item()));
  });
  highlightLvl2Items = computed(() => {
    return this.highlights()?.find(highlight => {
      const path = highlight.menuPath.split('>');
      if (path.length !== 2) {
        return false;
      }
      return path[0].trim() === this.getItemLabel(this.activeLvl0Item()) && path[1].trim() === this.getItemLabel(this.activeLvl1Item());
    });
  });

  activeLvl0Item = computed(() => this.items().find(item => item.data !== null) || this.emptyMenuItem());
  activeLvl1Item = computed(() => this.activeLvl0Item()?.data?.find(item => item.data !== null) || this.emptyMenuItem());
  activeLvl2Item = computed(() => this.activeLvl1Item()?.data?.find(item => item.isRefined) || this.emptyMenuItem());
  isAllLevelsActive = computed(() => {
    if (!this.activeLvl2Item()) {
      return false;
    }
    const hasMoreThenOneMenuItem = (this.activeLvl1Item().data?.length ?? 0) <= 1;
    if (this.activeLvl1Item().isRefined && hasMoreThenOneMenuItem) {
      return true;
    }

    return this.activeLvl2Item().isRefined;
  })

  public override state: WritableSignal<HierarchicalMenuRenderState> = signal({
    createURL: noop as any,
    items: [],
    refine: noop,
  } as any);

  resetMenuEvent = new Subject();
  resetMenuEventSub: Subscription;
  urlFormattedObject = [];

  constructor(
    @Optional()
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    protected changeDetector: ChangeDetectorRef,
    private headerMenuService: HeaderMenuService,
    private routingService: RoutingService
  ) {
    super('HierarchicalMenu');
  }


  public override ngOnInit() {
    this.createWidget(connectHierarchicalMenu as any, {
      limit: this.limit,
      attributes: this.attributes,
      rootPath: this.rootPath,
      separator: this.separator,
      showParentLevel: this.showParentLevel,
      sortBy: this.sortBy,
    });

    super.ngOnInit();
    this.instantSearchInstance.instantSearchInstance.on('render', () => {
      this.changeDetector.markForCheck();
    });

    this.resetMenuEventSub = this.resetMenuEvent.asObservable().pipe(
      debounceTime(300),
      filter((resetMenu) => !!resetMenu)
    ).subscribe(() => {
      this.headerMenuService.setActiveMenu(null);

      const item = this.activeLvl0Item;
      if (!item || !item().value) {
        return;
      }
      this.state().refine(item().value);
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.resetMenuEventSub.unsubscribe();
  }

  transformItems(items: any[]) {
    return items.map(item => {
      // Algolia sets count to be the isRefined subitems count, but we want the count of all subitems
      if (item.isRefined && item.data) {
        item.count = item.data.reduce((acc: number, subItem: any) => {
          acc += subItem.count;
          return acc;
        }, 0);
      }
      return item;
    }).sort(this.sortBy);
  }

  sortBy(a: any, b: any) {
    const aLabel = a.name?.split(ALGOLIA_SEPERATOR)[1];
    const bLabel = b.name?.split(ALGOLIA_SEPERATOR)[1];
    if (aLabel === 'Øvrige') {
      return 1;
    }
    if (bLabel === 'Øvrige') {
      return -1;
    }
    if (b.count - a.count !== 0) {
      return b.count - a.count;
    }

    return aLabel.localeCompare(bLabel);
  }

  transformValue(items: any[]) {
    return items.map(item => {

      const [value, label] = (item as any).label.split(ALGOLIA_SEPERATOR);

      return {
        ...item,
        value,
        label
      };
    });
  }

  getAdditionalContext(item: any, eventSourceName: string) {
    const menu_navigation_path = this.getFullPathLabel(item) ?? '';
    return {
      menu_navigation_path,
      eventSourceName
    }
  }

  onHighlightsHovering(hovering: boolean, lvl: number) {
    if (hovering && lvl === 0) {
      this.resetMenu();
    } else if (hovering && lvl === 1 && this.activeLvl1Item().isRefined) {
      this.state().refine(this.activeLvl1Item().value);
    } else if (hovering && lvl === 2 && this.activeLvl2Item().isRefined) {
      this.state().refine(this.activeLvl2Item().value);
    }
  }

  resetMenu() {
    this.resetMenuEvent.next(true);
  }

  keepMenu() {
    this.resetMenuEvent.next(false);
  }

  protected emptyMenuItem(): HierarchicalMenuItem {
    return {
      count: 0,
      data: [],
      label: '',
      isRefined: false,
      value: ''
    };
  }

  tracker(index: any, item: HierarchicalMenuItem) {
    return item.value;
  }

  parseItemValue(value: any) {
    if (!value) {
      return [];
    }
    let itemValue = value.split(this.separator).pop();
    return itemValue.split(ALGOLIA_SEPERATOR);
  }

  getItemPathname(item: any) {
    if (item === null || !item.value) {
      return '';
    }
    const path = this.parseItemValue(item.value)[0] ?? '';
    if (this.urlFormattedObject.length === 0) {

    }
    const urlFormattedObject = this.routingService.getUrlFormattedObjectByPath(path)
    return urlFormattedObject?.pathname ?? '';
  }

  getItemSearchParams(item: any) {
    if (item === null || !item.value) {
      return {};
    }
    const path = this.parseItemValue(item.value)[0] ?? {};
    const urlFormattedObject = this.routingService.getUrlFormattedObjectByPath(path)
    return urlFormattedObject?.search ?? {};
  }

  getItemLabel(item: any): string | null {
    if (item === null || !item.value) {
      return null;
    }
    return this.parseItemValue(item.value)[1] ?? null;
  }

  getFullPathLabel(item: any): string | null {
    if (item === null || !item.value) {
      return null;
    }
    return item.value
      .split(this.separator)
      .map((value: string) => {
        return this.parseItemValue(value)[1];
      })
      .join(this.separator);
  }
}
