import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {StoryblokStylingService} from '../../../../../core/services/storyblok-styling.service';
import {StoryblokDirective} from '../../../../../core/directives/storyblok.directive';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {CookiebotConsent, CookiebotService} from '../../../../../core/services/cookiebot.service';
import {AsyncPipe, NgIf} from '@angular/common';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-storyblok-video',
  templateUrl: './storyblok-video.component.html',
  styleUrls: ['./storyblok-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, YouTubePlayerModule, NgIf, AsyncPipe]
})
export class StoryblokVideoComponent extends StoryblokBaseComponent implements OnInit {

  @Input() url: { url: string };
  @Input() _editable: any;
  @Input() general_styling: any;

  @HostBinding('class') class: any;

  cookiebot$: Observable<CookiebotConsent>;

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    private cookiebotService: CookiebotService,
  ) {
    super();
  }

  get videoId() {
    let videoId = this.url.url.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }
    return videoId;
  }

  ngOnInit(): void {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12}, this.col);
    this.cookiebot$ = this.cookiebotService.cookiebotConsent.asObservable();
  }
}
