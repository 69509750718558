import {Component, Input} from '@angular/core';
import {WineProduct} from '../../../../../../../core/interfaces/generated/graphql';
import {DatePipe, NgIf} from '@angular/common';


@Component({
  selector: 'app-product-badges',
  templateUrl: './product-badges.component.html',
  styleUrls: ['./product-badges.component.scss'],
  standalone: true,
  imports: [NgIf, DatePipe]
})
export class ProductBadgesComponent {

  @Input() product: WineProduct | undefined | null;

  constructor() {
  }

  get showStockText() {
    return this.product?.ctaLink && this.product.ctaStockText;
  }

  get showInStock() {
    return !this.showStockText && this.product?.inStock && !this.showPreorder;
  }

  get showPreorder() {
    return !this.showStockText && this.product?.preorderDate;
  }

  get showOutOfStock() {
    return !this.showStockText && !this.product?.inStock;
  }

}
