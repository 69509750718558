import {Injectable} from '@angular/core';
import {v4 as uuidv4} from 'uuid';
import {RoutingService} from '@core/services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationTrackingService {

  navigation: {
    id: string;
    path: string;
  } | null = null;

  constructor(
    private routingService: RoutingService
  ) {
  }

  public getNavigationId() {
    const url = this.routingService.getURL(undefined, false, false, true);
    if (this.navigation?.path === url) {
      return this.navigation.id;
    }
    this.navigation = {
      id: uuidv4(),
      path: url
    };
    return this.navigation.id;
  }

  public resetNavigationId() {
    this.navigation = null;
  }
}
