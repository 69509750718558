import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Maybe, Query, Scalars, ShippingMethod} from '../interfaces/generated/graphql';
import {ApolloService} from './apollo.service';
import {UserInitService} from './user-init.service';
import dayjs from 'dayjs';
import 'dayjs/plugin/timezone';

@Injectable({
  providedIn: 'root'
})
export class ShippingMethodService {

  constructor(
    private apollo: ApolloService,
    protected userInitService: UserInitService
  ) {
  }

  shippingMethods$: BehaviorSubject<{ loading: boolean, shippingMethods: ShippingMethod[] }> = new BehaviorSubject<{
    loading: boolean,
    shippingMethods: ShippingMethod[]
  }>({
    loading: true,
    shippingMethods: []
  });

  getShippingMethods(): Observable<{ loading: boolean, shippingMethods: ShippingMethod[] }> {
    return this.shippingMethods$.asObservable();
  }

  setShippingMethodToLoading(loading = true) {
    if (this.shippingMethods$.observers.length === 0) {
      return;
    }
    this.shippingMethods$.next({
      loading: loading,
      shippingMethods: this.shippingMethods$.value.shippingMethods
    })
  }

  getActiveDeliveryDate(method: string, dateType: 'asap' | 'custom', date: string = '') {
    const shippingMethod = this.shippingMethods$.value.shippingMethods.find(data => data.method === method);
    if (!shippingMethod) {
      // TODO Throw error!;
      return this.formatDate(dayjs());
    }
    if (dateType === 'custom' && date) {
      return date;
    }
    return this.formatDate(this.asapDeliveryDate(shippingMethod.fastestDeliveryDate));
  }

  formatHumanDate(date: dayjs.Dayjs) {
    return 'd. ' + date.format('D. MMMM YYYY');
  }

  formatDate(date: dayjs.Dayjs) {
    return date.tz().format();
  }

  asapDeliveryDate(fastestDeliveryDate?: Maybe<Scalars['DateTime']>) {
    return dayjs(fastestDeliveryDate);
  }

  async updateShippingMethods() {
    if (!this.shippingMethods$.value.loading) {
      this.setShippingMethodToLoading();
    }

    await this.userInitService.onInitializedPromise();

    return this.apollo.queryPromise<Query>({
      queryName: 'getShippingMethodsQuery',
    }).then(shippingMethods => {
        this.shippingMethods$.next({
          loading: false,
          shippingMethods: (shippingMethods.data.getShippingMethods || []) as ShippingMethod[]
        })
      }
    ).catch(err => {
      this.setShippingMethodToLoading(false);
      throw err;
    });
  }

}
