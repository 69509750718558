@if (product(); as product) {
  <div class="user-rating" [class.show-animation]="ratingClicked()">
    <div class="d-flex">
      @if (productCustomerReview(); as customerReview) {
        @if (customerReview.rating > 0) {
          <app-star-solid-icon (click)="addReviewProduct(product, 1, customerReview.rating === 1)"/>
        } @else {
          <app-star-empty-icon (click)="addReviewProduct(product, 1)"/>
        }
        @if (customerReview.rating > 1) {
          <app-star-solid-icon (click)="addReviewProduct(product, 2, customerReview.rating === 2)"/>
        } @else {
          <app-star-empty-icon (click)="addReviewProduct(product, 2)"/>
        }
        @if (customerReview.rating > 2) {
          <app-star-solid-icon (click)="addReviewProduct(product, 3, customerReview.rating === 3)"/>
        } @else {
          <app-star-empty-icon (click)="addReviewProduct(product, 3)"/>
        }
        @if (customerReview.rating > 3) {
          <app-star-solid-icon (click)="addReviewProduct(product, 4, customerReview.rating === 4)"/>
        } @else {
          <app-star-empty-icon (click)="addReviewProduct(product, 4)"/>
        }
        @if (customerReview.rating > 4) {
          <app-star-solid-icon (click)="addReviewProduct(product, 5, customerReview.rating === 5)"/>
        } @else {
          <app-star-empty-icon (click)="addReviewProduct(product, 5)"/>
        }
      } @else {
        <app-star-empty-icon (click)="addReviewProduct(product, 1)"/>
        <app-star-empty-icon (click)="addReviewProduct(product, 2)"/>
        <app-star-empty-icon (click)="addReviewProduct(product, 3)"/>
        <app-star-empty-icon (click)="addReviewProduct(product, 4)"/>
        <app-star-empty-icon (click)="addReviewProduct(product, 5)"/>
      }
    </div>
  </div>
}
