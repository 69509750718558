<app-carousel [uniqueToken]="'product-recommendation-' + uniqueToken()" *ngIf="filteredProducts()" [trackBy]="trackBy()" [inputs]="carouselTile" [dataSource]="filteredProducts()" #carousel>
  <ngu-tile *nguCarouselDef="let item; let i = index">
    <article *ngIf="shouldBeVisible(carousel.activePoint, i)"
             [maxRatings]="2"
             [product]="item"
             (productClickEvent)="productClickEvent.emit($event)"
             [eventSourceName]="eventSourceName"
             [hideProductDefaultCta]="true"
             [index]="i"
             app-product-widget>
    </article>
  </ngu-tile>
  <button NguCarouselPrev class="carousel-navigator carousel-left">
    <img
      *ngIf="this.storyblokService.getImageObject({filename: 'https://a.storyblok.com/f/106593/22x39/f3750f9d89/arrow-left.png'}, 22) as imageObj"
      [height]="imageObj.imgHeight" [ngSrc]="imageObj.optimizedUrl" [width]="imageObj.imgWidth">
  </button>
  <button NguCarouselNext class="carousel-navigator carousel-right">
    <img
      *ngIf="this.storyblokService.getImageObject({filename: 'https://a.storyblok.com/f/106593/22x39/f698c62f31/arrow-right.png'}, 22) as imageObj"
      [height]="imageObj.imgHeight" [ngSrc]="imageObj.optimizedUrl" [width]="imageObj.imgWidth">
  </button>
</app-carousel>
