import {Component, computed, Inject, OnDestroy, PLATFORM_ID} from '@angular/core';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {PrintService} from '@core/services/print.service';
import {HeadService} from '@core/services/head.service';
import {SuperpayService} from '@core/services/superpay.service';
import {UserService} from '@core/services/user.service';
import {isPlatformBrowser, isPlatformServer, NgIf} from '@angular/common';
import {UnsubscribeService} from '@core/services/unsubscribe.service';
import {RoutingService} from '@core/services/routing.service';
import {map, takeUntil} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OneSignalService} from '@core/services/one-signal.service';
import {TrackEventService} from '@core/services/track-event.service';
import {Router, RouterOutlet} from '@angular/router';
import {VfSecretKeyService} from '@core/services/vf-secret-key.service';
import {ToastComponent} from '@layout/components/general/toast/toast.component';
import {FooterComponent} from '@layout/components/general/footer/footer.component';
import {BreadcrumbsComponent} from '@layout/components/general/breadcrumbs/breadcrumbs.component';
import {HeaderComponent} from '@layout/components/general/header/header.component';
import {toSignal} from '@angular/core/rxjs-interop';
import {setTags, setUser} from '@sentry/angular-ivy';
import {RuntimeIdService} from '@core/services/runtime-id.service';
import {CookieService} from '@core/services/cookie.service';
import {GridService} from '@core/services/grid.service';
import {SuperpayBuyBtnComponent} from '@layout/components/superpay/superpay-buy-btn/superpay-buy-btn.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
  imports: [
    HeaderComponent,
    BreadcrumbsComponent,
    RouterOutlet,
    FooterComponent,
    ToastComponent,
    NgIf,
    SuperpayBuyBtnComponent,
  ],
})
export class AppComponent implements OnDestroy {

  isCheckout = this.initIsCheckout();

  isUserUniverse = computed(() => this.routing.routerSignal()?.includes('kundeportal'));
  isTastingEvent = computed(() => this.routing.routerSignal()?.startsWith('event'));

  constructor(
    private gtmService: GoogleTagManagerService,
    protected superpayService: SuperpayService,
    public printService: PrintService,
    protected userService: UserService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected unsubscribeService: UnsubscribeService,
    private headService: HeadService,
    private routing: RoutingService,
    protected modalService: NgbModal,
    protected oneSignal: OneSignalService,
    private router: Router,
    private trackEventService: TrackEventService,
    private vfSecretKeyService: VfSecretKeyService,
    private runtimeIdService: RuntimeIdService,
    private cookieService: CookieService,
    private gridService: GridService,
  ) {
    this.headService.initHeadService();

    if (isPlatformBrowser(this.platformId)) {
      this.superpayService.initConfig();
      this.gtmService.addGtmToDom();
      this.userService.triggerIsAdminCheck();
      this.userService.checkUrlForConfirmAccountKey();
      this.superpayService.checkUrlForSuperpayAction();
      this.checkForResetPasswordModal();
      this.oneSignal.init();
      this.trackEventService.init();
      // this.trackTimeOnPageService.init();
      this.vfSecretKeyService.initVfSecretKey();
      this.handleReferrerFromVinButler();
      this.initSentryUser();
      // this.rrwebService.init();
      this.gridService.init();
    }
  }

  initIsCheckout() {
    return toSignal(this.routing.onRouteChange().pipe(
      map(data => data.includes('checkout/info'))
    ))
  }

  initSentryUser() {
    setUser({
      id: this.cookieService.get('SVSessionID') ?? 'Anonymous-user',
    });
    setTags({
      sessionId: this.cookieService.get('SVSessionID'),
      runtimeId: this.runtimeIdService.getRuntimeId(),
    });
  }

  public checkForResetPasswordModal() {
    let lastParams: string|undefined = undefined;

    this.routing.onRouteChange(false).pipe(
      takeUntil(this.unsubscribeService.whileApplicationAlive()),
    ).subscribe((data) => {
      const queryParams = this.routing.getQueryParams<{'reset-password-email': string|undefined, 'reset-password-token': string|undefined}>(true);
      if (lastParams === JSON.stringify(queryParams)) {
        return;
      }
      this.modalService.dismissAll();
      lastParams = JSON.stringify(queryParams);
      const email = queryParams['reset-password-email']?.replace(' ', '+');
      const token = queryParams['reset-password-token'];
      if (email && token) {
        this.userService.forgottenPasswordCreatePasswordModal(email, token);
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribeService.triggerApplicationDestroyed();
  }

  handleReferrerFromVinButler() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (document.referrer.includes('vinbutler.dk')) {
      this.router.navigateByUrl('/vinbutler');
    }
  }

  showToast() {
    return isPlatformBrowser(this.platformId);
  }
}
