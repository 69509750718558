import {MutationOptions, QueryOptions, WatchQueryOptions} from '@apollo/client/core';

export type QueryType = 'mutate' | 'query' | 'watchQuery';

export type ApolloParams = MutationOptions | QueryOptions | WatchQueryOptions;

export interface QueryDataParams {
  queryName: string,
  variables?: Record<string, any>,
  client?: 'post-query' | 'default' | 'superpay'
}

export interface ApolloDataBase {
  type: string;
  queryType: QueryType,
  query: QueryDataParams;
}

export interface ApolloDataInitParams extends ApolloDataBase {
  type: 'init';

  isPreview?: boolean;
  hostname?: string;
}

export interface ApolloDataSubscribeParams extends ApolloDataBase {
  type: 'subscribe';

  apolloParams?: ApolloParams;
}

export interface ApolloDataUnsubscribeParams extends ApolloDataBase {
  type: 'unsubscribe';
}

export type ApolloDataParams = ApolloDataInitParams | ApolloDataSubscribeParams | ApolloDataUnsubscribeParams;

export const getQueryKey = (queryType: QueryType, data: QueryDataParams): string => {
  return `${queryType}-${data.queryName}-${data.variables ? JSON.stringify(data.variables) : 'no-var'}`;
};
