import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isPlatformBrowser} from '@angular/common';

@Component({
    selector: 'app-group-buy-page',
    templateUrl: './group-buy-page.component.html',
    styleUrls: ['./group-buy-page.component.scss'],
    standalone: true
})
export class GroupBuyPageComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.openModal();
    }
  }

  openModal() {
    import('../../../../layout/components/general/header/components/header-middle/components/header-middle-voucher-modal/header-middle-voucher-modal.component').then(comp => {
      this.modalService.open(comp.HeaderMiddleVoucherModalComponent);
    })
  }

}
