import {
  Component,
  DoCheck,
  forwardRef,
  Inject,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  signal,
  WritableSignal
} from '@angular/core';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import connectConfigure, {
  ConfigureConnectorParams,
  ConfigureRenderState,
  ConfigureWidgetDescription
} from 'instantsearch.js/es/connectors/configure/connectConfigure';
import {noop} from 'instantsearch.js/es/lib/utils';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';

@Component({
  selector: 'app-instantsearch-configure',
  template: '',
  standalone: true,
})
export class InstantSearchConfigureComponent extends TypedBaseWidgetComponent<
  ConfigureWidgetDescription,
  ConfigureConnectorParams
> implements DoCheck {
  // instance options
  private internalSearchParameters: ConfigureConnectorParams['searchParameters'];

  private differ: KeyValueDiffer<string, any>;

  public override state: WritableSignal<ConfigureRenderState> = signal({
    refine: noop,
  });

  constructor(
    private differs: KeyValueDiffers,
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent
  ) {
    super('Configure');
  }

  @Input()
  set searchParameters(values: ConfigureConnectorParams['searchParameters']) {
    this.internalSearchParameters = values;
    if (!this.differ && values) {
      this.differ = this.differs.find(values).create();
    }
  }

  public override ngOnInit() {
    this.createWidget(connectConfigure, {
      searchParameters: this.internalSearchParameters,
    });
    super.ngOnInit();
  }

  ngDoCheck() {
    if (this.differ) {
      const changes = this.differ.diff(this.internalSearchParameters);
      if (changes) {
        this.state().refine(this.internalSearchParameters);
      }
    }
  }

}
