import {Inject, Injectable, NgZone, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {BehaviorSubject} from 'rxjs';
import {SessionService} from '@core/services/session.service';

export type CookiebotConsent = {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CookiebotService {

  cookiebotConsent = new BehaviorSubject<CookiebotConsent>({
    necessary: true,
    preferences: false,
    statistics: false,
    marketing: false,
  });

  constructor(
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId: object,
    private sessionService: SessionService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.ngZone.runOutsideAngular(() => {
        window.addEventListener('CookiebotOnConsentReady', (e) => {
          this.updateCookiebotConsent((window as any)?.Cookiebot?.consent);
        });
      });
      if ((window as any)?.Cookiebot?.consent) {
        this.updateCookiebotConsent((window as any)?.Cookiebot?.consent);
      }
    }
  }

  updateCookiebotConsent(value: CookiebotConsent) {
    this.cookiebotConsent.next({
      necessary: value.necessary,
      preferences: value.preferences,
      marketing: value.marketing,
      statistics: value.statistics,
    });
    const session = this.sessionService.getCurrentSession();
    if (session) {
      this.sessionService.updateSessionCookies(session);
    }
  }

}
