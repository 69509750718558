<div class="pdf" *ngIf="product">
  <div class="pdf-main">
    <div class="pdf-product-image">
      <img *ngIf="product.image?.large as image" [ngSrc]="image" fill/>
      <div *ngIf="baPercentage()" class="ba-percentage-wrapper">
        <fa-icon *ngFor="let circle of getNumberOfBaPercentageCircles()" [icon]="faCircle"/>
      </div>
    </div>
    <div class="pdf-product-info">
      <div class="pdf-title">
        <h1>{{ product?.meta?.producerName || '&nbsp;' }}</h1>
        <h2>{{ product.name }}</h2>
      </div>
      <div class="pdf-product-description long-text-description"
           [innerHTML]="(product.pdfText || product.longText) | safeHtml"></div>
    </div>
    <div class="pdf-divider"></div>
    <div
      *ngIf="storyblokService.getImageObject({filename: 'https://a.storyblok.com/f/106593/497x321/e36cfb534b/pdf-reviews.png'}) as imageObj"
      class="pdf-product-reviews">
      <img [ngSrc]="imageObj.optimizedUrl" [height]="imageObj.imgHeight" [width]="imageObj.imgWidth"/>
      <h3>ANMELDELSER:</h3>
      <div *ngFor="let review of product.meta?.ratings">
        <p class="product-review-title" *ngIf="review?.starCount">
          <ng-container *ngFor="let star of review|ratingToStars">
            <img *ngIf="star === 'filled'"
                 [ngSrc]="this.storyblokService.getImageUrl({filename: 'https://a.storyblok.com/f/106593/20x20/db38f6925a/star-orange.png'})"
                 alt="" class="star"
                 height="15"
                 width="15">
          </ng-container>
        </p>
        <p *ngIf="!review?.starCount" class="product-review-title">
          {{ prepareReviewRating(review?.rating, review?.label) }}
        </p>
        <p class="mb-3"> /{{ review?.name }} </p>
      </div>
    </div>
  </div>
  <div class="pdf-bottom">
    <div class="pdf-bottom-container">
      <div class="pdf-custom-attributes-left" *ngIf="storyblokProduct$|async as storyblokProduct">
        <p class="attribute-label">Passer godt til:</p>
        <div class="product-foods">
          <div *ngFor="let food of product.meta?.foods" class="product-food">
            <img [alt]="food?.label" [ngSrc]="productService.getItemIcon(food?.iconName ?? '', storyblokProduct, true)" fill/>
          </div>
        </div>
      </div>
      <div class="bottom-divider"></div>
      <div class="pdf-custom-attributes-right">
        <div class="product-attributes" *ngFor="let attribute of productAttributes">
          <p>
            <span class="attribute-label">{{ attribute.label }}</span>
            <span> {{ attribute.value }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
