import {Component, forwardRef, Inject, Input, signal, WritableSignal} from '@angular/core';
import {
  SearchBoxConnectorParams,
  SearchBoxRenderState,
  SearchBoxWidgetDescription
} from 'instantsearch.js/es/connectors/search-box/connectSearchBox';
import {noop} from 'instantsearch.js/es/lib/utils';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {DOCUMENT, NgIf} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {VfSecretKeyService} from '@core/services/vf-secret-key.service';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-wine-festival-search-box',
  templateUrl: './wine-festival-search-box.component.html',
  styleUrls: ['./wine-festival-search-box.component.scss'],
  standalone: true,
  imports: [
    FontAwesomeModule,
    NgIf
  ]
})
export class WineFestivalSearchBoxComponent extends TypedBaseWidgetComponent<SearchBoxWidgetDescription, SearchBoxConnectorParams> {

  @Input() placeholder: string;

  public query = '';

  public override state: WritableSignal<SearchBoxRenderState> = signal({
    query: '',
    refine: noop,
    clear: noop,
    isSearchStalled: false,
  });

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    @Inject(DOCUMENT) protected injectedDocument: Document,
    private vfSecretKeyService: VfSecretKeyService,
  ) {
    super('SearchBox');
    this.createWidget(
      connectSearchBox,
      {},
      {
        $$widgetType: 'ais.searchBox',
      }
    );
  }

  public handleChange(event: any) {
    let query = event.target.value;
    this.query = query;
    if (query.length === 1 && !this.vfSecretKeyService.isVfSecretKeySet()) {
      query = '00' + query;
    }
    if (query.length === 2 && !this.vfSecretKeyService.isVfSecretKeySet()) {
      query = '0' + query;
    }
    this.state().refine('VF' + query);
  }

  public handleSubmit(event: Event) {
    event.preventDefault();
  }

  public handleReset(event: MouseEvent) {
    this.state().refine('');
    this.query = '';
  }

  protected readonly faTimes = faTimes;
  protected readonly faSearch = faSearch;
}
