import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-storyblok-base',
  template: '<p>Not intended</p>',
  standalone: true
})
export class StoryblokBaseComponent {

  @Input() _uid: string;
  @Input() component: any;
  @Input() name: any;

  /**
   * @deprecated
   */
  @Input() nodequeueId: string;

  /**
   * @deprecated
   */
  @Input() Name: string;

  /**
   * @deprecated
   */
  @Input() col: any;

  /**
   * @deprecated
   */
  @Input() nodequeueMax: string;

  /**
   * @deprecated
   */
  @Input() generalStyling: string;

  /**
   * @deprecated
   */
  @Input() filter_taxonomy: string;

  /**
   * @deprecated
   */
  @Input() marginTop: string;
  /**
   * @deprecated
   */
  @Input() marginBottom: string;
}
