import {Inject, Injectable, makeStateKey, PLATFORM_ID, TrackByFunction, TransferState} from '@angular/core';
import Client from 'storyblok-js-client';
import {environment} from '@env/environment';
import {map, switchMap} from 'rxjs/operators';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {isPlatformServer} from '@angular/common';
import {ScriptService} from './script.service';
import {RoutingService} from './routing.service';
import {ApolloService} from './apollo.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Query} from '../interfaces/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class StoryblokService {
  private sbClient: Client;

  contentCache: any = {};
  productStoryblok: any = undefined;
  executing: boolean = false;
  callbacks: any[] = [];

  protected isInitialized = false;
  private _storyblokInputChange = new BehaviorSubject<{ story: any } | undefined>(undefined);

  get storyblokInputChange(): Observable<{ story: any } | undefined> {
    return this._storyblokInputChange.asObservable();
  }

  constructor(
    private apollo: ApolloService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: object,
    private scriptService: ScriptService,
    private routingService: RoutingService,
    protected httpClient: HttpClient
  ) {
  }

  public _storyblokMenuInputChange = new BehaviorSubject<{ story: any } | undefined>(undefined);

  get storyblokMenuInputChange(): Observable<{ story: any } | undefined> {
    return this._storyblokMenuInputChange.asObservable();
  }

  getImageObject(url: { filename: string } | undefined, width: number | undefined = undefined, height: number | undefined = undefined): { imgHeight: number | undefined, imgWidth: number | undefined, optimizedUrl: string } | undefined {
    if (!url?.filename) {
      return undefined;
    }
    const dimensions = this.getImageSize(url.filename);
    const optimizedUrl = url.filename;
    if (width === dimensions.imgWidth || height === dimensions.imgHeight) {
      return {...dimensions, optimizedUrl};
    }
    if (!dimensions.imgWidth || !dimensions.imgHeight) {
      return {...dimensions, optimizedUrl};
    }
    if (width && width !== dimensions.imgWidth) {
      const factor = width / dimensions.imgWidth;
      return {imgWidth: width, imgHeight: Math.round(dimensions.imgHeight * factor), optimizedUrl};
    }
    if (height && height !== dimensions.imgHeight) {
      const factor = height / dimensions.imgHeight;
      return {imgWidth: Math.round(dimensions.imgWidth * factor), imgHeight: height, optimizedUrl};
    }
    return {...dimensions, optimizedUrl};
  }

  public isPreviewStoryblokPage(params: Record<string, string>) {
    if (isPlatformServer(this.platformId)) {
      return false;
    }
    return Object.keys(params || {}).join('-').includes('_storyblok');
  }

  public storyblokEditCheck(data: any, promise: any) {
    if (data) {
      return of({story: data});
    }

    return from(promise);
  }

  getStoryObservable(slug: string, queryParams: Record<string, string>): Observable<any> {
    return from(this.checkStoryblokEditor(queryParams)).pipe(
      switchMap(result => this.getStory(slug, {version: result, from_release: queryParams['_storyblok_release']})),
      map(data => data?.story?.content),
    )
  }

  getStoryPromise(slug: string, queryParams: Record<string, string>): Promise<any> {
    return this.checkStoryblokEditor(this.routingService.getQueryParams()).then(result => {
      return this.getStory(slug, {version: result, from_release: queryParams['_storyblok_release']});
    });
  }

  async checkStoryblokEditor(queryParamMap: Record<string, string>): Promise<any> {
    if (!this.isPreviewStoryblokPage(queryParamMap)) {
      return environment.storyblok.enforceDraft ? 'draft' : 'published';
    }

    if (!this.isInitialized) {
      this.isInitialized = true;
      await this.scriptService.addScript({
        type: 'text/javascript',
        src: 'https://app.storyblok.com/f/storyblok-latest.js'
      });
      (window as any).storyblok?.init();
      (window as any).storyblok?.on(['input'], (data: any) => {
        if (data?.story?.name !== 'menu') {
          this._storyblokInputChange.next(data.story);
        } else {
          this._storyblokMenuInputChange.next(data.story);
        }
      });
      (window as any).storyblok?.on(['change', 'published'], () => window.location.reload());
    }

    const accessToken = await this.getPreviewToken(queryParamMap);
    this.setAccessToken(accessToken);

    return 'draft';
  }

  setAccessToken(token: string) {
    this.sbClient = new Client({
      accessToken: token
    });
  }

  formatStory(story: any) {
    if (story) {
      if (story.story.content.seo) {
        story.story.content.seo._uid = story?.story.uuid;
        story.story.content.seo.og_type = story?.story.content.og_type;
        story.story.content.seo.og_site_name = story?.story.content.og_site_name;
        story.story.content.seo.og_updated_time = story?.story.content.og_updated_time;
        story.story.content.seo.article_published_time = story?.story.content.article_published_time;
        story.story.content.seo.article_modified_time = story?.story.content.article_modified_time;
        story.story.content.seo.shortlink = story?.story.content.shortlink;
        story.story.content.seo.canonical = story?.story.content.canonical;
        story.story.content.seo.published_at = story?.story.published_at;
        story.story.content.seo.first_published_at = story?.story.first_published_at;
        story.story.content.seo.noindex = story?.story.content.noindex;
      }

      if (story.story.content.breadcrumbs && story.story.content.breadcrumbs.length <= 0) {
        story.story.content.breadcrumbs.push(story?.story.name);
      }
    }
    return story;
  }

  async getStory(slug: string, params?: object): Promise<any> {
    const isDraft = (params as any)?.version === 'draft';
    slug = slug.startsWith('/') ? slug.substring(1) : slug;

    if (slug === '') {
      slug = 'frontpage';
    }

    const stateKey = makeStateKey(slug);
    if (this.transferState.hasKey(stateKey) && !isDraft) {
      const result = this.transferState.get<any>(stateKey, null) ?? undefined;
      this.transferState.remove(stateKey);
      this.contentCache[slug] = result;
      return result;
    }

    if (Object.keys(this.contentCache).indexOf(slug) !== -1 && !isDraft) {
      return this.contentCache[slug] ?? undefined;
    }

    let storyPromise;
    if (isDraft) {
      if (environment.storyblok.enforceDraft) {
        this.setAccessToken(environment.storyblok.public_accessToken);
      }
      storyPromise = this.sbClient.getStory(slug, params).then(d => d.data);
    } else {
      storyPromise = this.httpClient.get(`${environment.supervin.api + environment.supervin.storyblokPath}?slug=${slug}`).toPromise();
    }

    return storyPromise
      .then(res => {
        this.transferState.set<any>(makeStateKey(slug), res);
        this.contentCache[slug] = res;
        return res;
      })
      .catch(error => {
        const httpStatus = (error).response?.status;
        if (error instanceof HttpErrorResponse && [404, undefined].indexOf(httpStatus) !== -1) {
          this.contentCache[slug] = undefined;
          this.transferState.remove<any>(makeStateKey(slug));
          return;
        }
        throw error;
      });
  }

  getPreviewToken(queryParamMap: Record<string, string>): Promise<string> {
    return this.apollo.queryPromise<Query>({
      queryName: 'getStoryblokPreviewTokenQuery',
      variables: {
        space_id: queryParamMap['_storyblok_tk[space_id]'],
        token: queryParamMap['_storyblok_tk[token]'],
        timestamp: parseInt(queryParamMap['_storyblok_tk[timestamp]'], 10),
      }
    }).then(res => {
      return res.data.getStoryblokPreviewToken?.accessToken as string;
    })
  }

  getCols(col: any) {
    if (!col) {
      return;
    }
    const cols = [];
    if (col.xlarge) {
      cols.push('col-xl-' + col.xlarge);
    }
    if (col.large) {
      cols.push('col-lg-' + col.large);
    }
    if (col.medium) {
      cols.push('col-md-' + col.medium);
    }
    if (col.small) {
      cols.push('col-sm-' + col.small);
    }
    if (col.xsmall) {
      cols.push('col-' + col.xsmall);
    }
    return cols;
  }

  getImageUrl(url: any, width: any = null, quality = null) {
    if (!url) {
      return '';
    }
    return url.filename;
  }

  public getImageSize(filename: string | undefined): { imgHeight: number | undefined, imgWidth: number | undefined } {
    // const filename = spot.backgroundImage.filename as string;
    if (!filename) {
      return {imgHeight: undefined, imgWidth: undefined}
    }
    let imgHeight = undefined;
    let imgWidth = undefined;
    if (filename) {
      const fileSize = filename?.match(/.*\/f\/\d+\/(\d+x\d+)\/.*/)?.[1];
      if (!fileSize) {
        console.warn('could not find file size for ' + filename);
      } else {
        const [width, height] = fileSize.split('x');
        imgHeight = parseInt(height);
        imgWidth = parseInt(width);
      }
    }

    return {imgHeight, imgWidth};
  }

  trackByUID(): TrackByFunction<any> {
    return (index, item) => {
      return item._uid;
    };
  }

  getStoryblokIcons() {
    return new Promise(async(resolve) => {
      if (this.productStoryblok) {
        return resolve(this.productStoryblok);
      }
      if (this.executing) {
        this.callbacks.push((value: any)=>{
          resolve(value);
        });
        return;
      }
      this.executing = true;
      const result = await this.checkStoryblokEditor(this.routingService.getQueryParams());
      const data = await this.getStory('product', { version: result});
      this.productStoryblok = data?.story?.content;

      for (const callback of this.callbacks) {
        callback(this.productStoryblok);
      }
      resolve(this.productStoryblok);
      this.executing = false;
    });
  }
}
