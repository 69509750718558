import {Injectable} from '@angular/core';
import {Head, HeadLink, HeadMeta, PageInfo, Storyblok} from '../interfaces/generated/graphql';
import {environment} from '@env/environment';
import {StoryblokService} from './storyblok.service';
import {RoutingService} from './routing.service';
import {StoryblokDynamicInputService} from '@core/services/storyblok-dynamic-input.service';

@Injectable({
  providedIn: 'root'
})
export class StoryblokPageService {

  _seo: any;
  private _breadcrumb_style: any;

  constructor(
    private storyblok: StoryblokService,
    private routingService: RoutingService,
    private storyblokDynamicInputService: StoryblokDynamicInputService
  ) {
  }

  getBreadcrumbs(pageInfo: PageInfo) {
    if (pageInfo.type !== 'STORYBLOK_PAGE' && pageInfo.type !== 'NOT_FOUND') {
      return null;
    }
    const storyblok = (pageInfo?.pageData as Storyblok)?.storyblok;
    const storyblokData = storyblok && JSON.parse(storyblok)?.story;
    const value = storyblokData?.content?.breadcrumbs;
    if (!value) {
      return [];
    }

    if (value.length === 0) {
      const pageName = storyblokData.name;
      return [
        {label: 'Forside', path: '/'},
        {
          label: `${pageName.charAt(0).toUpperCase()}${pageName.slice(1)}`,
          path: this.routingService.getURL() || ''
        }
      ];
    }

    return value.filter((v: any) => !!v.url.url).map((v: any) => {
      return {label: v.title, path: this.storyblokDynamicInputService.replaceDynamicInputs(v.url.url, true)()};
    });

  }

  get breadcrumb_style() {
    return this._breadcrumb_style;
  }


  seoToHead(seo: any, keys: string[], valueKey: 'property' | 'name'): HeadMeta[] {
    return keys.reduce((previousValue, currentValue) => {
      previousValue.push({
        content: seo[currentValue],
        [valueKey]: currentValue.replace('_', ':')
      });
      return previousValue;
    }, [] as HeadMeta[]);
  }

  get baseUrl() {
    const url = this.routingService.getURL();
    return `https://${environment.supervin.frontendHostname}/${url}`;
  }

  getHead(pageInfo: PageInfo): Head | null {
    if (pageInfo.type !== 'STORYBLOK_PAGE' && pageInfo.type !== 'NOT_FOUND') {
      return null;
    }

    const pageDataStoryblok = (pageInfo?.pageData as Storyblok)?.storyblok;
    if (!pageDataStoryblok) {
      return {};
    }
    const storyblokData = JSON.parse(pageDataStoryblok);
    const storyblok = this.storyblok.formatStory(storyblokData);
    const seo = storyblok.story.content.seo;

    if (!seo) {
      return {};
    }

    let meta: HeadMeta[] = [];
    let link: HeadLink[] = [];

    if (seo.og_updated_time) {
      seo.og_updated_time = seo.published_at;
    }

    meta.push(...this.seoToHead(seo, Object.keys(seo).filter(d => d.indexOf('og_') === 0), 'property'));
    meta.push(...this.seoToHead(seo, Object.keys(seo).filter(d => d.indexOf('twitter_') === 0), 'name'));
    meta.push(...this.seoToHead(seo, ['description'], 'name'));

    if (seo.article_published_time) {
      meta.push({
        property: 'article:published_time',
        content: seo.first_published_at
      });
    }

    if (seo.article_modified_time) {
      meta.push({
        property: 'article:modified_time',
        content: seo.published_at
      });
    }

    if (seo.noindex) {
      meta.push({
        name: 'robots',
        content: 'noindex,follow'
      });
    }

    meta.push({
      property: 'og:url',
      content: this.baseUrl
    });

    link.push({
      rel: 'canonical',
      href: seo.canonical ? seo.canonical : this.baseUrl.split('#')[0]
    });

    link.push({
      rel: 'shortlink',
      href: seo.shortlink
    });

    meta = meta.filter(item => item.content);
    link = link.filter(item => item.href);

    return {
      id: seo._uid,
      title: seo.title,
      meta,
      link
    };
  }
}
