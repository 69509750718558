<div [appStoryblok]="_editable()">
  @if (title()) {
    <p class="countdown-title">{{ title() }}</p>
  }
  <div class="countdown-wrapper">
    @if (showDaysElement()) {
      <div [style.border-color]="color_theme()?.value" class="countdown-unit">
        <p class="countdown-number" [style.font-weight]="font_weight()">{{ date()?.days }}</p>
        <p class="countdown-text">{{ date()?.days === 1 ? 'DAG' : 'DAGE' }}</p>
      </div>
    }
    <div [style.border-color]="color_theme()?.value" class="countdown-unit">
      <p [style.font-weight]="font_weight()" class="countdown-number">{{ dateHours() }}</p>
      <p class="countdown-text">{{ date()?.hours === 1 ? 'TIME' : 'TIMER' }}</p>
    </div>
    <div [style.border-color]="color_theme()?.value" class="countdown-unit">
      <p [style.font-weight]="font_weight()" class="countdown-number">{{ date()?.minutes }}</p>
      <p class="countdown-text">{{ date()?.minutes === 1 ? 'MINUT' : 'MINUTTER' }}</p>
    </div>
    <div [style.border-color]="color_theme()?.value" class="countdown-unit">
      <p [style.font-weight]="font_weight()" class="countdown-number">{{ date()?.seconds }}</p>
      <p class="countdown-text">{{ date()?.seconds === 1 ? 'SEKUND' : 'SEKUNDER' }}</p>
    </div>
  </div>
</div>
