import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export type SuperpayEvent = 'authentication' | 'age-verified' | 'auth-on-load' | 'open-reset-password' | 'cart-change';

@Injectable({providedIn: 'root'})
export class SuperpayEventService {

  superpayEvents = new Subject<{ event: SuperpayEvent, data: any }>();

  getSuperpayEvents() {
    return this.superpayEvents.asObservable();
  }

  runSuperpayEvent(event: SuperpayEvent, data: any): void {
    this.superpayEvents.next({event, data});
  }

}
