export const parseNumberInput = (input?: number | string) => {
  return typeof input === 'string' ? parseInt(input, 10) : input;
}
export function bem(widgetName: string) {
  const cx = function(element?: string, subElement?: string) {
    let cssClass = `ais-${widgetName}`;
    if (element) {
      cssClass += `-${element}`;
    }
    if (subElement) {
      cssClass += `--${subElement}`;
    }
    return cssClass;
  };
  return cx;
}
