import {Component, forwardRef, Inject, Input, signal, ViewChild, WritableSignal,} from '@angular/core';

import {connectRange} from 'instantsearch.js/es/connectors';

import {
  RangeBoundaries,
  RangeConnectorParams,
  RangeRenderState,
  RangeWidgetDescription,
} from 'instantsearch.js/es/connectors/range/connectRange';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {noop} from 'instantsearch.js/es/lib/utils';
import {parseNumberInput} from '@core/instantsearch/instantsearch-utils';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {NouisliderComponent} from 'ng2-nouislider';

@Component({
  template: '<p>Should not be used directly</p>',
  standalone: true,
})
export class InstantsearchBaseRangeSliderComponent extends TypedBaseWidgetComponent<
  RangeWidgetDescription,
  RangeConnectorParams
> {
  @ViewChild('slider', {static: false})
  public slider: NouisliderComponent;

  // rendering options
  @Input() public pips: boolean = true;
  @Input() public tooltips: boolean = true;

  // instance options
  @Input() public attribute: RangeConnectorParams['attribute'];
  @Input() public precision?: RangeConnectorParams['precision'];

  public override state: WritableSignal<RangeWidgetDescription['renderState']> = signal({
    canRefine: false,
    format: {
      from: () => '',
      to: () => '',
    },
    range: {min: 0, max: 0},
    refine: noop,
    start: [0, 0],
    sendEvent: noop,
  });

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent
  ) {
    super('RangeSlider');
  }

  public override ngOnInit() {
    this.createWidget(
      connectRange,
      {
        attribute: this.attribute,
        precision: parseNumberInput(this.precision),
      },
      {
        $$widgetType: 'ais.rangeSlider',
      }
    );

    super.ngOnInit();
  }

  public override updateState = (state: RangeRenderState, isFirstRendering: boolean) => {
    if (state.range.min === state.range.max) {
      return;
    }
    // update component inner state
    this.state.set(state);
  };

  public handleChange = (values: RangeBoundaries) => {
    this.state().refine(values);
  };
}
