import {ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {CountdownResult, CountdownService} from '../../../../../../../core/services/countdown.service';
import {NgIf} from '@angular/common';


@Component({
  selector: 'app-product-order',
  templateUrl: './product-order.component.html',
  styleUrls: ['./product-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class ProductOrderComponent implements OnInit, OnDestroy {

  hours: number;
  minutes: number;
  distance: number;
  now: number;

  target: Date;

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected countdownService: CountdownService,
    protected ngZone: NgZone
  ) {
    this.target = new Date(new Date().setHours(15, 30, 0))
    this.callback(this.countdownService.calculateDistance(this.target), false);
  }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.countdownService.addCountdown('countdown-order', {
        target: this.target,
        callback: result => {
          this.callback(result, true);
        }
      })
    })
  }

  protected callback(result: CountdownResult, detectChanges: boolean) {
    const hoursDiff = result.hours !== this.hours;
    const minutesDiff = result.minutes !== this.minutes;
    this.distance = result.distance;
    this.hours = result.hours;
    this.minutes = result.minutes;
    if ((hoursDiff || minutesDiff) && detectChanges) {
      this.changeDetector.detectChanges();
    }
  }

  // on destroy - clear if not cleared or is set
  ngOnDestroy() {
    this.countdownService.removeCountdown('countdown-order');
  }
}


