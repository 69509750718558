import {afterRender, AfterRenderPhase, Component, computed, input, signal, ViewChild} from '@angular/core';
import {LoadingIconComponent} from '../loading-icon/loading-icon.component';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {TrackEventService} from '@core/services/track-event.service';

@Component({
  selector: '[app-read-more]',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  standalone: true,
  imports: [
    LoadingIconComponent,
    NgIf,
    FaIconComponent,
    NgTemplateOutlet
  ]
})
export class ReadMoreComponent {

  useReadMore = input<boolean>(false);
  showFadeOut = input<boolean>(false);
  backgroundColor = input<string>('#ffffff');

  isBodyLong = signal(false);
  isBodyExpanded = signal(false);

  maxBodyHeight = input<number | undefined>();

  maxHeight = this.getMaxHeightSignal();
  background = computed(() => `linear-gradient(rgba(255, 255, 255, 0), ${this.backgroundColor()})`);

  eventSourceName = input<string | undefined>(undefined);

  @ViewChild('bodyWrapper') bodyWrapper: any;
  @ViewChild('bodyContent') bodyContent: any;
  protected readonly faAngleDown = faAngleDown;
  protected readonly faAngleUp = faAngleUp;

  constructor(
    private trackEventService: TrackEventService
  ) {
    afterRender(() => {
      if (this.useReadMore()) {
        this.isBodyLong.set(this.checkBodyLong());
      }
    }, {phase: AfterRenderPhase.Read});
  }

  getMaxHeightSignal() {
    return computed(() => {
      if (this.isBodyExpanded() || !this.maxBodyHeight()) {
        return 'none';
      }
      return this.maxBodyHeight() + 'px';
    });
  }

  checkBodyLong() {
    if (!this.bodyContent || !this.bodyWrapper) {
      return false;
    }
    const bodyContentHeight = this.bodyContent.nativeElement.clientHeight;
    const bodyWrapperHeight = this.bodyWrapper.nativeElement.clientHeight;
    if (bodyContentHeight === 0) {
      return false;
    }

    return bodyContentHeight > bodyWrapperHeight;
  }

  toggleBody() {
    this.isBodyExpanded.set(!this.isBodyExpanded());
    this.trackEventService.trackEvent('click', {
      clickEvent: this.isBodyExpanded() ? 'Read More' : 'Read Less',
      eventSourceName: this.eventSourceName()
    });
  }
}
