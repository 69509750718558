import {ChangeDetectionStrategy, Component, input, OnInit} from '@angular/core';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {YouTubePlayerModule} from '@angular/youtube-player';
import {
  ProductTrendingItemsComponent
} from '../../../page/components/product/components/product-trending-items/product-trending-items.component';
import {SearchPageAlgoliaComponent} from '@modules/page/components/search-page-algolia/search-page-algolia.component';
import {SearchPageComponent} from '@modules/page/components/search-page/search-page.component';
import {AlgoliaRecommendService} from '@core/services/algolia-recommend.service';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {ListPageObjectIds} from '@core/interfaces/generated/graphql';
import {CustomProductListFilter} from '@core/services/algolia.service';

@Component({
  selector: 'app-storyblok-trending-products',
  templateUrl: './storyblok-trending-products.component.html',
  styleUrls: ['./storyblok-trending-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, YouTubePlayerModule, ProductTrendingItemsComponent, SearchPageAlgoliaComponent, SearchPageComponent, AsyncPipe]
})
export class StoryblokTrendingProductsComponent implements OnInit {

  viewType = input<'list' | 'carousel'>('carousel');
  facetName = input<string | undefined>();
  facetValue = input<string | undefined>();
  maxRecommendations = input<number>(9);
  _editable = input<any>();

  trendingItems$: Observable<CustomProductListFilter>;

  constructor(
    private algoliaRecommendService: AlgoliaRecommendService,
  ) {
  }

  ngOnInit() {
    this.trendingItems$ = from(this.algoliaRecommendService.getTrendingProducts(this.maxRecommendations(), '', this.facetName(), this.facetValue())).pipe(
      map((products) => {
        const objectIds = products.map((product) => {
          return {vismaId: product.vismaProductNumber, objectId: 0, weight: 0} as ListPageObjectIds
        });
        return {
          types: [],
          objectIds: objectIds,
          title: 'Trending Products',
          storyblok: undefined
        } as CustomProductListFilter
      })
    );
  }

}
