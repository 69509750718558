import {Component} from '@angular/core';

@Component({
  selector: 'app-user-group-icon',
  styles: `
    :host {
      height: 25px;
      width: 31px;
      display: inline-block;
      color: white;
      line-height: 0;
    }
  `,
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="25" viewBox="0 0 32 25" fill="none">
      <path
        d="M7.57324 0C8.60924 0 9.60281 0.41155 10.3354 1.14411C11.0679 1.87668 11.4795 2.87025 11.4795 3.90625C11.4795 4.94225 11.0679 5.93582 10.3354 6.66839C9.60281 7.40095 8.60924 7.8125 7.57324 7.8125C6.53724 7.8125 5.54367 7.40095 4.81111 6.66839C4.07854 5.93582 3.66699 4.94225 3.66699 3.90625C3.66699 2.87025 4.07854 1.87668 4.81111 1.14411C5.54367 0.41155 6.53724 0 7.57324 0ZM25.542 0C26.578 0 27.5716 0.41155 28.3041 1.14411C29.0367 1.87668 29.4482 2.87025 29.4482 3.90625C29.4482 4.94225 29.0367 5.93582 28.3041 6.66839C27.5716 7.40095 26.578 7.8125 25.542 7.8125C24.506 7.8125 23.5124 7.40095 22.7799 6.66839C22.0473 5.93582 21.6357 4.94225 21.6357 3.90625C21.6357 2.87025 22.0473 1.87668 22.7799 1.14411C23.5124 0.41155 24.506 0 25.542 0ZM0.541992 14.585C0.541992 11.709 2.87598 9.375 5.75195 9.375H7.83691C8.61328 9.375 9.35059 9.5459 10.0146 9.84863C9.95117 10.2002 9.92188 10.5664 9.92188 10.9375C9.92188 12.8027 10.7422 14.4775 12.0361 15.625C12.0264 15.625 12.0166 15.625 12.002 15.625H1.58203C1.01074 15.625 0.541992 15.1563 0.541992 14.585ZM20.332 15.625C20.3223 15.625 20.3125 15.625 20.2979 15.625C21.5967 14.4775 22.4121 12.8027 22.4121 10.9375C22.4121 10.5664 22.3779 10.2051 22.3193 9.84863C22.9834 9.54102 23.7207 9.375 24.4971 9.375H26.582C29.458 9.375 31.792 11.709 31.792 14.585C31.792 15.1611 31.3232 15.625 30.752 15.625H20.3369H20.332ZM11.4795 10.9375C11.4795 9.6943 11.9734 8.50201 12.8524 7.62294C13.7315 6.74386 14.9238 6.25 16.167 6.25C17.4102 6.25 18.6025 6.74386 19.4816 7.62294C20.3606 8.50201 20.8545 9.6943 20.8545 10.9375C20.8545 12.1807 20.3606 13.373 19.4816 14.2521C18.6025 15.1311 17.4102 15.625 16.167 15.625C14.9238 15.625 13.7315 15.1311 12.8524 14.2521C11.9734 13.373 11.4795 12.1807 11.4795 10.9375ZM6.79199 23.6963C6.79199 20.1025 9.70703 17.1875 13.3008 17.1875H19.0283C22.627 17.1875 25.542 20.1025 25.542 23.6963C25.542 24.4141 24.9609 25 24.2383 25H8.09082C7.37305 25 6.78711 24.4189 6.78711 23.6963H6.79199Z"
        fill="white"/>
    </svg>
  `,
  standalone: true
})

export class UserGroupIconComponent {

}
