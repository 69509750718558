import {Injectable, TemplateRef} from '@angular/core';

export type Toast = { textOrTpl: any | TemplateRef<any>, classname?: string, delay?: number }

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public toasts: Toast[] = [];

  showDefault(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textOrTpl, ...options});
  }

  showDanger(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    options.classname = 'bg-danger text-light';
    this.toasts.push({textOrTpl, ...options});
  }

  showSuccess(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    options.classname = 'bg-success text-light';
    this.toasts.push({textOrTpl, ...options});
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

}
