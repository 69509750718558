import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import connectRange, {
  RangeConnectorParams,
  RangeRenderState,
  RangeWidgetDescription
} from 'instantsearch.js/es/connectors/range/connectRange';
import {Component, computed, forwardRef, Inject, Input, signal, WritableSignal} from '@angular/core';
import {noop} from 'instantsearch.js/es/lib/utils';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {parseNumberInput} from '@core/instantsearch/instantsearch-utils';

@Component({
  template: '',
  standalone: true,
})
export class InstantSearchBaseRangeInputComponent extends TypedBaseWidgetComponent<
  RangeWidgetDescription,
  RangeConnectorParams
> {
  // rendering options
  @Input() public currency: string = '$';
  @Input() public separator: string = 'to';
  @Input() public submitLabel: string = 'Go';

  // instance options
  @Input() public attribute: RangeConnectorParams['attribute'];
  @Input() public min?: RangeConnectorParams['min'];
  @Input() public max?: RangeConnectorParams['max'];
  @Input() public precision?: RangeConnectorParams['precision'] = 0;

  canRefine = computed(() => this.state().range.min !== this.state().range.max)

  // inner state
  public minInputValue?: number;
  public maxInputValue?: number;

  get step() {
    const precision = parseNumberInput(this.precision);
    return 1 / Math.pow(10, precision || 2);
  }

  public override state: WritableSignal<RangeRenderState> = signal({
    range: { min: undefined, max: undefined },
    refine: noop,
    start: [0, 0],
    canRefine: false,
    format: {
      from: () => '',
      to: () => '',
    },
    sendEvent: undefined as any,
  });

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent
  ) {
    super('RangeInput');
  }

  public override ngOnInit() {
    this.createWidget(connectRange, {
      attribute: this.attribute,
      max: parseNumberInput(this.max),
      min: parseNumberInput(this.min),
      precision: parseNumberInput(this.precision),
    });

    super.ngOnInit();
  }

  public handleChange(event: any, type: string) {
    const value = parseNumberInput(event.target.value);

    if (type === 'min') {
      this.minInputValue = value;
    } else {
      this.maxInputValue = value;
    }
  }

  public handleSubmit(event: Event) {
    event.preventDefault();
    this.state().refine([this.minInputValue, this.maxInputValue]);
  }
}
