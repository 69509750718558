import {Component, EventEmitter, input, Output} from '@angular/core';
import {NgClass} from '@angular/common';
import {
  InstantSearchBaseRangeInputComponent
} from '@core/instantsearch/components/instantsearch-base-range-input.component';


@Component({
  selector: 'app-search-range-input',
  templateUrl: './range-input.component.html',
  styleUrls: ['./range-input.component.scss'],
  standalone: true,
  imports: [NgClass]
})
export class RangeInputComponent extends InstantSearchBaseRangeInputComponent {

  @Output() inputSubmit: EventEmitter<any> = new EventEmitter();

  hide = input(true);

  override handleSubmit(event: Event) {
    event.preventDefault();
    if (!this.minInputValue) {
      this.minInputValue = undefined;
    }
    if (!this.maxInputValue) {
      this.maxInputValue = undefined;
    }
    this.inputSubmit.emit({min: this.minInputValue, max: this.maxInputValue});
    this.state().refine([this.minInputValue, this.maxInputValue]);
  }

}
