import {Component, computed, Inject, input, PLATFORM_ID} from '@angular/core';
import {GridService} from '@core/services/grid.service';
import {StoryblokBaseComponent} from '@modules/storyblok/components/storyblok-base.component';
import {isPlatformServer} from '@angular/common';

@Component({
  selector: 'app-storyblok-read-more',
  template: '<p>Not intended</p>',
  standalone: true
})
export class StoryblokReadMoreComponent extends StoryblokBaseComponent {

  read_more_active = input<boolean>(false);
  read_more_background_color = input<{ value: string }>({value: '#ffffff'});
  read_more_show_fade_out = input<boolean>(false);
  read_more_max_height_mobile = input<number | undefined>();
  read_more_max_height_tablet = input<number | undefined>();
  read_more_max_height_desktop = input<number | undefined>();

  maxHeight = computed(() => {
    if (isPlatformServer(this.platformId)) {
      return 0;
    }
    if (this.gridService.isExtraSmall) {
      return this.read_more_max_height_mobile();
    }
    if (this.gridService.isSmall && !this.gridService.isLarge) {
      return this.read_more_max_height_tablet();
    }
    return this.read_more_max_height_desktop();
  });

  constructor(
    private gridService: GridService,
    @Inject(PLATFORM_ID) public platformId: object
  ) {
    super();
  }
}
