<div class="container-fluid search-facets">
  <div class="container noPadding">
    <nav class="navbar navbar-expand-md navbar-dark" #filter>
      <button *ngIf="isCollapsed()" class="navbar-toggler"
              (click)="collapse(!isCollapsed())"
              type="button" data-toggle="collapse"
              data-target="#collapsingNavbar2">
        <span class="filter-text-style">
          FILTRER &nbsp;&nbsp; <fa-icon style="font-size: 16px !important;" [icon]="faPlus"></fa-icon>
        </span>
      </button>
      <div *ngIf="!isCollapsed()" class="filter-header-box">
        <span (click)="collapse(!isCollapsed())" class="filter-header"
              data-target="#collapsingNavbar2" data-toggle="collapse"
              type="button">
              Luk
        </span>
        <span class="filter-text-style">FILTRE</span>
        <app-clear-refinements
          [includedAttributes]="shownFilterAttributes"
          [resetLabel]="'Ryd filtrering'"
          class="d-inline-block text-end"/>
      </div>
      <div class="navbar-collapse collapse" [ngClass]="[isCollapsed() ? 'hide' : 'show']">
        <div class="scroll-container" id="filter-height">
          <app-desktop-top-filter [hasLoadedHits]="hasLoadedHits()"
                                  [shownFilters]="shownFilters"></app-desktop-top-filter>
        </div>
        <div class="button-wrapper d-md-none">
          <button (click)="collapse(true)" class="btn btn-primary w-100">SE {{ hits }} PRODUKTER</button>
        </div>
      </div>
    </nav>
  </div>
</div>
