<div
  [disabled]="disableAddToCartBtn|async"
  [success]="success|async"
  app-loading
  class="d-flex align-items-center buy-wrapper"
  text="Tilføjet til kurven">
  <div class="d-flex flex-column flex-grow-1">
    @if (productPrice$|async; as pricing) {
      <p class="price-text m-0">
      <span class="price-number">
        {{ pricing.price | currency }}
      </span>
        DKK pr. {{ product.meta?.units?.shortName }}
      </p>
      <p class="price-description m-0">ved køb af {{ pricing.qty }} stk.</p>
    }
  </div>
  <ng-container *ngIf="product.inStock">
    <app-qty-input
      (submitEvent)="addToCart()"
      [(qty)]="qtyInput"
      [small]="true"
      class="d-flex">
    </app-qty-input>
    <button (click)="addToCart()" class="btn btn-primary btn-buy">KØB</button>
  </ng-container>
  <ng-container *ngIf="!product.inStock">
    <button class="btn btn-light">UDSOLGT</button>
  </ng-container>
</div>
