import {Component, Input} from '@angular/core';
import {WineProduct} from '../../../../../../../core/interfaces/generated/graphql';
import {UrlNameService} from '../../../../../../../core/services/url-name.service';

import {RouterModule} from '@angular/router';
import {NgForOf, NgIf} from '@angular/common';


@Component({
  selector: 'app-product-units',
  templateUrl: './product-units.component.html',
  styleUrls: ['./product-units.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, RouterModule]
})
export class ProductUnitsComponent {

  @Input() product: WineProduct | undefined;

  constructor(
    private urlNameService: UrlNameService,
  ) {
  }

  getFriendlyName(name: any): string {
    let friendlyUrl = name.replace(/[(),´]/g, '');
    friendlyUrl = friendlyUrl.replace(/[.]/g, '-');
    return this.urlNameService.getFriendlyUrl(friendlyUrl);
  }
}
