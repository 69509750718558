import {Inject, Injectable, InjectionToken} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class HostnameService {

  constructor(
    @Inject(APP_HOSTNAME) private hostname: string,
  ) {
  }

  getHostname(): string {
    return this.hostname || window.location.origin;
  }
}

export const APP_HOSTNAME = new InjectionToken<string>('APP_HOSTNAME', {providedIn: 'platform', factory: () => ''});
