import {ImageLoaderConfig} from '@angular/common';
import {environment} from '../environments/environment';

export function customImageLoader(config: ImageLoaderConfig) {
  let url;

  try {
    url = new URL(config.src);
  } catch (e) {
    return config.src;
  }

  let newHost = url.hostname;
  let pathPrefix = '';
  let isCloudflare = false;

  if (url.hostname === 'a.storyblok.com') {
    newHost = environment.supervin.frontendHostname;
    pathPrefix = '/storyblok';
    isCloudflare = true;
  }
  if (`${url.protocol}//${url.hostname}` === environment.supervin.assets) {
    isCloudflare = true;
  }

  if (!isCloudflare) {
    return config.src;
  }

  const quality = config.loaderParams?.['quality'] || 85;

  const filters = [
    `quality=${quality}`,
  ];

  if (config.loaderParams?.['height'] > 0) {
    filters.push(`height=${config.loaderParams?.['height']}`)
  } else {
    filters.push(`width=${config.width || 1250}`)
  }

  return `${url.protocol}//${newHost}/cdn-cgi/image/${filters.join(',')}${pathPrefix}${url.pathname}`;
}
