<div
  *ngIf="!isHidden()"
>
  <ul>
    <li
      *ngFor="let item of state().items; trackBy: trackBy"
    >
      <a [appRouterContext]="trackEventName() ?? ''" [routerLink]="item.value">
        {{item.label}}
      </a>
    </li>
    <li (click)="toggleShowMore($event)" *ngIf="showMoreLimit && state().canToggleShowMore" class="show-more">
      <a>{{state().isShowingMore ? 'Vis færre' : 'Vis alle'}}</a>
    </li>
  </ul>
</div>
