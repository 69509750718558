import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {DynamicModule} from 'ng-dynamic-component';
import {NgComponentOutlet, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {StoryblokDynamicInputService} from '@core/services/storyblok-dynamic-input.service';

@Component({
  selector: 'app-storyblok-image',
  templateUrl: './storyblok-image.component.html',
  styleUrls: ['./storyblok-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, NgIf, StoryblokDirective, DynamicModule, NgComponentOutlet, NgOptimizedImage]
})
export class StoryblokImageComponent extends StoryblokBaseComponent implements OnInit {

  @Input() general_styling: any;
  @Input() _editable: any;
  @Input() asset: any;
  @Input() external_url: string
  @HostBinding('class') class: any;

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    private storyblokDynamicInputService: StoryblokDynamicInputService
  ) {
    super();
  }

  ngOnInit() {
    this.class = this.storyblokStylingService.getClassList(this.general_styling);
  }

  getImageSrc() {
    if (this.asset.id) {
      return this.asset.filename;
    }
    if (this.external_url) {
      return this.storyblokDynamicInputService.replaceDynamicInputs(this.external_url)();
    }
    return '';
  }

}
