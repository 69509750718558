const errorMessageMap: Record<string, string> = {
  'GIFTCARD_CODE_DOES_NOT_EXIST': 'Koden %code% kunne ikke aktiveres, enten fordi din kurv ikke opfylder betingelserne, eller den ikke findes. Tjek for eventuelle tastefejl.',
  'GIFTCARD_ALREADY_APPLIED': 'Der kan kun anvendes ét gavekort ad gangen.',
  'GIFTCARD_IS_EMPTY': 'Gavekortets saldo er 0 kr.',
  'CUSTOMER_DOES_NOT_EXIST': 'Kan ikke finde et login til emailadressen %email%.',
  'INVALID_GIFTCARD_AMOUNT': 'Ugyldigt beløb for gavekort.',
  'INVALID_RESET_PASSWORD_TOKEN': 'Den indtastede kode er ugyldig. Tjek for tastefejl.',
  'GROUP_VOUCHER_DOES_NOT_EXIST': 'Der findes ikke et gruppekøb med koden %groupVoucherCode%.',
  'GROUP_VOUCHER_EXPIRED_OR_INVALID': 'Gruppekøbet er udløbet eller ugyldigt.',
  'NOT_ENOUGH_IN_STOCK': 'Produktet er udsolgt.',
  'COUPON_LIMIT_USES': 'Rabatkoden har været brugt det maksimale antal gange.',
  'COUPON_LIMIT_USES_PER_CUSTOMER': 'Denne rabatkode kan kun bruges én gang pr. kunde.',
  'COUPON_DOESNT_EXIST': 'Rabatkoden findes ikke, eller kunne ikke aktiveres på denne ordre.',
  'COUPON_EXPIRED': 'Rabatkoden er udløbet.',
  'COUPON_CANT_BE_APPLIED': 'Rabatkoden kan ikke anvendes på denne ordre.',
  'EMAIL_NOT_FOUND': 'Der findes ikke en bruger med denne emailadresse.',
  'CUSTOMER_ALREADY_EXISTS_WITH_PASSWORD': 'Der er allerede en bruger med denne email',
  'CUSTOMER_ALREADY_EXISTS_WITHOUT_PASSWORD': 'Der er allerede en bruger med denne email',
  'The password needs at least 8 characters. Create a new password and try again.': 'Din adgangskode skal være mindst 8 bogstaver/tegn og skal indeholde mindst 3 af følgende: Små bogstaver, store bogstaver, tal og/eller specialtegn.',
  'Minimum of different classes of characters in password is 3. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.': 'Din adgangskode skal være mindst 8 bogstaver/tegn og skal indeholde mindst 3 af følgende: Små bogstaver, store bogstaver, tal og/eller specialtegn.',
  'The password token is mismatched. Reset and try again.': 'Øv, du har trykket på et link, der er udløbet! Prøv igen...',
  'Product that you are trying to add is not available.': 'Produktet er ikke tilgængeligt.',
}


export default errorMessageMap;
