import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {StoryblokStylingService} from '../../../../../core/services/storyblok-styling.service';
import {DynamicModule} from 'ng-dynamic-component';
import {NgComponentOutlet, NgForOf, NgIf} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {StoryblokComponentService} from '../../../../../core/services/storyblok-component.service';

@Component({
  selector: 'app-storyblok-group',
  templateUrl: './storyblok-group.component.html',
  styleUrls: ['./storyblok-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DynamicModule, NgForOf, NgIf, NgComponentOutlet]
})
export class StoryblokGroupComponent extends StoryblokBaseComponent implements OnInit {

  components: any;
  selected: string;

  constructor(
    private storyblokComponentService: StoryblokComponentService,
    private storyblokStylingService: StoryblokStylingService,
  ) {
    super();
    this.components = this.storyblokComponentService.getComponents();
  }

  @Input() body: any[];
  @Input() fullWidth: boolean;
  @Input() _editable: any;
  @Input() general_styling: any;

  @HostBinding('class') class: any;

  ngOnInit(): void {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12});
    this.selected = this.body[0].title;
  }

}
