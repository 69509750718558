@if (product().topCta?.type) {
  <div class="position-relative">
    <app-product-top-cta [product]="product()"/>
  </div>
}
<div
  data-rr-shared="true"
  [ngClass]="product()?.isB2b === true ? 'displayCol' : 'displayRow'"
  class="no-gutters product-top product-widget-description">
  <a
    (click)="productClick(product())"
    [ngClass]="product()?.isB2b === true ? 'fullWidthMarginBottom' : 'col4Width'"
    [state]="{queryID: queryID()}"
    [routerLink]="productUrl()"
    [additionalContext]="additionalLinkContext()"
    [appRouterContext]="'productWidget'"
    class="product-image">
    @if (compressedImage()) {
      <img [alt]="product()?.name" [loaderParams]="{height: 250}"
           [ngSrc]="compressedImage() || ''" fill>
    }
    @if (product().productImageBadge?.type) {
      <app-product-save-label [product]="product()"/>
    }
  </a>
  <div
    [ngClass]="product()?.isB2b === true ? 'fullWidth' : 'col8Width'"
    class="product-info">
    <app-product-tags [tags]="product()?.tags ?? []"></app-product-tags>
    <h4>
      <a (click)="productClick(product())"
         [state]="{queryID: queryID()}"
         [routerLink]="productUrl()"
         [additionalContext]="additionalLinkContext()"
         [appRouterContext]="'productWidget'"
      >
        {{product()?.name}}
      </a>
    </h4>
    <a (click)="productClick(product())"
       [innerHTML]="product()?.shortText|safeHtml"
       [state]="{queryID: queryID()}"
       [routerLink]="productUrl()"
       [additionalContext]="additionalLinkContext()"
       [appRouterContext]="'productWidget'"
       class="hit-description short">
    </a>
  </div>
  @if ((ratings()?.length|exists) && !product().meta?.energyLabel) {
    <div class="ratings w-100">
      @for (rating of ratings(); track ratingTracking) {
        <app-product-rating
          [rating]="rating"
          class="d-flex">
        </app-product-rating>
      }
    </div>
  }
  @if (product().meta?.energyLabel) {
    <div class="energy-label">
      <a [href]="product().meta?.productDataSheet" target="_blank">Produktdatablad</a>
      <img (click)="openEnergyLabelDoc(product())" [ngSrc]="product().meta?.energyLabel ?? ''" alt="energy label" height="36"
           width="60">
    </div>
  }
</div>
@if (product()?.id) {
  <app-price-table
    (addedToCart)="addToCart(product())"
    [hideProductDefaultCta]="hideProductDefaultCta()"
    [product]="product()"
    [hitPosition]="hitPosition()"
    [eventSourceName]="eventSourceName()"></app-price-table>
}
