import {Component, computed, forwardRef, Inject, signal, WritableSignal} from '@angular/core';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import connectStats, {
  StatsConnectorParams,
  StatsRenderState,
  StatsWidgetDescription
} from 'instantsearch.js/es/connectors/stats/connectStats';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {NgIf, NgTemplateOutlet} from '@angular/common';

@Component({
  selector: 'app-instantsearch-stats',
  template:  `
    <div [class]="cx()">
      <span class="search-results-count">
        {{ nbHits() }}
        produkter fundet
      </span>

      @if (currentAlgoliaIndex() === 'Products') {
        <span class="search-sort-text">- Bedste pris vises altid først</span>
      }
    </div>
  `,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf
  ],
})
export class InstantSearchStatsComponent extends TypedBaseWidgetComponent<
  StatsWidgetDescription,
  StatsConnectorParams
> {

  currentAlgoliaIndex = computed(() => this.instantSearchInstance.currentState()?.index);
  nbHits = computed(() => this.state()?.nbHits)

  public override state: WritableSignal<StatsRenderState | undefined> = signal(undefined);

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent
  ) {
    super('Stats');
    this.createWidget(connectStats, {});
  }

}
