import {Component} from '@angular/core';

@Component({
  selector: 'app-check-marker-icon',
  styles: `
    :host {
      height: 15px;
      width: 15px;
      display: inline-block;
      color: white;
      line-height: 0;
    }
  `,
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
      <path
        d="M7.35323 0.646461C7.54851 0.841743 7.54851 1.15888 7.35323 1.35416L3.35385 5.35354C3.15857 5.54882 2.84143 5.54882 2.64615 5.35354L0.646461 3.35385C0.45118 3.15857 0.45118 2.84143 0.646461 2.64615C0.841743 2.45087 1.15888 2.45087 1.35416 2.64615L3.00078 4.2912L6.64709 0.646461C6.84237 0.45118 7.15951 0.45118 7.35479 0.646461H7.35323Z"
        fill="white"/>
    </svg>
  `,
  standalone: true
})

export class CheckMarkerIconComponent {

}
