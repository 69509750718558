import {Component, Input, OnInit} from '@angular/core';
import {QtyInputComponent} from '@layout/components/product/qty-input/qty-input.component';
import {Maybe, WineProduct} from '@core/interfaces/generated/graphql';
import {ProductService} from '@core/services/product.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {AsyncPipe, CurrencyPipe, NgIf} from '@angular/common';
import {formatPrice, PricePipe} from '@core/pipes/price.pipe';
import {map} from 'rxjs/operators';
import {CartService} from '@core/services/cart.service';
import {LoadingComponent} from '@layout/components/general/loading/loading.component';
import {GoogleService} from '@core/services/google.service';

@Component({
  selector: 'app-product-sticky-buy-bar',
  templateUrl: './product-sticky-buy-bar.component.html',
  styleUrls: ['./product-sticky-buy-bar.component.scss'],
  standalone: true,
  imports: [
    QtyInputComponent,
    NgIf,
    AsyncPipe,
    CurrencyPipe,
    PricePipe,
    LoadingComponent
  ]
})
export class ProductStickyBuyBarComponent implements OnInit {

  qtyInput: number;
  productPrice$: Observable<{ price: number, qty: Maybe<number | undefined> }>
  disableAddToCartBtn = new BehaviorSubject(false);
  success = new BehaviorSubject(false);

  @Input() product: WineProduct;

  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private googleService: GoogleService,
  ) {
  }

  ngOnInit(): void {
    this.qtyInput = this.product?.defaultQuantity ?? 1;
    this.productPrice$ = this.productService.getDefaultPriceAsync(this.product).pipe(
      map(price => {
        return {
          price: formatPrice(price.discount?.amountIncVat || price.amountIncVat),
          qty: price.minQty
        };
      })
    );
  }

  async addToCart() {
    this.disableAddToCartBtn.next(true);

    const isPreorder = !!this.product?.preorderDate;
    const isEnPrimeur = !!this.product?.isEnPrimeur;
    await this.cartService.addToCart(this.product.vismaProductNumber, this.product.productId as any, this.qtyInput, 'product-page', undefined, undefined, isPreorder, isEnPrimeur);
    this.googleService.addProductToCartEvent(this.product, this.qtyInput);
    this.qtyInput = this.product?.defaultQuantity ?? 1;
    this.disableAddToCartBtn.next(false);
    this.showSuccess();
  }

  showSuccess() {
    this.success.next(true);
    setTimeout(() => {
      this.success.next(false);
    }, 2000);
  }

}
