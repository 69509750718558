import {Component} from '@angular/core';

@Component({
  selector: 'app-gift-icon',
  styles: `
    :host {
      height: 25px;
      width: 27px;
      display: inline-block;
      color: white;
      line-height: 0;
    }
  `,
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="25" viewBox="0 0 28 25" fill="none">
      <path
        d="M10.4646 1.86293C9.85101 1.86293 9.26251 2.1067 8.8286 2.5406C8.3947 2.97451 8.15093 3.56301 8.15093 4.17664C8.15093 4.79027 8.3947 5.37877 8.8286 5.81268C9.26251 6.24658 9.85101 6.49035 10.4646 6.49035H12.7783V12.0432H3.06078C2.44714 12.0432 1.85864 11.7995 1.42474 11.3656C0.990835 10.9317 0.74707 10.3432 0.74707 9.72954V8.80405C0.74707 7.52565 1.78361 6.49035 3.06078 6.49035H7.00087C6.40092 5.59911 6.1686 4.51051 6.35245 3.45201C6.5363 2.3935 7.12209 1.447 7.98742 0.810263C8.85276 0.17353 9.93068 -0.104164 10.9959 0.0352052C12.0612 0.174575 13.0314 0.720225 13.7038 1.55814C14.3762 0.720225 15.3464 0.174575 16.4117 0.0352052C17.477 -0.104164 18.5549 0.17353 19.4202 0.810263C20.2856 1.447 20.8714 2.3935 21.0552 3.45201C21.2391 4.51051 21.0067 5.59911 20.4068 6.49035H25.2724C26.5495 6.49035 27.5861 7.52689 27.5861 8.80405V9.72954C27.5861 11.0079 26.5495 12.0432 25.2724 12.0432H14.6293V6.49035H16.943C17.4006 6.49035 17.848 6.35465 18.2284 6.10042C18.6089 5.84618 18.9055 5.48483 19.0806 5.06206C19.2557 4.63928 19.3015 4.17407 19.2123 3.72526C19.123 3.27644 18.9026 2.86418 18.5791 2.5406C18.2555 2.21702 17.8432 1.99667 17.3944 1.90739C16.9456 1.81812 16.4804 1.86393 16.0576 2.03905C15.6348 2.21417 15.2735 2.51073 15.0192 2.89121C14.765 3.2717 14.6293 3.71903 14.6293 4.17664V6.49035H12.7783V4.17664C12.7783 2.89824 11.7418 1.86293 10.4646 1.86293ZM12.7783 13.8942H2.59804V22.2236C2.59804 22.9599 2.89055 23.6661 3.41124 24.1868C3.93192 24.7075 4.63812 25 5.37449 25H12.7783V13.8942ZM14.6293 13.8942V25H22.9587C23.695 25 24.4012 24.7075 24.9219 24.1868C25.4426 23.6661 25.7351 22.9599 25.7351 22.2236V13.8942H14.6293Z"
        fill="white"/>
    </svg>
  `,
  standalone: true
})

export class GiftIconComponent {

}
