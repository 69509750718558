<!--Header-->

<div data-dismiss="modal" class="modal-header">
  <div class="flex-row d-flex w-100">
    <h4 *ngIf="title" class="modal-title">{{title}}</h4>
    <ng-content select="[title]"></ng-content>
    <button (click)="activeModal.dismiss('Cross click')" class="btn-close" style="box-shadow: none"
            type="button"></button>
  </div>
  <ng-content select="[header]"></ng-content>
</div>


<!--main content-->
<div class="modal-body">
  <ng-content data-dismiss="modal"></ng-content>
</div>

<!--Footer-->

<div class="modal-footer">
  <ng-content select="[footer]"></ng-content>
</div>


