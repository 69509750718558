@if (!hide()) {
  <div [ngClass]="[
          cx(),
          !canRefine ? cx('', 'noRefinement') : ''
        ]">
    <form
      [class]="cx('form')"
      (submit)="handleSubmit($event)"
      novalidate
    >
      <div class="rangeWrapper">
        <label [class]="cx('label')">
          <span [class]="cx('currency')">{{ currency }}</span>
          <input
            (change)="handleChange($event, 'min')"
            [max]="state().range.max"
            [min]="state().range.min"
            [ngClass]="[
                  cx('input'),
                  cx('input', 'min')
                ]"
            [placeholder]="state().range.min"
            [step]="step"
            [value]="minInputValue"
            type="number"
          />
        </label>
        <span [class]="cx('separator')">{{ separator }}</span>
        <label [class]="cx('label')">
          <span [class]="cx('currency')">{{ currency }}</span>
          <input
            (change)="handleChange($event, 'max')"
            [max]="state().range.max"
            [min]="state().range.min"
            [ngClass]="[
                  cx('input'),
                  cx('input', 'max')
                ]"
            [placeholder]="state().range.max"
            [step]="step"
            [value]="maxInputValue"
            type="number"
          />
        </label>
      </div>
      <button
        [class]="cx('submit')"
        (click)="handleSubmit($event)"
      >
        {{ submitLabel }}
      </button>
    </form>
  </div>
}
