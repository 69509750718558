import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  getExpiresDate(days: number = 0, hours: number = 0): Date {
    const date = new Date();
    date.setDate(date.getDate() + days);
    date.setHours(date.getHours() + hours);
    return date;
  }

  set(key: string, value: string, expiresDate: Date, path: string) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    let samesite = 'Lax';
    if (environment.production) {
      samesite = 'Strict';
    }

    document.cookie = `${key}=${value};expires=${expiresDate.toUTCString()};path=${path};samesite=${samesite}`;
  }

  get(key: string): string|null {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    const cookies = this.getCookies();
    return cookies[key] || null;
  }

  protected getCookies(): Record<string, string> {
    return document.cookie.split(';').reduce((cookies, cookie) => {
      const values = cookie.split('=');
      const key = values.shift() as string;
      return {...cookies, [key.trim()]: values.join('=')};
    }, {});
  }

  delete(key: string) {
    const expiresDate = this.getExpiresDate(-1);
    this.set(key, '', expiresDate, '/')
  }

}
