import {AfterViewChecked, Directive, ElementRef, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Directive({
  selector: '[appFormValidationClass]',
  standalone: true
})
export class FormValidationClassDirective implements AfterViewChecked {

  @Input() formField: AbstractControl | null;
  @Input() customValidation: boolean;
  @Input() onlyShowError: boolean = false;
  @Input() validationDisabled: boolean = false;

  constructor(private el: ElementRef) {

  }

  ngAfterViewChecked() {
    if (this.validationDisabled) {
      return;
    }
    let valid;
    let touched;
    if (!this.formField) {
      touched = true;
    } else {
      touched = this.formField.touched;
    }

    if (this.customValidation !== undefined) {
      valid = this.customValidation;
    } else {
      valid = !this.formField?.invalid;
    }

    if (!valid) {
      if (touched) {
        this.el.nativeElement.classList.add('is-invalid');
        this.el.nativeElement.classList.remove('is-valid');
      }
      if (!touched) {
        this.el.nativeElement.classList.remove('is-invalid');
        this.el.nativeElement.classList.remove('is-valid');
      }
    }

    if (valid) {
      this.el.nativeElement.classList.remove('is-invalid');
      if (!this.onlyShowError) {
        this.el.nativeElement.classList.add('is-valid');
      }
    }
  }

}
