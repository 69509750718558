<div [loading]="loading" [success]="success" app-loading text="Tilføjet til kurven"
     [disabled]="product.inStock !== true"
     class="product-row">
  <p class="m-0 vf-id">{{product.vf_id}}</p>
  <p class="product-title">{{product.name}}</p>
  <div class="buy-wrapper">
    <div class="text-end mb-0 me-3 d-md-flex d-none flex-column">
      <p class="unit-text">pr. {{product.meta?.units?.shortName}}
        v. {{product.defaultQuantity}} {{product.meta?.units?.shortName}}</p>
      <p class="price-text">{{price|currency}} kr.</p>
    </div>
    <div class="d-flex flex-column align-self-end">
      <div *ngIf="itemQtySignal() as itemQty" class="d-flex align-self-center text-nowrap">
        <p *ngIf="itemQty" class="m-0">
          {{itemQty}} {{product.meta?.units?.shortName}} i kurven
        </p>
      </div>
      <app-qty-input
        [(qty)]="qty"
        (submitEvent)="addToCart(true)"
        [small]="true"
        [attr.data-vf-id]="product.vf_id"
        class="d-flex"
      ></app-qty-input>
    </div>
    <div class="price-wrapper">
      <div class="text-end mb-2 me-0 d-flex d-md-none flex-column">
        <p class="unit-text">pr. {{product.meta?.units?.shortName}}
          v. {{product.defaultQuantity}} {{product.meta?.units?.shortName}}</p>
        <p class="price-text">{{price|currency}} kr.</p>
      </div>
      <button (click)="addToCart()" class="btn btn-primary text-nowrap btn-buy">
        <fa-icon *ngIf="product.inStock" [icon]="faBasketShopping"></fa-icon>
        {{product.inStock ? 'Køb' : 'Udsolgt'}}
      </button>
    </div>
  </div>
</div>
