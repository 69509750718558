import {Component, Inject, OnDestroy, OnInit, Optional, signal, WritableSignal} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Connector, UnknownWidgetParams, Widget, WidgetDescription} from 'instantsearch.js/es/types';
import {InstantSearchComponent} from './components/instantsearch.component';
import {bem} from '@core/instantsearch/instantsearch-utils';
import {noop} from 'instantsearch.js/es/lib/utils';


@Component({
  selector: 'app-base-widget',
  template: ''
})
export abstract class BaseWidgetComponent<
  TWidgetDescription extends WidgetDescription,
  TConnectorParams extends UnknownWidgetParams
>
  implements OnInit, OnDestroy {

  public widget?: Widget;
  public state: WritableSignal<TWidgetDescription['renderState']> = signal({});
  public cx: ReturnType<typeof bem>;
  public abstract instantSearchInstance: InstantSearchComponent;

  constructor(@Inject('should-not-be-injected') @Optional() widgetName: string) {
    this.cx = bem(widgetName);
  }

  get parent() {
    return this.instantSearchInstance;
  }

  public createWidget(
    connector: Connector<TWidgetDescription, TConnectorParams>,
    options: TConnectorParams,
    additionalWidgetProperties: object = {}
  ) {
    this.widget = {
      ...connector(this.updateState, noop)(options),
      ...additionalWidgetProperties,
    };
  }

  public ngOnInit() {
    if (!this.widget) {
      return;
    }
    this.parent.addWidgets([this.widget]);
  }

  public ngOnDestroy() {
    if (isPlatformBrowser(this.instantSearchInstance.platformId) && this.widget) {
      this.parent.removeWidgets([this.widget]);
    }
  }

  public updateState = (
    state: TWidgetDescription['renderState'],
    isFirstRendering: boolean
  ): Promise<void> | void => {
    if (isFirstRendering) {
      return Promise.resolve().then(() => {
        this.state.set(state)
      });
    }
    this.state.set(state)
  };

  /**
   * Helper to generate class names for an item
   * @param item element to generate a class name for
   */
  public getItemClass(item: { isRefined?: boolean }) {
    const className = this.cx('item');

    if (item.isRefined) {
      return `${className} ${this.cx('item', 'selected')}`;
    }

    return className;
  }
}
