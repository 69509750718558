import {
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  forwardRef,
  Inject,
  input,
  Input,
  Output
} from '@angular/core';
import {RatingReviewFilterComponent} from './rating-review-filter/rating-review-filter.component';
import {AsyncPipe, NgForOf, NgIf} from '@angular/common';
import {RatingPointsRangeComponent} from './rating-points-range/rating-points-range.component';
import connectRatingReviewerFilter, {
  RangeConnectorParams,
  RangeRenderState,
  RangeWidgetDescription,
  ReviewerFilter
} from '@core/instantsearch-connectors/connectRatingReviewerFilter';
import {RendererOptions} from 'instantsearch.js/es/types';
import {AlgoliaService} from '@core/services/algolia.service';
import {BehaviorSubject} from 'rxjs';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';

@Component({
  selector: 'app-rating-filter',
  templateUrl: './rating-filter.component.html',
  styleUrls: ['./rating-filter.component.scss'],
  standalone: true,
  imports: [
    RatingReviewFilterComponent,
    NgForOf,
    RatingPointsRangeComponent,
    NgIf,
    AsyncPipe
  ]
})
export class RatingFilterComponent extends TypedBaseWidgetComponent<RangeWidgetDescription, RangeConnectorParams> {

  @Output() changeEvent: EventEmitter<ReviewerFilter> = new EventEmitter<ReviewerFilter>();
  @Input() min: number = 80;
  @Input() max: number = 100;
  hide = input(true);

  initialized = new BehaviorSubject(false);
  activeReviewers: string[] = [];
  range: number[] = [];

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    private algoliaService: AlgoliaService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super('RatingFilterComponent');
    this.range = [this.min, this.max];
  }

  reviewers = computed(() => {
    const algoliaFacets = this.algoliaService.algoliaFacets();
    const facet = algoliaFacets.find(f => f.instantSearchType === 'ratings-filter');
    return facet?.reviewers || [];
  });

  override ngOnInit() {
    this.createWidget(connectRatingReviewerFilter, {
      min: this.min,
      max: this.max
    });
    super.ngOnInit();
  }

  override updateState = (state: RangeRenderState & RendererOptions<RangeConnectorParams>, isFirstRendering: boolean) => {
    if (isFirstRendering) {
      const start = state.start;
      this.activeReviewers = start.reviewers;
      this.range = [start.range.min, start.range.max];
      this.initialized.next(true);
      this.changeEvent.emit(state.start);
      this.changeDetectorRef.detectChanges();
    }

    if (this.range[0] !== state.start.range.min || this.range[1] !== state.start.range.max) {
      this.range = [state.start.range.min, state.start.range.max];
      this.handleChange(false);
    }

    if (this.activeReviewers.length !== state.start.reviewers.length) {
      this.activeReviewers = state.start.reviewers;
      this.handleChange(false);
    }

    this.state.set(state);
  }

  onInputChange(checked: boolean, reviewer: string) {
    if (checked && !this.activeReviewers.includes(reviewer)) {
      this.activeReviewers.push(reviewer);
    }
    if (!checked && this.activeReviewers.includes(reviewer)) {
      this.activeReviewers = this.activeReviewers.filter(r => r !== reviewer);
    }
    this.handleChange(true);
  }

  onRangeChange(range: number[]) {
    this.range = range;
    this.handleChange(true);
  }

  isReviewerChecked(reviewer: string) {
    return this.activeReviewers.includes(reviewer);
  }

  private handleChange = (refine: boolean) => {
    const values = {
      range: {min: this.range[0], max: this.range[1]},
      reviewers: this.activeReviewers
    }
    if (refine) {
      this.state()?.refine(values);
    }
    this.changeEvent.emit(values);
  }

}
