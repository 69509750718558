import {Component, computed, Inject, PLATFORM_ID, Signal, ViewChild} from '@angular/core';
import {AnnouncekitComponent, AnnouncekitModule} from 'announcekit-angular';
import {CookiebotConsent, CookiebotService} from '@core/services/cookiebot.service';
import {isPlatformBrowser, NgIf} from '@angular/common';
import {toSignal} from '@angular/core/rxjs-interop';
import {
  CookieConsentModalComponent
} from '@layout/components/modals/cookie-consent-modal/cookie-consent-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-menu-announcekit',
  standalone: true,
  imports: [
    AnnouncekitModule,
    NgIf
  ],
  templateUrl: './menu-announcekit.component.html',
  styleUrl: './menu-announcekit.component.scss'
})
export class MenuAnnouncekitComponent {
  @ViewChild('announcekitComponent') announceKit: AnnouncekitComponent;

  cookiebotConsent: Signal<CookiebotConsent | undefined>;

  showAnnounceKit = computed(() => {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    return !!this.cookiebotConsent()?.marketing
  });


  constructor(
    private cookiebotService: CookiebotService,
    @Inject(PLATFORM_ID) private platformId: object,
    private modalService: NgbModal,
  ) {
    this.cookiebotConsent = toSignal(this.cookiebotService.cookiebotConsent.asObservable());
  }

  public announceKitClick(event: MouseEvent) {
    if (this.announceKit) {
      event.stopPropagation();
      event.preventDefault();
      this.announceKit.open();
    } else {
      this.modalService.open(CookieConsentModalComponent);
    }
  }

}
