import {Injectable} from '@angular/core';
import recommend from '@algolia/recommend';
import {environment} from '@env/environment';
import {TrendingQuery} from '@algolia/recommend/dist/recommend';
import {AlgoliaService} from './algolia.service';
import {AlgoliaWineProduct} from '@core/types/algolia-wine-product';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaRecommendService {

  constructor(
    private algoliaService: AlgoliaService
  ) {
  }

  async getTrendingProducts(maxRecommendations: number, filters: string = '', facetName: string = '', facetValue: string = ''): Promise<AlgoliaWineProduct[]> {
    const recommendClient = recommend(environment.algolia.appId, environment.algolia.publicKey);
    const indexName = environment.algolia.defaultIndex;

    let filtersString = 'commerce_stock_bool:1 AND on_discount:1 AND is_salable_qty_more_than_default_qty:true AND attribute_set:Vin';

    if (filters) {
      filtersString += ` AND ${filters}`;
    }

    const trendingQuery: TrendingQuery = {
      indexName,
      model: 'trending-items',
      maxRecommendations: +maxRecommendations,
      queryParameters: {
        filters: filtersString
      }
    };

    if (facetName && facetValue) {
      (trendingQuery as any).facetName = facetName;
      (trendingQuery as any).facetValue = facetValue;
    }

    const trendingItems = await recommendClient.getTrendingItems([trendingQuery])
    const hits = (trendingItems.results[0] as any).hits;

    return hits.map((hit: any) => {
      return this.algoliaService.hitToWineProduct(hit);
    });
  }

}
