import {computed, Inject, Injectable, makeStateKey, PLATFORM_ID, Signal, TransferState} from '@angular/core';
import {environment} from '@env/environment';
import {SearchClient} from 'algoliasearch/lite';
import {
  ListPageTypeTypes,
  PriceTable,
  PriceTablePricing,
  Query,
  Rating,
  Scalars
} from '../interfaces/generated/graphql';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AlgoliaProduct} from '../interfaces/algolia-product';
import {ApolloService} from './apollo.service';
import {AlgoliaWineProduct} from '../types/algolia-wine-product';
import {CookieService} from './cookie.service';
import {DOCUMENT, isPlatformBrowser, Location} from '@angular/common';
import {AlgoliaFacetFilter} from '../types/algolia-filter';
import {createSSRSearchClient} from '../instantsearch/create-ssr-client';
import {InstantSearchOptions} from 'instantsearch.js/es/types';
import {ApolloQueryResult} from '@apollo/client/core';
import {history} from 'instantsearch.js/es/lib/routers';
import qs from 'qs';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {ActivatedRoute} from '@angular/router';

export const ALGOLIA_SEPERATOR = '{(-_-)}';

export type CustomProductListFilter = {
  types: ListPageTypeTypes[];
  objectIds: CustomOneProductFilter[];
  title: Scalars['String'];
  storyblok: any;
}

export type CustomOneProductFilter = {
  objectId?: number;
  weight: number;
  vismaId?: string
}

export type InstantSearchGetter = () => InstantSearchComponent;

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService {

  public hitsCache: { [index: string]: any } = {};
  private facetsFromMagento: Signal<ApolloQueryResult<Query> | undefined> = this.apollo.watchQuerySignal<Query>({
    queryName: 'getAlgoliaFiltersAndSorts',
  });

  constructor(
    private transferState: TransferState,
    private httpClient: HttpClient,
    private apollo: ApolloService,
    private location: Location,
    @Inject(DOCUMENT) private document: any,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected route: ActivatedRoute
  ) {
  }

  algoliaSorts = computed(() => {
    return this.facetsFromMagento()?.data.getAlgoliaFiltersAndSorts?.sorts ?? [];
  });

  algoliaFacets = computed(() => {
    const facets = this.facetsFromMagento()?.data.getAlgoliaFiltersAndSorts?.filters as AlgoliaFacetFilter[];
    if (facets?.length === 0) {
      return [];
    }
    let localFilters = this.getLocalFilters();
    localFilters = localFilters.filter(filter => {
      return !facets?.find((data) => data.algoliaAttribute === filter.algoliaAttribute);
    });
    return [...localFilters, ...facets ?? []];
  });

  createClient(options?: any) {
    return createSSRSearchClient(
      {
        appId: environment.algolia.appId,
        apiKey: environment.algolia.publicKey,
        httpClient: this.httpClient,
        makeStateKey,
        HttpHeaders,
        transferState: this.transferState,
        options
      });
  }

  getLabel(label: string) {
    if (label === 'true') {
      return 'Ja';
    } else if (label === 'false') {
      return 'Nej';
    }
    if (label.indexOf('icon-') !== -1) {
      return label.replace(this.getIconName(label) as string, '');
    }
    return label;
  }

  getIconName(icon: string) {
    return icon.split(' ').find(value => {
      return value.indexOf('icon-') !== -1;
    });
  }

  get defaultIndex(): string {
    return environment.algolia.defaultIndex;
  }

  get sortByIndex(): string | undefined {
    if (isPlatformBrowser(this.platformId)) {
      const sortIndex = localStorage.getItem('algoliaSortIndex');
      if (sortIndex) {
        return sortIndex;
      }
    }
    return undefined;
  }

  getVismaIdFilter(vismaIds: string[]): string {
    return vismaIds
      .map((value) => `visma_id: '${value}'`)
      .join(' OR ');
  }

  searchConfigWithUIState(filters: AlgoliaFacetFilter[] | undefined, filter: CustomProductListFilter | undefined, getInstantSearch: InstantSearchGetter, indexName: string | null = null): InstantSearchOptions {
    const config = this.searchConfig(undefined, true, indexName);
    config.routing = {
      router: this.getHistory(getInstantSearch)
    };
    if (filters || filter) {
      config.initialUiState = this.filterToUiState(filters || [], filter, config.indexName, this.sortByIndex);
    }

    return config;
  }



  private historyNavigate(url: string, title: string, routeState: any) {
    const urlParts = url.split('/');
    urlParts.splice(0, 3);
    url = '/' + urlParts.join('/');

    const currentState: object = this.location.getState() as object ?? {};
    const state = {...currentState, ...routeState};
    window.history.replaceState && window.history.replaceState(state, title || '', url);
  }

  protected getFilterByHash() {
    const parts = (this.route.snapshot.fragment?.split('&') ?? []).map(d => this.hashPartToFilter(d));
    return parts.reduce((p, c) => {
      p.push(...c);
      return p;
    }, []);
  }

  filterToUiState(filters: AlgoliaFacetFilter[], filterParam: CustomProductListFilter | undefined, indexName?: string, sortByIndexName?: string) {
    const state: any = {};
    if ((!filterParam && !sortByIndexName) || !indexName) {
      return undefined;
    }
    const filter = {...filterParam};
    const types = [...(filter.types ?? [])];
    const hashFilter = this.getFilterByHash();
    if (hashFilter.length > 0) {

      types.push(...hashFilter);
    }

    if (!types) {
      return undefined;
    }
    state.refinementList = {};
    state.toggle = {};
    if (sortByIndexName) {
      state.sortBy = sortByIndexName;
    }
    for (const type of types) {
      const facet = filters.find(value => value?.algoliaAttribute === type?.attribute);
      if (type?.attribute) {
        if (facet?.instantSearchType === 'toggle') {
          const value = type?.name;
          if (value) {
            state.toggle[type.attribute] = value;
          }
        } else {
          if (!state?.refinementList[type.attribute]) {
            state.refinementList[type.attribute] = [];
          }
          state?.refinementList[type.attribute].push(type?.name);
        }
      }
    }
    return {[indexName]: state};
  }

  protected hashPartToFilter(part: string) {
    const [attribute, value] = part?.split('=') ?? [];

    const whitelistedAttributes = ['default_price', 'field_grapes', 'facet_types', 'country', 'producer'];

    if (whitelistedAttributes.indexOf(attribute) === -1 || !value) {
      return [];
    }
    return value.split(',').map(d => {
      return {
        attribute,
        name: decodeURI(d)
      };
    });
  }

  // https://www.algolia.com/doc/api-reference/widgets/instantsearch/angular/#widget-param-routing;
  private getHistory(instantSearch: InstantSearchGetter) {
    const historyObject = history();
    const _this = this;
    historyObject.onUpdate = function (callback) {
      const _this2 = this as any;

      _this2._onPopState = function (event: any) {
        if (_this2.writeTimer) {
          clearTimeout(_this2.writeTimer);
          _this2.writeTimer = undefined;
        }

        const routeState = event.state; // At initial load, the state is read from the URL without update.
        // Therefore the state object is not available.
        // In this case, we fallback and read the URL.

        if (!routeState) {
          callback(_this2.read());
        } else {
          callback(routeState);
        }
      };

      // Mads: We dont use popstate and it give a error. SUPER-1248
      // window.addEventListener('popstate', this._onPopState);
    };
    historyObject.write = function (routeState, delay?: number) {
      const self = this as any;
      delete routeState?.['Products']?.page;
      if (!(instantSearch()?.instantSearchInstance as any)?.started) {
        return;
      }
      const url = this.createURL(routeState);
      const title = self.windowTitle && self.windowTitle(routeState);

      if (self.writeTimer) {
        clearTimeout(self.writeTimer);
      }

      self.writeTimer = setTimeout(() => {
        if (title) {
          document.title = title;
        }
        _this.historyNavigate(url, title, routeState);
        self.writeTimer = undefined;
      }, delay ?? self.writeDelay);
    };

    const doc = this.document;

    historyObject.read = function () {
      const self = this as any;
      const url = self.parseURL({
        qsModule: qs,
        location: doc.location
      });
      if (url.Products) {
        return url;
      }
      return {};
    };

    return historyObject;
  }


  searchConfig(host?: string, withUserToken = false, indexName: string | null = null): InstantSearchOptions {
    const config = {
      indexName: indexName ? indexName : this.defaultIndex,
      searchClient: this.searchClient(),
      routing: false,
    } as InstantSearchOptions;

    if (host) {
      config.searchClient = this.searchClient({
        hosts: [{url: host}]
      });
    }

    if (isPlatformBrowser(this.platformId) && withUserToken) {
      const useCookie = !!this.cookieService.get('_ALGOLIA');
      config.insights = {
        insightsInitParams: {
          useCookie,
        }
      }
    }
    return config;
  }

  searchClient(options?: any): SearchClient {
    return this.createClient(options) as any;
  }

  hitToWineProduct(hit: AlgoliaProduct): AlgoliaWineProduct {
    const product = {} as AlgoliaWineProduct;
    if (typeof (hit.node_path as any as number) === 'number') {
      hit.node_path = (hit.node_path as any as number).toString();
    }
    product.__position = hit.__position;
    product.__queryID = hit.__queryID;
    product.objectID = hit.objectID;
    product.nodePath = hit.node_path?.trim();
    product.id = hit.objectID;
    product.productId = hit.product_id as any;
    product.image = {
      small: `${environment.supervin.assets}${hit.product_image_large}`
    };
    product.name = hit.title[0].value;
    product.priceTable = this.parseHitPriceTableToProductPriceTable(hit);
    product.inStock = hit.commerce_stock_bool == 1 as any;
    product.shortText = hit.body_short;
    product.ctaLink = hit['field_cta_link:url'];
    product.ctaTitle = hit['field_cta_link:title'];
    product.canBeAddedToCart = !product.ctaLink;
    product.showSavings = hit.field_show_savings;
    product.isB2b = hit.field_is_b2b;
    product.sku = hit.product_sku;
    product.tags = hit.tags;
    product.hidePriceTable = hit.hide_price_table
    product.productImageBadge = hit.product_image_badge;

    product.topCta = this.parseTopCta(hit)

    if (hit['field_type'] === 'Billet') {
      product.isB2b = true;
    }

    product.hideDiscountCountdown = !hit.show_discount;
    product.vismaProductNumber = hit.visma_id;
    product.meta = {
      ratings: this.parseHitRatingsToProductRatings(hit),
      units: {
        shortName: hit.price_table.unit
      },
      energyLabel: hit.energy_label,
      energyLabelDoc: hit.energy_label_doc,
      productDataSheet: hit.product_data_sheet,
      grapes: hit.field_grapes,
      savingPotential: hit.saving_potential,
      alcohol: hit.alcohol?.toFixed(2).replace('.',','),
      vintage: hit.field_vintage,
    };

    if (hit.suggested_retail_price) {
      product.suggestedRetailPrice = hit.suggested_retail_price;
    }

    if ((hit.price_table?.def as any) > 0) {
      product.defaultQuantity = parseInt(hit.price_table?.def, 10);
    } else {
      product.defaultQuantity = 1;
    }

    return product;
  }

  protected parseHitPriceTableToProductPriceTable(hit: any): PriceTable {
    const priceTable: PriceTable = {
      id: hit.objectID,
      pricings: this.parseHitPricings(hit)
    };

    return priceTable;
  }

  protected parseHitPricings(hit: any): PriceTablePricing[] {
    const parsed = [];

    if (hit.field_cta_hide_price) {
      return [];
    }

    let pricings = hit.price_table?.pricings || [];

    if (pricings.length > 2) {
      pricings = [
        pricings[0],
        pricings[pricings.length - 1]
      ];
    }

    for (const pricing of pricings) {
      const price: PriceTablePricing = {
        currencyCode: 'DKK',
        amount: pricing.ex * 100,
        amountIncVat: pricing.inc * 100,
        minQty: pricing.min,
        maxQty: pricing.max
      };
      const isToValid = new Date(pricing.discount.to) > new Date();
      if (pricing.discount?.price && hit.commerce_stock_bool == 1 && isToValid) {
        price.discount = {
          amountIncVat: pricing.discount?.price * 100,
          to: new Date(pricing.discount?.to)
        };
      }
      parsed.push(price);
    }

    return parsed;
  }

  protected isProductDiscountActive(hit: any) {
    return !!this.parseHitPricings(hit).find(pricing => pricing.discount);
  }

  protected parseHitRatingsToProductRatings(hit: any): Rating[] {
    const hitRatings = hit.wine_ratings || [];
    const ratings = [] as Rating[];

    for (const rating of hitRatings) {
      let starCount = null;

      if (rating.max_rating > 0 && rating.rating <= 6) {
        starCount = rating.rating;
      }

      ratings.push({
        rating: rating.rating,
        label: rating.rating_label,
        name: rating.reviewer,
        starCount
      });
    }

    return ratings.slice(0, 4);
  }

  protected parseTopCta(hit: any): any {
    if (!this.isProductDiscountActive(hit) && hit.top_cta?.type === 'discount_countdown') {
      return {}
    }
    return {
      text: hit.top_cta?.text,
      icon: hit.top_cta?.icon,
      type: hit.top_cta?.type
    }
  }

  private getLocalFilters(): AlgoliaFacetFilter[] {
    return [
      {
        algoliaAttribute: 'facet_types',
        label: 'Type',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'country',
        label: 'Land',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'area',
        label: 'Område',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'district',
        label: 'Distrikt',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'producer',
        label: 'Producent',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'field_grapes',
        label: 'Drue',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'default_price',
        label: 'Pris',
        instantSearchType: 'range-input',
      },
      {
        algoliaAttribute: 'on_discount',
        label: 'Tilbud',
        instantSearchType: 'toggle',
      },
      {
        algoliaAttribute: 'field_klassikere',
        label: 'DONT_SHOW_THIS',
        instantSearchType: 'refinement-list',
        hidden: true
      },
      {
        algoliaAttribute: 'field_non_wine',
        label: 'DONT_SHOW_THIS',
        instantSearchType: 'refinement-list',
        hidden: true
      },
      {
        algoliaAttribute: 'categories',
        label: 'DONT_SHOW_THIS',
        instantSearchType: 'refinement-list',
        hidden: true
      },
      {
        algoliaAttribute: 'commerce_stock_bool',
        label: 'DONT_SHOW_THIS',
        instantSearchType: 'refinement-list',
        hidden: true
      },
      {
        algoliaAttribute: 'wine_ratings.reviewer',
        label: 'DONT_SHOW_THIS',
        instantSearchType: 'refinement-list',
        hidden: true
      },
      {
        algoliaAttribute: 'points',
        label: 'Anmeldelser',
        instantSearchType: 'ratings-filter',
        reviewers: [
          'Best Italian Wines Annuario',
          'Decanter',
          'Falstaff',
          'Flaskehalsen',
          'Guia Peñin',
          'Houlberg',
          'James Suckling',
          'Jeb Dunnuck',
          'Mad og Monopolet',
          'Robert Parker',
          'Verema',
          'Vinous',
          'Wine Enthusiast',
          'Wine Spectator',
          'Winewherever, Rasmus Christensen'
        ],
      },
      {
        algoliaAttribute: 'field_vintage',
        label: 'Årgang',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'field_food',
        label: 'Passer til',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'culture',
        label: 'Dyrkning',
        instantSearchType: 'refinement-list',
      },
      {
        algoliaAttribute: 'field_non_alcoholic',
        label: 'Alkoholfri',
        instantSearchType: 'refinement-list',
      },
    ];
  }
}
