@if (tags()?.length) {
  @for (tag of tags(); track trackByTag) {
    @if (tag?.icon) {
      <img [alt]="tag?.altText" [ngSrc]="tag?.icon ?? ''" [ngbTooltip]="tag?.altText" height="20" width="20">
    }
    @if (tag?.text) {
      <p [class]="'tag-green'" class="tag mb-0">Økologisk</p>
    }
  }
}
