import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from './cookie.service';
import {isPlatformServer} from '@angular/common';
import {environment} from '@env/environment';
import {SessionService} from './session.service';
import {VfSecretKeyService} from './vf-secret-key.service';
import {NavigationTrackingService} from '@core/services/navigation-tracking.service';
import {RuntimeIdService} from '@core/services/runtime-id.service';

@Injectable({
  providedIn: 'root'
})
export class ApolloHeadersService {

  constructor(
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object,
    private sessionService: SessionService,
    private vfSecretKeyService: VfSecretKeyService,
    private navigationTrackingService: NavigationTrackingService,
    private runtimeIdService: RuntimeIdService
  ) {
  }

  getHeaders() {
    if (isPlatformServer(this.platformId)) {
      return {}
    }

    const cookieConsent = this.cookieService.get('CookieConsent');

    const session = this.sessionService.getCurrentSession();

    const vfSecretKey = this.vfSecretKeyService.getVfSecretKey();

    const headers = {
      'SV-Session-Id': session?.sessionId ?? this.cookieService.get('SVSessionID'),
      'Cookie-Consent': cookieConsent ? decodeURIComponent(cookieConsent) : null,
      'Superpay-Id': session?.superpayId ?? this.cookieService.get('superpayUID'),
      'CARTID': session?.cartId ?? this.cookieService.get('cartId'),
      'GA-Session-Id': this.cookieService.get('_ga_G9XYLVTMBV'),
      'Algolia-Id': session?.algoliaId ?? this.cookieService.get('_ALGOLIA'),
      'Document-Referer': this.getReferer(),
      'X-Referer': this.getUrl(),
      'tracing': this.getTracing(),
      'X-GTM-Server-Preview': localStorage?.getItem('GTM-Server-Preview'),
      'X-GA-Client-Id': this.cookieService.get('_ga'),
      'X-Secure-Key': vfSecretKey,
      'X-Navigation-Id': this.navigationTrackingService.getNavigationId(),
      'X-Runtime-Id': this.runtimeIdService.getRuntimeId(),
      'X-FBC': this.cookieService.get('_fbc'),
      'X-FBP': this.cookieService.get('_fbp'),
    } as Record<string, string | boolean>;

    const userToken = this.cookieService.get('userToken');
    if (userToken) {
      headers['X-User-Token'] = 'Bearer ' + userToken;
    }
    const adminAuthToken = this.cookieService.get('admin_auth_token');
    if (adminAuthToken) {
      headers['Authorization'] = 'Bearer ' + adminAuthToken;
    }

    Object.keys(headers).forEach(key => {
      if (!headers[key]) {
        delete headers[key];
      }
    });
    return headers;
  }

  getReferer() {
    return document.referrer;
  }

  getTracing() {
    return !environment.production || !!localStorage?.getItem('tracing')
  }

  getUrl() {
    return window.location.href;
  }
}
