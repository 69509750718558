import {
  Component,
  computed,
  ContentChild,
  forwardRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  TemplateRef
} from '@angular/core';
import {RefinementListItem} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {
  InstantSearchBaseRefinementListComponent
} from '@core/instantsearch/components/instantsearch-base-refinement-list.component';


@Component({
  selector: 'app-instantsearch-refinement-list',
  templateUrl: './instantsearch-refinement-list.component.html',
  styleUrls: ['./instantsearch-refinement-list.component.scss'],
  standalone: true,
  imports: [NgIf, NgTemplateOutlet, NgForOf]
})
export class InstantsearchRefinementListComponent extends InstantSearchBaseRefinementListComponent implements OnInit {


  @Input() showResults = false;

  @ContentChild(TemplateRef) public template?: any;

  @HostBinding('style.display') get display() {
    return (this.showResults && this.state().items.length > 0) ? 'block' : 'none';
  }

  items = computed(() => this.state().items);

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
      instantSearchInstance: InstantSearchComponent
  ) {
    super(instantSearchInstance);
  }

  preventDefault(event: any, item: any) {
    event.stopPropagation();
    event.preventDefault();
    this.refine(event, item);
  }

  trackBy(index: any, item: RefinementListItem) {
    return `${item.label}_${item.value}`;
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  toggleShowMore(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.state().toggleShowMore();
  }

}
