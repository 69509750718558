import {Pipe, PipeTransform} from '@angular/core';
import {Rating} from '../interfaces/generated/graphql';

@Pipe({
  name: 'ratingToStars',
  standalone: true
})
export class RatingToStarsPipe implements PipeTransform {

  transform(rating: Rating | undefined | null): ('filled' | 'empty')[] {
    if (!rating) {
      return [];
    }
    const stars: any[] = [];

    for (let i = 0; i < <number>rating.starCount; i++) {
      stars.push(i < <number>rating.rating ? 'filled' : 'empty');
    }

    return stars;
  }

}
