import {Component, forwardRef, Inject, Input, OnInit, signal, WritableSignal} from '@angular/core';
import {noop} from 'rxjs';
import {connectToggleRefinement} from 'instantsearch.js/es/connectors';
import {ToggleRefinementRenderState} from 'instantsearch.js/es/connectors/toggle-refinement/connectToggleRefinement';
import {NgIf} from '@angular/common';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {TrackEventService} from '@core/services/track-event.service';


@Component({
  selector: 'app-instantsearch-toggle',
  templateUrl: './instantsearch-toggle.component.html',
  styleUrls: ['./instantsearch-toggle.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class InstantsearchToggleComponent extends TypedBaseWidgetComponent<any, any> implements OnInit {

  @Input() public attribute: string;
  @Input() public label: string;
  @Input() public on?: boolean | number | string;
  @Input() public off?: boolean | number | string;

  public override state: WritableSignal<ToggleRefinementRenderState> = signal({
    createURL: noop,
    refine: noop,
    value: {},
  } as any);

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    private trackEventService: TrackEventService,
  ) {
    super('ToggleRefinement');
  }

  override ngOnInit(): void {
    this.createWidget(connectToggleRefinement as any, {
      attribute: this.attribute,
      on: this.on,
      off: this.off,
    });
    super.ngOnInit();
  }

  handleChange(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.trackEventService.trackClickEvent('toggle_refinement', {
      attribute: this.attribute,
      isRefined: !this.state().value.isRefined
    });
    this.state().refine(this.state().value);
  }
}
