import {Component} from '@angular/core';
import {NgForOf} from '@angular/common';
import {AlgoliaService} from '@core/services/algolia.service';
import {SearchParameters} from 'algoliasearch-helper';
import {WineFestivalProductComponent} from './components/wine-festival-product/wine-festival-product.component';
import {WineFestivalSearchBoxComponent} from './components/wine-festival-search-box/wine-festival-search-box.component';
import {
  WineFestivalInfiniteHitsComponent
} from './components/wine-festival-infinite-hits/wine-festival-infinite-hits.component';
import {Router} from '@angular/router';
import {environment} from '@env/environment';
import {InstantSearchOptions} from 'instantsearch.js/es/types';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {InstantSearchConfigureComponent} from '@core/instantsearch/components/instant-search-configure.component';

@Component({
  selector: 'app-storyblok-wine-festival-ordering',
  templateUrl: './storyblok-wine-festival-ordering.component.html',
  styleUrls: ['./storyblok-wine-festival-ordering.component.scss'],
  standalone: true,
  imports: [
    WineFestivalProductComponent,
    NgForOf,
    WineFestivalSearchBoxComponent,
    WineFestivalInfiniteHitsComponent,
    InstantSearchComponent,
    InstantSearchConfigureComponent
  ]
})
export class StoryblokWineFestivalOrderingComponent {

  config: InstantSearchOptions;
  configuration: SearchParameters = {
    hitsPerPage: 20,
    filters: 'has_vf_value: true',
  } as any;

  constructor(
    private algoliaService: AlgoliaService,
    private router: Router,
  ) {
  }

  getSearchConfig(): InstantSearchOptions {
    if (this.config) {
      return this.config;
    }
    this.config = this.algoliaService.searchConfig();

    this.config.indexName = environment.algolia.wineFestivalIndexName;

    return this.config;
  }

  goToCart() {
    this.router.navigateByUrl('/checkout/cart');
  }

}
