import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {

  protected applicationDestroyed = new Subject<void>();

  public whileApplicationAlive() {
    return this.applicationDestroyed.asObservable();
  }

  public triggerApplicationDestroyed() {
    this.applicationDestroyed.next();
    this.applicationDestroyed.complete();
  }


}
