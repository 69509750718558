import type {SendEventForFacet} from 'instantsearch.js/es/lib/utils';
import {
  checkRendering,
  createDocumentationMessageGenerator,
  isFiniteNumber,
  noop,
} from 'instantsearch.js/es/lib/utils';
import type {InsightsEvent} from 'instantsearch.js/es/middlewares';
import type {Connector, InstantSearch, WidgetRenderState} from 'instantsearch.js/es/types';
import type {AlgoliaSearchHelper} from 'algoliasearch-helper';
import {SearchParameters} from 'algoliasearch-helper';
import {getAlgoliaReviewersFromFilter, updateFilterWithReviewers} from '../utils/AlgoliaFilters';

const withUsage = createDocumentationMessageGenerator(
  {name: 'range-input', connector: true},
  {name: 'range-slider', connector: true}
);

const $$type = 'supervin.rating-filter';
const pointsAttribute = 'points';
const reviewerAttribute = 'reviewers';

// @MAJOR: potentially we should consolidate these types
export type ReviewerFilter = {
  range: Range;
  reviewers: string[];
};
export type Range = {
  min: number;
  max: number;
};

export type RangeRenderState = {
  /**
   * Sets a range to filter the results on. Both values
   * are optional, and will default to the higher and lower bounds. You can use `undefined` to remove a
   * previously set bound or to set an infinite bound.
   * @param rangeValue tuple of [min, max] bounds
   */
  refine: (rangeValue: ReviewerFilter) => void;

  /**
   * Indicates whether this widget can be refined
   */
  canRefine: boolean;

  /**
   * Send an event to the insights middleware
   */
  sendEvent: SendEventForFacet;

  /**
   * Current refinement of the search
   */
  start: ReviewerFilter;
};

export type RangeConnectorParams = {
  /**
   * Minimal range value, default to automatically computed from the result set.
   */
  min: number;

  /**
   * Maximal range value, default to automatically computed from the result set.
   */
  max: number;

  /**
   * Number of digits after decimal point to use.
   */
  precision?: number;
};

export type RangeWidgetDescription = {
  $$type: 'supervin.rating-filter';
  renderState: RangeRenderState;
  indexRenderState: {
    ratingFilter: {
      [attribute: string]: WidgetRenderState<
        RangeRenderState,
        RangeConnectorParams
      >;
    };
  };
  indexUiState: {
    ratingFilter: {
      // @TODO: this could possibly become `${number}:${number}` later
      [attribute: string]: string;
    };
  };
};

export type RangeConnector = Connector<
  RangeWidgetDescription,
  RangeConnectorParams
>;

/**
 * **Range** connector provides the logic to create custom widget that will let
 * the user refine results using a numeric range.
 *
 * This connectors provides a `refine()` function that accepts bounds. It will also provide
 * information about the min and max bounds for the current result set.
 */
const connectRange: RangeConnector = function connectRange(
  renderFn,
  unmountFn = noop
) {
  checkRendering(renderFn, withUsage());

  return (widgetParams) => {
    const {
      min: minBound,
      max: maxBound,
      precision = 0,
    } = widgetParams || {};

    const handlePointsFilter = (resolvedState: SearchParameters, range: Range) => {
      resolvedState = resolvedState.removeNumericRefinement(pointsAttribute);
      if (range.min === minBound && range.max === maxBound) {
        return resolvedState;
      }

      resolvedState = resolvedState.addNumericRefinement(
        pointsAttribute,
        '>=',
        range.min
      );
      resolvedState = resolvedState.addNumericRefinement(
        pointsAttribute,
        '<=',
        range.max
      );

      return resolvedState;
    }

    const handleReviewerFilter = (resolvedState: SearchParameters, reviewers: string[], range: Range) => {
      resolvedState.filters = updateFilterWithReviewers(resolvedState.filters || '', reviewers, range);
      return resolvedState;
    }

    // eslint-disable-next-line complexity
    const getRefinedState = (
      helper: AlgoliaSearchHelper,
      newReviewerFilter: ReviewerFilter
    ) => {
      let resolvedState = helper.state;
      const currentMin = resolvedState?.getNumericRefinement(pointsAttribute, '>=') ? resolvedState?.getNumericRefinement(pointsAttribute, '>=')[0] : minBound;
      const currentMax = resolvedState?.getNumericRefinement(pointsAttribute, '<=') ? resolvedState?.getNumericRefinement(pointsAttribute, '<=')[0] : maxBound;
      const currentReviewers = getAlgoliaReviewersFromFilter(resolvedState?.filters || '');
      const {range: newRange, reviewers: newReviewers} = newReviewerFilter;

      const hasMinChange = currentMin !== newRange.min;
      const hasMaxChange = currentMax !== newRange.max;
      const hasReviewerChange = currentReviewers.length !== newReviewers.length;

      if (hasMinChange || hasMaxChange || hasReviewerChange) {

        resolvedState = handlePointsFilter(resolvedState, newRange);

        resolvedState = handleReviewerFilter(resolvedState, newReviewers, newRange);

        return resolvedState.resetPage();
      }

      return null;
    };

    const createSendEvent =
      (instantSearchInstance: InstantSearch) =>
        (...args: [InsightsEvent] | [string, string, string?]) => {
          if (args.length === 1) {
            instantSearchInstance.sendEventToInsights(args[0]);
            return;
          }
        };

    function _getCurrentRefinement(
      helper: AlgoliaSearchHelper
    ): ReviewerFilter {
      const [minValue] = helper.getNumericRefinement(pointsAttribute, '>=') || [];

      const [maxValue] = helper.getNumericRefinement(pointsAttribute, '<=') || [];

      const reviewers = getAlgoliaReviewersFromFilter(helper.state.filters || '');

      const min = isFiniteNumber(minValue) ? minValue : minBound;
      const max = isFiniteNumber(maxValue) ? maxValue : maxBound;

      return {
        range: {min, max},
        reviewers,
      };
    }

    function _refine(helper: AlgoliaSearchHelper) {
      return (newReviewerFilter: ReviewerFilter) => {
        const refinedState = getRefinedState(
          helper,
          newReviewerFilter
        );
        if (refinedState) {
          helper.setState(refinedState).search();
        }
      };
    }

    return {
      $$type,

      init(initOptions) {
        renderFn(
          {
            ...this.getWidgetRenderState(initOptions),
            instantSearchInstance: initOptions.instantSearchInstance,
          },
          true
        );
      },

      render(renderOptions) {
        renderFn(
          {
            ...this.getWidgetRenderState(renderOptions),
            instantSearchInstance: renderOptions.instantSearchInstance,
          },
          false
        );
      },

      getRenderState(renderState, renderOptions) {
        return {
          ...renderState,
          ratingFilter: {
            ...renderState.ratingFilter,
            [pointsAttribute]: this.getWidgetRenderState(renderOptions),
          },
        };
      },

      getWidgetRenderState(renderOptions) {
        const {instantSearchInstance, helper, results} =
          renderOptions;

        const start = _getCurrentRefinement(helper);

        const defaultReviewerFilter = {range: {min: minBound, max: maxBound}, reviewers: []};

        let refine: ReturnType<typeof _refine>;
        refine = _refine(helper);

        return {
          refine,
          canRefine: results?.getFacetStats(pointsAttribute)?.sum > 0,
          reviewerFilter: defaultReviewerFilter,
          sendEvent: createSendEvent(instantSearchInstance),
          widgetParams: {
            ...widgetParams,
            precision,
          },
          start,
        };
      },

      dispose({state}) {
        unmountFn();

        return state
          .removeDisjunctiveFacet(pointsAttribute)
          .removeNumericRefinement(pointsAttribute);
      },

      getWidgetUiState(uiState, {searchParameters}) {
        const {'>=': min = [], '<=': max = []} = searchParameters.getNumericRefinements(pointsAttribute);
        const reviewers: string[] = getAlgoliaReviewersFromFilter(searchParameters.filters || '');

        if (min.length === 0 && max.length === 0 && reviewers.length === 0) {
          return uiState;
        }

        return {
          ...uiState,
          ratingFilter: {
            ...uiState.ratingFilter,
            [pointsAttribute]: `${min}:${max}`,
            [reviewerAttribute]: reviewers.join('{-_-}'),
          },
        };
      },

      getWidgetSearchParameters(searchParameters, {uiState}) {
        let widgetSearchParameters = searchParameters
          .addDisjunctiveFacet(pointsAttribute)
          .setQueryParameters({
            filters: searchParameters.filters,
            numericRefinements: {
              ...searchParameters.numericRefinements,
            },
          });

        const value = uiState.ratingFilter && uiState.ratingFilter[pointsAttribute];

        if (!value || value.indexOf(':') === -1) {
          return widgetSearchParameters;
        }

        const [lowerBound, upperBound] = value.split(':').map(parseFloat);
        const reviewers = uiState.ratingFilter && uiState.ratingFilter[reviewerAttribute] ? uiState.ratingFilter[reviewerAttribute].split('{-_-}') : [];

        const min = isFiniteNumber(lowerBound) ? lowerBound : minBound;
        const max = isFiniteNumber(upperBound) ? upperBound : maxBound;

        widgetSearchParameters = handlePointsFilter(widgetSearchParameters, {min, max});

        widgetSearchParameters = handleReviewerFilter(widgetSearchParameters, reviewers, {min, max});
        return widgetSearchParameters;
      },
    };
  };
};

export default connectRange;
