  <div class="author">
    <div class="image">
      <img [height]="getAuthorImage()?.imgHeight" [ngSrc]="getAuthorImage()?.optimizedUrl ?? ''"
           [width]="getAuthorImage()?.imgWidth"
           alt="Author">
    </div>
    <div>
      <p class="title">Vinen er beskrevet af</p>
      <p class="author-name"><strong>{{ productAuthor()?.name }}</strong> {{ productAuthor()?.tagline }}</p>
    </div>
  </div>

