import {Injectable} from '@angular/core';
import {ApolloService} from './apollo.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {PaymentMethod, Query} from '../interfaces/generated/graphql';
import {UserInitService} from './user-init.service';

export type PaymentMethodType = {
  loading: boolean,
  paymentMethods: PaymentMethod[]
}

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  paymentMethod$: BehaviorSubject<PaymentMethodType> = new BehaviorSubject<PaymentMethodType>({
    loading: true,
    paymentMethods: []
  });

  constructor(
    private apollo: ApolloService,
    protected userInitService: UserInitService
  ) {
  }

  getPaymentMethods(): Observable<PaymentMethodType> {
    return this.paymentMethod$.asObservable();
  }

  setPaymentMethodsToLoading(loading = true) {
    if (this.paymentMethod$.observers.length === 0) {
      return;
    }
    this.paymentMethod$.next({
      loading: loading,
      paymentMethods: this.paymentMethod$.value.paymentMethods
    })
  }

  async updatePaymentMethods() {
    if (!this.paymentMethod$.value.loading) {
      this.setPaymentMethodsToLoading();
    }
    await this.userInitService.onInitializedPromise();

    return this.apollo.queryPromise<Query>({
      queryName: 'getPaymentMethodsQuery',
    }).then(paymentMethods => {
        this.paymentMethod$.next({
          loading: false,
          paymentMethods: (paymentMethods.data?.getPaymentMethods ?? []) as PaymentMethod[]
        })
      }
    ).catch(err => {
      this.setPaymentMethodsToLoading(false);
      throw err;
    });
  }


}
