@if (product()?.productImageBadge?.type === 'last_bottles') {
  <span class="last-bottles-title">1</span>
  <span class="last-bottles-subtitle">
    stk. pris
  </span>
} @else if (product()?.productImageBadge?.type === 'show_discount') {
  <span class="price">
    -{{ product()?.productImageBadge?.value }}%
  </span>
}
