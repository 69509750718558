import {Component} from '@angular/core';

@Component({
  selector: 'app-comments-icon',
  styles: `
    :host {
      height: 24px;
      width: 24px;
      display: inline-block;
      color: #fd8e00;
      line-height: 0;
    }
  `,
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.913 2.658C6.988 2.388 9.103 2.25 11.25 2.25C13.397 2.25 15.512 2.389 17.587 2.658C19.509 2.908 20.878 4.519 20.992 6.385C20.6577 6.27362 20.3112 6.20278 19.96 6.174C17.1582 5.94141 14.3418 5.94141 11.54 6.174C9.182 6.37 7.5 8.364 7.5 10.608V14.894C7.49906 15.7178 7.72577 16.5258 8.15512 17.2288C8.58446 17.9319 9.19973 18.5026 9.933 18.878L7.28 21.53C7.17511 21.6348 7.04153 21.7061 6.89614 21.735C6.75074 21.7638 6.60004 21.749 6.46308 21.6923C6.32611 21.6356 6.20903 21.5395 6.12661 21.4163C6.04419 21.2931 6.00013 21.1482 6 21V16.97C5.6372 16.9314 5.27484 16.8888 4.913 16.842C2.905 16.58 1.5 14.833 1.5 12.862V6.638C1.5 4.668 2.905 2.919 4.913 2.658Z"
        fill="#FD8E00"/>
      <path
        d="M15.75 7.5C14.374 7.5 13.011 7.557 11.664 7.669C10.124 7.797 9 9.103 9 10.609V14.894C9 16.401 10.128 17.708 11.67 17.834C12.913 17.936 14.17 17.991 15.438 17.999L18.22 20.78C18.3249 20.8848 18.4585 20.9561 18.6039 20.985C18.7493 21.0139 18.9 20.999 19.0369 20.9423C19.1739 20.8856 19.291 20.7896 19.3734 20.6663C19.4558 20.5431 19.4999 20.3982 19.5 20.25V17.86L19.83 17.834C21.372 17.709 22.5 16.401 22.5 14.894V10.608C22.5 9.103 21.375 7.797 19.836 7.668C18.4769 7.55562 17.1137 7.49957 15.75 7.5Z"
        fill="#FD8E00"/>
    </svg>
  `,
  standalone: true
})

export class CommentsIconComponent {

}
