import {Component, Inject, Input, OnInit, PLATFORM_ID, Signal} from '@angular/core';
import {UserService} from '@core/services/user.service';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {AsyncPipe, isPlatformServer, NgIf} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ProductBadgesComponent} from '../product-badges/product-badges.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SafeHtmlPipe} from '@core/pipes/safe-html.pipe';
import {ReplaceImgPipe} from '@core/pipes/replace-img.pipe';
import {customImageLoader} from '../../../../../../../custom-image-loader';
import {environment} from '@env/environment';
import {ProductTagsComponent} from '@layout/components/product/product-tags/product-tags.component';
import {captureException} from '@sentry/angular-ivy';
import {ReadMoreComponent} from '@layout/components/general/read-more/read-more.component';
import {GridService} from '@core/services/grid.service';
import {
  ProductAuthorComponent
} from '@modules/page/components/product/components/product-author/product-author.component';
import {SplitTestService} from '@core/services/split-test.service';

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.scss'],
  standalone: true,
  imports: [NgIf, RouterModule, ProductBadgesComponent, FontAwesomeModule, SafeHtmlPipe, ReplaceImgPipe, AsyncPipe, ProductTagsComponent, ReadMoreComponent, ProductAuthorComponent]
})
export class ProductDescriptionComponent implements OnInit {
  private _product: WineProduct;
  private _description: string;

  isAdmin: Signal<boolean>;

  @Input()
  set product(value: WineProduct | undefined | null) {
    if (!value) {
      return;
    }
    this._description = this.domParser(value.longText);
    this._product = value;
  }

  get product() {
    return this._product;
  }

  get description() {
    return this._description;
  }

  constructor(
    public userService: UserService,
    private gridService: GridService,
    public splitTestService: SplitTestService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  ngOnInit(): void {
    this.isAdmin = this.userService.isAdmin();
  }

  get maxBodyHeight() {
    if (isPlatformServer(this.platformId)) {
      return 600;
    }
    return this.gridService.isLarge ? 600 : 400;
  }

  private domParser(description: any): string {
    if (typeof document === 'undefined') {
      return description;
    }
    let div = document.createElement('div');
    div.innerHTML = description;

    div = this.vimeoParser(div);
    div = this.imageParser(div);

    return new XMLSerializer().serializeToString(div);
  }

  private vimeoParser(div: HTMLDivElement): HTMLDivElement {

    Array.from(div.querySelectorAll('div[data-video-src]')).forEach((value, index) => {

      const child = document.createElement('div');
      child.setAttribute('class', 'vimeo');

      const iframe = document.createElement('iframe');
      iframe.setAttribute('src', `https://player.vimeo.com/video/${value.getAttribute('data-video-src')}`);
      iframe.setAttribute('allow', 'autoplay; fullscreen; picture-in-picture');
      iframe.setAttribute('frameborder', '0');

      child.appendChild(iframe);
      value.replaceWith(child);
    });

    return div;
  }

  onImageError(src: string) {
    captureException('IMAGE_NOT_FOUND', {
      extra: {
        src
      }
    });
  }

  private imageParser(div: HTMLDivElement): HTMLDivElement {

    Array.from(div.getElementsByTagName('img')).forEach((value, index) => {

      let path: string|null = value.getAttribute('src');
      if (!path) {
        return;
      }

      if (path.indexOf('/pub/media/') === 0) {
        path = environment.supervin.assets + path;
      }

      const url = customImageLoader({
        width: 500,
        src: path
      })
      value.setAttribute('src', url);
      value.setAttribute('loading', 'lazy');
      value.onerror = ($event) => this.onImageError(url);
    });

    return div;
  }
}

