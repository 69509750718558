import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'exists',
  standalone: true
})
export class ExistsPipe implements PipeTransform {

  transform(value: string | number | null | undefined, ...args: unknown[]): boolean {
    if (typeof value === 'number') {
      return value > 0;
    }
    if (typeof value === 'string') {
      return parseInt(value, 10) > 0;
    }
    return false;
  }
}
