import {Injectable} from '@angular/core';
import {RoutingService} from './routing.service';
import {CookieService} from './cookie.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class VfSecretKeyService {

  constructor(
    private routingService: RoutingService,
    private cookieService: CookieService,
    private modalService: NgbModal,
  ) {
  }

  initVfSecretKey() {
    const vfSecretKey = this.routingService.getQueryParams()['vf_secret_key'];
    const sessionId = this.cookieService.get('SVSessionID');
    if (vfSecretKey && sessionId) {
      import('../../content/layout/components/modals/simple-modal/simple-modal.component').then(comp => {
        const modalRef = this.modalService.open(comp.SimpleModalComponent);
        modalRef.componentInstance.title = 'VF Secret Key';
        modalRef.componentInstance.text = 'Det ser ud til at du prøver at sætte vf_secret_key, men har allerede en session. Tjek at du har åbnet en inkognito fane og at du ikke har 2 inkognito faner åbne. Hvis du har, så luk dem begge og prøv igen';
      })
      return;
    }

    if (vfSecretKey) {
      const expiresDate = this.cookieService.getExpiresDate(1);
      this.cookieService.set('vf_secret_key', vfSecretKey, expiresDate, '/');
    }
  }

  isVfSecretKeySet() {
    return !!this.getVfSecretKey();
  }

  getVfSecretKey() {
    return this.cookieService.get('vf_secret_key');
  }

}
