<div class="container">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="navbar-collapse collapse" [ngClass]="[isCollapsed() ? 'hide' : 'show']">
      <ul class="navbar-nav mx-auto" *ngIf="menuStoryBlok()">
        <ng-container *ngFor="let menuStructure of menuStoryBlok().content.Menu; let i = index; trackBy: menuTracker">
          <li
            *ngIf="getMenuType(menuStructure) === 'AlgoliaMenu'"
            [hasSubMenu]="true"
            [name]="menuStructure.Name"
            app-header-menu-item
            class="nav-item nav-background">
            <app-instantsearch [config]="getConfig()">
              <app-instantsearch-hierarchical-menu
                [highlights]="menuStructure.highlights"
                [attributes]="[menuStructure.algolia_menu + '.lvl0', menuStructure.algolia_menu + '.lvl1', menuStructure.algolia_menu + '.lvl2']"
                [limit]="15"
              ></app-instantsearch-hierarchical-menu>
            </app-instantsearch>
          </li>
          <li
            *ngIf="getMenuType(menuStructure) === 'menu'"
            data-rr-shared="true"
            [class]="getMenuClassName(menuStructure)"
            [hasSubMenu]="false"
            [name]="menuStructure.Name"
            [showAnnounceKit]="menuStructure.show_announcekit"
            [url]="menuStructure.Link?.url"
            app-header-menu-item
            class="nav-item nav-background">
          </li>
          <li
            *ngIf="getMenuType(menuStructure) === 'subMenu'"
            data-rr-shared="true"
            [class.dropdown-left]="menuStructure.dropdown_direction === 'left'"
            [class]="getMenuClassName(menuStructure)"
            [hasSubMenu]="true"
            [name]="menuStructure.Name"
            app-header-menu-item>
            <app-header-menu-sub-menu [menuStructure]="menuStructure"
                                      [pathWithLabels]="menuStructure.Name"></app-header-menu-sub-menu>
          </li>
        </ng-container>
      </ul>
    </div>
  </nav>
</div>
