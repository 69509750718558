import {Inject, Injectable, Optional} from '@angular/core';
import {ListPage, PageInfo, Query, Storyblok} from '../interfaces/generated/graphql';
import {StoryblokPageService} from './storyblok-page.service';
import {RESPONSE} from '../../../express.tokens';
import {Response} from 'express';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApolloService} from './apollo.service';
import {Breadcrumb, Page} from '../interfaces/page';

@Injectable({
  providedIn: 'root'
})
export class PageInfoService {

  constructor(
    @Optional() @Inject(RESPONSE) private response: Response,
    private storyblokPageService: StoryblokPageService,
    private apollo: ApolloService,
  ) {
  }

  generateBreadcrumbs(pageInfo: PageInfo): { breadcrumbStyle: string, breadcrumbs: Breadcrumb[] } {
    let breadcrumbStyle = pageInfo.breadcrumbStyle;
    let breadcrumbs: Breadcrumb[];
    if (pageInfo?.type === 'STORYBLOK_PAGE' || pageInfo?.type === 'NOT_FOUND') {
      const storyblokData = this.getStoryblockData(pageInfo);
      breadcrumbs = this.storyblokPageService.getBreadcrumbs(pageInfo);
      if (storyblokData?.story?.content?.breadcrumb_style) {
        breadcrumbStyle = storyblokData?.story?.content?.breadcrumb_style;
      }
    } else {
      breadcrumbs = this.createDefaultPageBreadCrumbs(pageInfo);
    }
    return {
      breadcrumbStyle,
      breadcrumbs,
    }
  }

  getPageData(dataProp: PageInfo): Page {
    const data = {...dataProp};
    this.pageNotFoundHasRedirectCheck(data);

    const breadcrumbData = this.generateBreadcrumbs(data);
    const storyblokHead = this.storyblokPageService.getHead(data);
    const storyblokBreadcrumbs = this.storyblokPageService.getBreadcrumbs(data);

    if (storyblokHead) {
      data.head = storyblokHead;
    }
    if (storyblokBreadcrumbs) {
      data.breadcrumb = storyblokBreadcrumbs;
    }

    return {
      type: data.type || 'NOT_FOUND',
      path: data.path || '',
      title: data.head?.title || 'Supervin',
      head: {
        link: data.head?.link?.map(link => {
          return {href: link?.href || '', rel: link?.rel || ''}
        }) || [],
        meta: data.head?.meta?.map(meta => {
          return {property: meta?.property || '', content: meta?.content || '', name: meta?.name || ''};
        }) || [],
        structuredDataScheme: data.head?.structuredDataScheme
      },
      breadcrumbs: breadcrumbData.breadcrumbs,
      breadcrumbStyle: breadcrumbData.breadcrumbStyle,
    }
  }

  getStoryblockData(pageInfo: PageInfo) {
    const storyblok = (pageInfo?.pageData as Storyblok)?.storyblok;
    if (!storyblok) {
      return undefined;
    }
    return JSON.parse(storyblok);
  }

  createDefaultPageBreadCrumbs(pageInfo: PageInfo): Breadcrumb[] {
    const pageData = pageInfo?.pageData as ListPage;
    const breadcrumbs = pageInfo?.breadcrumb;
    if (!breadcrumbs || breadcrumbs.length === 0) {
      return [{path: '/', label: 'Forside'},
        {path: '/' + pageInfo?.path, label: pageData?.title}];
    }
    return breadcrumbs.map((d, i) => {
      return {
        label: d?.label || 'Supervin',
        path: '/' + d?.path
      };
    });
  }

  pageInfoGraphQL(path: string, secret?: string): Observable<PageInfo | undefined> {
    return this.apollo.watchQuery<Query>({
      queryName: 'getPageInfo',
      variables: {
        path,
        secret
      }
    }).pipe(
      map(value => {
        return value.data?.getPageInfo || undefined;
      })
    );
  }

  protected pageNotFoundHasRedirectCheck(pageInfo: PageInfo): boolean {
    if (pageInfo?.type !== 'NOT_FOUND') {
      return false;
    }
    if (!pageInfo.redirect) {
      this.response?.status(404);
      return false;
    }
    const {code, redirect} = pageInfo.redirect;
    if (code && (redirect || redirect === '')) {
      if (this.response) {
        this.response?.redirect(parseInt(String(code), 10), '/' + redirect);
        return true;
      } else {
        window.location.href = redirect;
        return true;
      }
    } else {
      this.response?.status(404);
    }
    return false;
  }

}
