import {Component} from '@angular/core';

@Component({
  selector: 'app-cart-icon',
  styles: `
    :host {
      height: 25px;
      width: 28px;
      display: inline-block;
      color: white;
      line-height: 0;
    }
  `,
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26" viewBox="0 0 30 26" fill="none">
      <path
        d="M13.3057 2.21379C13.6035 1.63761 13.3789 0.929601 12.8076 0.631749C12.2363 0.333896 11.5234 0.558506 11.2256 1.1298L6.67969 9.87494H2.5C1.63574 9.87494 0.9375 10.5732 0.9375 11.4375C0.9375 12.3017 1.63574 13 2.5 13L5.03418 23.1318C5.38086 24.5234 6.63086 25.5 8.06641 25.5H21.9336C23.3691 25.5 24.6191 24.5234 24.9658 23.1318L27.5 13C28.3643 13 29.0625 12.3017 29.0625 11.4375C29.0625 10.5732 28.3643 9.87494 27.5 9.87494H23.3203L18.7744 1.1298C18.4766 0.558506 17.7686 0.333896 17.1924 0.631749C16.6162 0.929601 16.3965 1.63761 16.6943 2.21379L20.6787 9.87494H9.32129L13.3057 2.21379ZM10.3125 15.3437V20.0312C10.3125 20.4609 9.96094 20.8125 9.53125 20.8125C9.10156 20.8125 8.75 20.4609 8.75 20.0312V15.3437C8.75 14.914 9.10156 14.5625 9.53125 14.5625C9.96094 14.5625 10.3125 14.914 10.3125 15.3437ZM15 14.5625C15.4297 14.5625 15.7812 14.914 15.7812 15.3437V20.0312C15.7812 20.4609 15.4297 20.8125 15 20.8125C14.5703 20.8125 14.2188 20.4609 14.2188 20.0312V15.3437C14.2188 14.914 14.5703 14.5625 15 14.5625ZM21.25 15.3437V20.0312C21.25 20.4609 20.8984 20.8125 20.4688 20.8125C20.0391 20.8125 19.6875 20.4609 19.6875 20.0312V15.3437C19.6875 14.914 20.0391 14.5625 20.4688 14.5625C20.8984 14.5625 21.25 14.914 21.25 15.3437Z"
        fill="white"/>
    </svg>
  `,
  standalone: true
})

export class CartIconComponent {

}
