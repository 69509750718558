<ng-container *ngIf="product && product?.id">
  <span class="btn btn-sm btn-outline-light stock-status presale" *ngIf="showStockText">{{product.ctaStockText}}</span>
  <span class="btn btn-sm btn-outline-light stock-status" *ngIf="showInStock">På lager - 1-2 dages levering</span>
  <span class="btn btn-sm btn-outline-light stock-status presale" *ngIf="showPreorder">
    <span class="p-0 m-0">{{ product.isEnPrimeur ? 'EN PRIMEUR' : 'Forsalg' }} - Afsendes fra d.</span>
    {{ product.preorderDate | date: 'd. MMMM y' }}
  </span>
  <span class="btn btn-sm btn-outline-light stock-status out" *ngIf="showOutOfStock">Udsolgt</span>
  <span class="btn btn-sm btn-outline-light guarantee">
    <span>Vingaranti</span>
    <div>
      <p>Supervin's unikke vingaranti</p>
      <p>
        Hvis du ikke er tilfreds med kvaliteten, tilbyder vi at tage vinen retur.
        Du refunderes det fulde beløb for vinen, også for den første åbnede flaske. Vi betaler selvfølgelig returfragten.
        (maks. 1 md. efter levering).
      </p>
    </div>
  </span>
</ng-container>
