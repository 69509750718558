import {Component, computed, input, Input, signal, ViewChild} from '@angular/core';
import {AlgoliaService, CustomProductListFilter} from '@core/services/algolia.service';
import {SearchPageResultsComponent} from './components/search-page-results/search-page-results.component';
import {NgIf} from '@angular/common';
import {SearchPageTitleComponent} from './components/search-page-title/search-page-title.component';
import {DynamicModule} from 'ng-dynamic-component';
import {TopFilterComponent} from './components/top-filter/top-filter.component';
import {TopFilterActiveComponent} from './components/top-filter-active/top-filter-active.component';
import {
  InstantsearchToggleComponent
} from '@layout/components/instantsearch/instantsearch-toggle/instantsearch-toggle.component';
import {LoadingIconComponent} from '@layout/components/general/loading-icon/loading-icon.component';
import {SadWineglassIconComponent} from '@layout/components/general/sad-wineglass-icon/sad-wineglass-icon.component';
import {AlgoliaSearchHelper} from 'algoliasearch-helper';
import {AlgoliaFacetFilter} from '@core/types/algolia-filter';
import {InstantSearchOptions} from 'instantsearch.js/es/types';
import {ChangeEvent, InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {InstantSearchConfigureComponent} from '@core/instantsearch/components/instant-search-configure.component';
import {InstantSearchSearchBoxComponent} from '@core/instantsearch/components/instant-search-search-box.component';
import {InstantSearchStatsComponent} from '@core/instantsearch/components/instant-search-stats.component';
import {RoutingService} from '@core/services/routing.service';
import {
  InstantsearchSortByComponent
} from '@layout/components/instantsearch/instantsearch-sort-by/instantsearch-sort-by.component';
import {ProductWidgetComponent} from '@layout/components/product/product-widget/product-widget.component';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  standalone: true,
  imports: [SearchPageTitleComponent, DynamicModule, TopFilterComponent, TopFilterActiveComponent, InstantsearchToggleComponent, SearchPageResultsComponent, LoadingIconComponent, SadWineglassIconComponent, InstantSearchComponent, InstantSearchConfigureComponent, InstantSearchSearchBoxComponent, InstantSearchStatsComponent, NgIf, InstantsearchSortByComponent, ProductWidgetComponent]
})
export class SearchPageComponent {

  configuration = {hitsPerPage: 48} as any;
  voucherList = input<boolean>(false);
  filtersSignal = this.algoliaService.algoliaFacets;
  sortsSignal = computed(() => this.algoliaService.algoliaSorts().map(d => ({label: d?.title, value: d?.indexName})));
  topFilterHeight = signal<string>('50px')
  hasLoadedHits = signal(false);
  hits = signal(0);
  currentAlgoliaIndex = signal(this.algoliaService.defaultIndex);

  ready = computed(() => (this.routingService.isFirstRoute() && !this.voucherList()) || this.hasLoadedHits());

  public config: InstantSearchOptions;
  private _instantSearch: InstantSearchComponent;
  private _filter: CustomProductListFilter | undefined;

  @ViewChild('searchResults') searchResults: SearchPageResultsComponent;

  @Input() title: string;
  @Input() sortBy: (a: any, b: any) => number;
  @Input() hideDiscountFilter: boolean = true;

  @Input()
  set hitsPerPage(value: number) {
    this.configuration.hitsPerPage = value;
  }

  public get filter() {
    return this._filter;
  }

  constructor(
    private algoliaService: AlgoliaService,
    protected routingService: RoutingService
  ) {
  }

  get instantSearch(): InstantSearchComponent {
    return this._instantSearch;
  }

  @ViewChild('instantSearch')
  set instantSearch(value: InstantSearchComponent) {
    this._instantSearch = value;
  }

  @Input()
  public set filter(filter) {
    this._filter = filter;
    this.configuration.filters = this.getFilters();
  }

  topFilterHeightChange(newHeight: string) {
    if (newHeight && newHeight !== this.topFilterHeight()) {
      this.topFilterHeight.set(newHeight);
    }
  }

  shownFilters(filters: AlgoliaFacetFilter[]) {
    return filters.filter(filter => !filter.hidden);
  }

  protected getFilters() {
    const objectIds = this.filter?.objectIds || [];
    const newConfig = {...this.configuration};

    if (objectIds.length > 0) {
      newConfig.filters = this.algoliaService.getVismaIdFilter(objectIds.map(d => d.vismaId as any as string))
    } else {
      newConfig.filters = '';
    }

    return newConfig.filters;
  }

  getSearchConfig(filters: AlgoliaFacetFilter[]|undefined): InstantSearchOptions {
    if (!filters) {
      throw new Error('filters must be defined')
    }
    if (this.config) {
      return this.config;
    }
    this.config = this.algoliaService.searchConfigWithUIState(filters, this.filter, () => this._instantSearch);

    return this.config;
  }

  get helper(): AlgoliaSearchHelper | null {
    return this.instantSearch?.instantSearchInstance?.helper;
  }

  hitsChange({results}: ChangeEvent) {
    if (results) {
      this.hits.set(results.nbHits);
      this.hasLoadedHits.set(true);
    }
  }
}
