import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  NgIterable,
  Output,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import {NguCarousel, NguCarouselConfig, NguCarouselDefDirective, NguTileComponent} from '@ngu/carousel';
import {Image, WineProduct} from '@core/interfaces/generated/graphql';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {CarouselComponent} from '@layout/components/product/carousel/carousel.component';
import {ProductImage} from '@modules/page/components/product/components/product-image/product-images.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import {
  Orbitvu360Component
} from '@modules/page/components/product/components/product-image/orbitvu-360/orbitvu-360.component';

@Component({
  selector: 'app-product-image-carousel',
  templateUrl: './product-image-carousel.component.html',
  styleUrls: ['./product-image-carousel.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgOptimizedImage, NguCarousel, NguTileComponent, NguCarouselDefDirective, CarouselComponent, FaIconComponent, Orbitvu360Component],
  'host': {ngSkipHydration: 'true'},
})
export class ProductCarouselComponent {

  images = input<ProductImage[] | null>(null);
  @Output() activeImageIdChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() openImageModal = new EventEmitter<string>();
  @ViewChild('carousel') carousel: CarouselComponent<WineProduct['images']>;
  public carouselTile: NguCarouselConfig = {
    grid: {
      xs: 1, sm: 1, md: 1, lg: 1, all: 0
    },
    slide: 1,
    speed: 350,
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    loop: true,
    touch: false,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };
  protected readonly faMagnifyingGlass = faMagnifyingGlass;

  @Input()
  set activeImageId(value: string | null) {
    const images = this.images();
    if (!images || !this.carousel) {
      return;
    }
    const activeImageIndex = images.findIndex(image => image?.id === value);
    if (activeImageIndex !== -1) {
      this.carousel.moveTo(activeImageIndex);
    }
  }

  get uniqueToken(): string {
    const images = this.images();
    if (!images) {
      return 'empty';
    }
    return images.map(d => d?.id).join('-');
  }

  onMove(event: NguCarousel<Image, NgIterable<Image>>) {
    const images = this.images();
    if (!images) {
      return;
    }
    const activeImage = images[event.currentSlide];
    this.activeImageIdChange.emit(activeImage?.id ?? '');
  }

  trackBy(): TrackByFunction<Image> {
    return (index, item) => {
      return item?.id;
    };
  }
}
