import {Injectable} from '@angular/core';
import {ToastService} from './core/services/toast.service';
import {ErrorService} from './core/services/error.service';
import {SentryErrorHandler} from '@sentry/angular-ivy';

@Injectable({providedIn: 'root'})
export class CustomErrorHandler extends SentryErrorHandler {

  constructor(
    private toastService: ToastService,
    protected errorService: ErrorService
  ) {
    super(undefined);
  }

  protected showErrorInToast(error: any) {
    return !!error.isApolloError;
  }

  override handleError(error: any) {

    if (error?.rejection) {
      error = error.rejection;
    }

    const message = this.errorService.getErrorMessage(error, undefined, false);
    const defaultMessage = this.errorService.getDefaultErrorMessage();

    let showError = this.showErrorInToast(error);
    let captureError = true;
    if (message !== defaultMessage) {
      showError = true;
      captureError = false;
    }

    if (showError) {
      this.toastService.showDanger(message);
    }

    if (captureError) {
      super.handleError(error);
    }
  }

}
