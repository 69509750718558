import {ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {StoryblokService} from '@core/services/storyblok.service';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {DynamicModule} from 'ng-dynamic-component';
import {NgComponentOutlet, NgForOf, NgIf} from '@angular/common';
import {StoryblokComponentService} from '@core/services/storyblok-component.service';
import {GridService} from '@core/services/grid.service';
import {ReadMoreComponent} from '@layout/components/general/read-more/read-more.component';
import {StoryblokReadMoreComponent} from '@modules/storyblok/components/storyblok-read-more.component';


@Component({
  selector: 'app-card',
  templateUrl: './storyblok-card.component.html',
  styleUrls: ['./storyblok-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, DynamicModule, NgForOf, NgIf, NgComponentOutlet, ReadMoreComponent]
})
export class StoryblokCardComponent extends StoryblokReadMoreComponent implements OnInit {

  @Input() body: any[];
  @Input() _editable: any;
  @Input() general_styling: any;
  @Input() id: any;
  @Input() background_color: any;

  @Input()
  set elementId(value: any) {
    this.hostElementId = value;
  }

  @HostBinding('attr.id') hostElementId: any;
  @HostBinding('class') class: any;
  @HostBinding('style.background-color') backgroundColor: any;

  components: any;

  constructor(
    private storyblokService: StoryblokService,
    private storyblokStylingService: StoryblokStylingService,
    protected storyblokComponentService: StoryblokComponentService,
    gridService: GridService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    super(gridService, platformId);
    this.components = storyblokComponentService.getComponents();
  }

  ngOnInit() {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, {
      desktop: {
        'margin-bottom': '.5rem',
        'margin-top': '.5rem'
      }
    }, {'col-': 12});
    if (this.background_color?.value) {
      this.backgroundColor = this.background_color.value;
    }
  }


  get trackByFn() {
    return this.storyblokService.trackByUID();
  }
}
