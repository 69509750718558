import {ChangeDetectionStrategy, Component, computed, effect, HostBinding, input} from '@angular/core';
import {DecimalPipe, NgForOf, NgIf} from '@angular/common';
import {Rating} from '@core/interfaces/generated/graphql';
import {ExistsPipe} from '@core/pipes/exists.pipe';
import {StarSolidIconComponent} from '@core/svg-icons/star-solid-icon.component';
import {StarEmptyIconComponent} from '@core/svg-icons/star-empty-icon.component';

type Star = {isSolid: boolean;};
@Component({
  selector: 'app-product-rating-points',
  templateUrl: './product-rating-points.component.html',
  styleUrls: ['./product-rating-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ExistsPipe, DecimalPipe, NgForOf, StarSolidIconComponent, StarEmptyIconComponent]
})

export class ProductRatingPointsComponent {

  rating = input.required<Rating>();
  showEmptyStars = input<boolean>(false);
  stars = computed(() => {
    let stars: Star[] = [];
    if (Number(this.rating().rating) < 0 || Number(this.rating().starCount) < 1){
      return stars;
    }
    let i = 0;
    let i2 = 0;

    while (i < Number(this.rating().rating)) {
      stars.push({isSolid: true});
      i++;
    }
    while (i2 < this.emptyStarsCount()) {
      stars.push({isSolid: false});
      i2++;
    }
    return stars;
  });

  emptyStarsCount = computed(() => {
    if (Number(this.rating().rating) >= Number(this.rating().starCount)) {
      return 0;
    }
    return Number(this.rating().starCount) - Number(this.rating().rating);
  });

  @HostBinding('title') title = '';

  constructor() {
    effect(() => {
      if (this.rating().label?.length) {
        this.title = this.rating().label ?? '';
      } else if (this.rating().starCount) {
        this.title = `${this.rating().rating} af ${this.rating().starCount} stjerner`;
      } else {
        this.title = `${this.rating().rating} Point`;
      }
    });
  }
}
