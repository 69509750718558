<div class="row">
  <div *ngFor="let product of products$|async; trackBy: tracker; let i = index" class="col-sm-6 col-lg-4 mb-4">
    <article
      [maxRatings]="2"
      [product]="product"
      [index]="i"
      app-product-widget
      eventSourceName="storyblok-product-list"
    ></article>
  </div>
</div>

<ng-container *ngFor="let error of errors">
  <h3>{{error}}</h3>
</ng-container>
