import {Component, input, OnInit} from '@angular/core';
import {AsyncPipe, NgIf} from '@angular/common';
import {ProductCarouselComponent} from '@layout/components/product/product-carousel/product-carousel.component';
import {from, Observable} from 'rxjs';
import {AlgoliaRecommendService} from '@core/services/algolia-recommend.service';

@Component({
  selector: 'app-product-trending-items',
  templateUrl: './product-trending-items.component.html',
  styleUrls: ['./product-trending-items.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    ProductCarouselComponent
  ]
})
export class ProductTrendingItemsComponent implements OnInit {

  trendingItems$: Observable<any>;

  facetName = input<string | undefined>();
  facetValue = input<string | undefined>();
  maxRecommendations = input<number>(9);

  constructor(
    private algoliaRecommendService: AlgoliaRecommendService,
  ) {
  }

  ngOnInit() {
    this.trendingItems$ = from(this.algoliaRecommendService.getTrendingProducts(this.maxRecommendations(), '', this.facetName(), this.facetValue()));
  }
}
