<a class="hMenu-see-all"
   [additionalContext]="{eventSourceName: 'seeAllLink'}"
   [appRouterContext]="'hierarchicalMenu'"
   [queryParams]="getItemSearchParams(activeMenuItem())"
   [routerLink]="getItemPathname(activeMenuItem())"
>
  Klik her for at se alle <b>{{ getItemLabel(activeLvl0Item()) }}</b> fra <b>{{ getItemLabel(activeMenuItem()) }}</b>
</a>
<div class="divider"></div>
<div class="facets">
  <app-instantsearch-refinement-list
    [attribute]="'field_district'"
    [limit]="6"
    [showMoreLimit]="100"
    [showMore]="true"
    [showResults]="true"
    [sortBy]="sortBy"
    [transformItems]="transformValue">
    <p>Udvalgte distrikter</p>
    <ng-template let-item="item">
      <a [queryParams]="getRefinementItemParams(item.label, 'district')"
         [routerLink]="item.value"
         [additionalContext]="getAdditionalContext(item,'district')"
         [appRouterContext]="'hierarchicalMenu'"
         [title]="item.label">
        {{ item.label }}
      </a>
    </ng-template>
  </app-instantsearch-refinement-list>


  <app-instantsearch-refinement-list
    [attribute]="'field_producer'"
    [limit]="6"
    [showMoreLimit]="100"
    [showMore]="true"
    [showResults]="true"
    [sortBy]="sortBy"
    [transformItems]="transformValue">
    <p>Udvalgte producenter</p>
    <ng-template let-item="item">
      <a [additionalContext]="getAdditionalContext(item, 'producer')"
         [routerLink]="item.value"
         [appRouterContext]="'hierarchicalMenu'"
         [queryParams]="getRefinementItemParams(item.label, 'producer')"
         [title]="item.label">
        {{ item.label }}
      </a>
    </ng-template>
  </app-instantsearch-refinement-list>

  <app-instantsearch-refinement-list
    [attribute]="'field_grapes'"
    [limit]="6"
    [showMoreLimit]="100"
    [showMore]="true"
    [showResults]="true"
    [sortBy]="sortBy">
    <p>Udvalgte druetyper</p>
    <ng-template let-item="item">
      <a [queryParams]="getRefinementItemParams(item.label, 'field_grapes')"
         [routerLink]="getItemPathname(activeMenuItem())"
         [additionalContext]="getAdditionalContext(item, 'grapes')"
         [appRouterContext]="'hierarchicalMenu'"
         [title]="item.label">
        {{ item.label }}
      </a>
    </ng-template>
  </app-instantsearch-refinement-list>
</div>
