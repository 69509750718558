import {
  ChangeDetectionStrategy,
  Component,
  effect,
  HostBinding,
  input,
  Input,
  OnDestroy,
  OnInit,
  TrackByFunction
} from '@angular/core';
import {HeadService} from '@core/services/head.service';
import {BreadcrumbService} from '@core/services/breadcrumb.service';
import {StoryblokActionsService} from '@core/services/storyblok-actions.service';
import {StoryblokDirective} from '@core/directives/storyblok.directive';

import {DynamicModule} from 'ng-dynamic-component';
import {NgComponentOutlet, NgForOf, NgIf} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {StoryblokComponentService} from '@core/services/storyblok-component.service';
import {StoryblokDynamicInputService} from '@core/services/storyblok-dynamic-input.service';

@Component({
  selector: 'app-storyblok-page',
  templateUrl: './storyblok-page.component.html',
  styleUrls: ['./storyblok-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, NgForOf, NgIf, DynamicModule, NgComponentOutlet]
})
export class StoryblokPageComponent extends StoryblokBaseComponent implements OnInit, OnDestroy {
  components: any;

  @Input() body: any[];
  @Input() actions: any[];
  @Input() _editable: any;
  @Input() seo: any;
  @Input() noindex: any;
  @Input() og_type: any;
  @Input() priority: any;
  @Input() canonical: any;
  @Input() shortlink: any;
  @Input() breadcrumbs: any;
  @Input() og_site_name: any;
  @Input() og_updated_time: any;
  @Input() breadcrumb_style: any;
  @Input() article_modified_time: any;
  @Input() article_published_time: any;

  primary_color = input<string>();
  primary_text_color = input<string>();
  secondary_color = input<string>();
  @HostBinding('style.--primary-color') primaryColor: any = '';
  @HostBinding('style.--primary-text-color') primaryTextColor: any = '';
  @HostBinding('style.--secondary-color') secondaryColor: any = '';

  constructor(
    private headService: HeadService,
    private breadcrumbService: BreadcrumbService,
    private storyblockActionsService: StoryblokActionsService,
    private storyblokComponentService: StoryblokComponentService,
    private storyblokDynamicInputService: StoryblokDynamicInputService,
  ) {
    super()
    this.components = this.storyblokComponentService.getComponents();
    effect(() => {
      this.primaryColor = this.storyblokDynamicInputService.replaceDynamicInputs(this.primary_color() ?? '', true)();
      this.primaryTextColor = this.storyblokDynamicInputService.replaceDynamicInputs(this.primary_text_color() ?? '', true)();
      this.secondaryColor = this.storyblokDynamicInputService.replaceDynamicInputs(this.secondary_color() ?? '', true)();
    });
  }

  ngOnInit() {
    this.storyblockActionsService.executeActions(this.actions ?? []);
  }

  ngOnDestroy() {
    this.headService.isAutoHeadersEnabled = true;
    this.breadcrumbService.isAutoChangeEnabled = true;
  }

  trackBy(): TrackByFunction<any> {
    return (index, item) => {
      return item._uid;
    }
  }
}
