import {
  afterRender,
  AfterRenderPhase,
  AfterViewChecked,
  Component,
  computed,
  ElementRef,
  forwardRef,
  Inject,
  input,
  Input,
  PLATFORM_ID,
  signal,
  TrackByFunction,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {isPlatformBrowser, NgForOf, NgIf} from '@angular/common';
import {TopFilterFacetComponent} from '../top-filter-facet/top-filter-facet.component';
import {AlgoliaFacetFilter} from '@core/types/algolia-filter';
import {SearchPageComponent} from '@modules/page/components/search-page/search-page.component';
import {HEADER_HEIGHT} from '@core/utils/HeaderHeight';
import {GridService} from '@core/services/grid.service';
import {TrackEventService} from '@core/services/track-event.service';

@Component({
  selector: 'app-desktop-top-filter',
  templateUrl: './desktop-top-filter.component.html',
  styleUrls: ['./desktop-top-filter.component.scss'],
  standalone: true,
  imports: [TopFilterFacetComponent, NgForOf, NgIf]
})
export class DesktopTopFilterComponent implements AfterViewChecked {

  @ViewChild('topFilterFacets') topFilterFacets: ElementRef<HTMLDivElement>;

  @Input() shownFilters: AlgoliaFacetFilter[];
  selectedAttribute: string | null;
  hideExtraFilters = signal(true);
  initFilterInProgress = signal(true)
  scrollIntoView: any;
  filtersHidden: WritableSignal<{ key: string, value: boolean }[]> = signal([]);
  hasLoadedHits = input(false);

  showMoreFilter = computed(() => this.hideExtraFilters() && this.filtersHidden().find(filter => filter.value))

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    protected gridService: GridService,
    @Inject(forwardRef(() => SearchPageComponent)) protected searchPage: SearchPageComponent,
    private trackEventService: TrackEventService
  ) {
    if (isPlatformBrowser(this.platformId) && this.initFilterInProgress()) {
      this.initFilterInProgress.set(false);
    }

    afterRender(() => {
      this.searchPage.topFilterHeightChange(this.getTopFilterHeight());
    }, {phase: AfterRenderPhase.Read})
  }
  protected getTopFilterHeight() {
    if (!isPlatformBrowser(this.platformId)) {
      return '50px';
    }
    const offsetHeight = this.topFilterFacets.nativeElement?.offsetHeight;
    if (offsetHeight === undefined) {
      return '14px';
    }
    if (!this.gridService.isLarge) {
      return (offsetHeight + 14 + HEADER_HEIGHT) + 'px';
    }
    return (offsetHeight + 14) + 'px';
  }

  ngAfterViewChecked() {
    if (this.scrollIntoView) {
      (this.scrollIntoView as any).elRef.nativeElement.scrollIntoView();
      this.scrollIntoView = null;
    }
  }

  hideFilter(hide: boolean, facet: string) {
    const filtersHidden = this.filtersHidden();
    const filter = filtersHidden.find(filter => filter.key === facet) ?? {key: facet, value: false};
    filter.value = hide;
    this.filtersHidden.set([...filtersHidden.filter(filter => filter.key !== facet), filter]);
  }

  setSelectedAttribute(attr: string, filterFacet: TopFilterFacetComponent) {
    if (this.selectedAttribute === attr) {
      this.selectedAttribute = null;
      return;
    }
    this.selectedAttribute = attr;
    this.scrollIntoView = filterFacet;
  }

  toggleFilters() {
    this.hideExtraFilters.update(d => !d);
    this.trackEventService.trackClickEvent('show-more-filters', {showExtraFilters: !this.hideExtraFilters()});
  }

  trackBy(): TrackByFunction<AlgoliaFacetFilter> {
    return (index, item) => {
      return item.algoliaAttribute;
    };
  }
}
