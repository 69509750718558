import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Input,
  Output,
  TrackByFunction
} from '@angular/core';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {ProductService} from '@core/services/product.service';
import {
  NguCarouselConfig,
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguTileComponent
} from '@ngu/carousel';
import {ProductWidgetComponent} from '../product-widget/product-widget.component';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {StoryblokService} from '@core/services/storyblok.service';
import {AlgoliaWineProduct} from '@core/types/algolia-wine-product';
import {CarouselComponent} from '@layout/components/product/carousel/carousel.component';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ProductWidgetComponent, NgOptimizedImage, NguTileComponent, NguCarouselDefDirective, NguCarouselPrevDirective, NguCarouselNextDirective, CarouselComponent],
  'host': {ngSkipHydration: 'true'},
})
export class ProductCarouselComponent {

  @Output() productClickEvent: EventEmitter<WineProduct> = new EventEmitter<WineProduct>();

  @Input() eventSourceName: string;

  products = input<WineProduct[] | AlgoliaWineProduct[] | null>(null)
  filteredProducts = computed(() => this.productService.filterProducts(this.products()));
  uniqueToken = computed(() => this.filteredProducts()?.map(d => d.vismaProductNumber).join('-') || 'empty');

  constructor(
    private productService: ProductService,
    public storyblokService: StoryblokService
  ) {
  }

  public carouselTile: NguCarouselConfig = {
    grid: {
      xs: 1, sm: 2, md: 3, lg: 3, all: 0
    },
    slide: 1,
    speed: 350,
    interval: {
      timing: 10000
    },
    point: {
      visible: true
    },
    load: 2,
    velocity: 0,
    loop: true,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  trackBy(): TrackByFunction<WineProduct> {
    return (index, item) => {
      return item?.id;
    };
  }

  shouldBeVisible(activePoint: number, index: number) {
    // Only hide products if many products in carousel
    if ((this.products?.length || 0) < 15) {
      return true;
    }
    const slideSize = 3;
    const padding = 2;

    const maxVisibleIndex = activePoint + slideSize + padding;
    const minVisibleIndex = activePoint - padding;


    return index >= minVisibleIndex && index <= maxVisibleIndex;
  }

}
