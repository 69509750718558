import {Component, Input, OnInit} from '@angular/core';
import {HsFormsService} from '../../../../../core/services/hs-forms.service';

@Component({
  selector: 'app-storyblok-hubspot-form',
  templateUrl: './storyblok-hubspot-form.component.html',
  styleUrls: ['./storyblok-hubspot-form.component.scss'],
  standalone: true,
  imports: []
})
export class StoryblokHubspotFormComponent implements OnInit {

  @Input() portalId: string;
  @Input() formId: string;

  constructor(
    private hsFormsService: HsFormsService
  ) {
  }

  ngOnInit(): void {
    this.hsFormsService.addForm(this.portalId, this.formId, '#formId-' + this.formId);
  }

}
