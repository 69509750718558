import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {StoryblokService} from '@core/services/storyblok.service';
import {ProductService} from '@core/services/product.service';
import {RoutingService} from '@core/services/routing.service';
import {AsyncPipe, NgFor, NgIf, NgOptimizedImage} from '@angular/common';
import {Observable} from 'rxjs';
import {RouterLink} from '@angular/router';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {TrackEventService} from '@core/services/track-event.service';


@Component({
  selector: 'app-product-foods',
  templateUrl: './product-foods.component.html',
  styleUrls: ['./product-foods.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgFor, AsyncPipe, RouterLink, RouterContextDirective]
})
export class ProductFoodsComponent implements OnInit {

  @Input() product: WineProduct | undefined;
  productStoryblok$: Observable<any>

  constructor(
    private storyblokService: StoryblokService,
    protected routingService: RoutingService,
    public productService: ProductService,
    protected trackEventService: TrackEventService,
  ) {
  }

  ngOnInit(): void {
    this.productStoryblok$ = this.storyblokService.getStoryObservable('product', this.routingService.getQueryParams())
  }

  trackEvent(foodLabel?: string | null) {
    this.trackEventService.trackClickEvent('food-icon', {attribute: foodLabel})
  }

}
