import {Component, input} from '@angular/core';
import {Maybe, ProductTag} from '@core/interfaces/generated/graphql';
import {NgOptimizedImage} from '@angular/common';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-product-tags',
  templateUrl: './product-tags.component.html',
  styleUrls: ['./product-tags.component.scss'],
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgbTooltip
  ]
})
export class ProductTagsComponent {

  tags = input.required<Maybe<ProductTag>[]>()

  trackByTag(index: number, tag: ProductTag | null) {
    return '' + tag?.icon + tag?.text;
  }
}
