import {Component, input, Input, OnDestroy, OnInit} from '@angular/core';
import {PrintService} from '@core/services/print.service';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {StoryblokService} from '@core/services/storyblok.service';
import {ProductService} from '@core/services/product.service';
import {RoutingService} from '@core/services/routing.service';
import {AsyncPipe, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {SafeHtmlPipe} from '@core/pipes/safe-html.pipe';
import {RatingToStarsPipe} from '@core/pipes/rating-to-stars.pipe';
import {Observable} from 'rxjs';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-print-pdf',
  templateUrl: './print-pdf.component.html',
  styleUrls: ['./print-pdf.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, SafeHtmlPipe, RatingToStarsPipe, NgOptimizedImage, AsyncPipe, FaIconComponent]
})
export class PrintPdfComponent implements OnInit, OnDestroy {

  productAttributes: any[] = [];

  _product: WineProduct;

  storyblokProduct$: Observable<any>;

  baPercentage = input<string | null>(null);

  get product(): WineProduct {
    return this._product;
  }

  @Input()
  set product(value: any) {
    this._product = value;
  }

  constructor(public storyblokService: StoryblokService,
              public productService: ProductService,
              protected routingService: RoutingService,
              private printService: PrintService) {
  }

  ngOnInit(): void {

    this.storyblokProduct$ = this.storyblokService.getStoryObservable('product', this.routingService.getQueryParams());
    this.getProductAttributes();
  }

  ngOnDestroy() {
    this.printService.setIsPrinting(false);
  }

  prepareReviewRating(reviewRating: number | undefined | null, reviewLabel: string | undefined | null) {
    if (reviewRating) {
      return reviewRating + ' Point';
    }
    return reviewLabel;
  }

  protected readonly faCircle = faCircle;

  getProductAttributes() {
    if (!this.product) {
      return;
    }

    if (this.product.meta?.grapes?.join()) {
      this.productAttributes.push({label: 'Drue:', value: this.product.meta?.grapes?.join(', ')});
    }
    if (this.product.meta?.serving) {
      this.productAttributes.push({label: 'Servering:', value: this.product.meta.serving});
    }
    if (this.product.meta?.savingPotential) {
      this.productAttributes.push({label: 'Gemmepotientiale:', value: this.product.meta.savingPotential});
    }
    if (this.product.meta?.alcohol) {
      const value = this.product.meta.alcohol;
      this.productAttributes.push({label: 'Alkohol:', value: value.includes('%') ? value : value + ' %'});
    }
    if (this.product.meta?.contents) {
      this.productAttributes.push({label: 'Indhold:', value: this.product.meta.contentLabel});
    }
  }

  getNumberOfBaPercentageCircles(): number[] {
    const baPercentage = parseInt(this.baPercentage() ?? '', 10);
    if (!baPercentage) {
      return [];
    }
    if (baPercentage < 3000) {
      return [1];
    }
    if (baPercentage >= 3000 && baPercentage < 3500) {
      return [1, 2];
    }
    if (baPercentage >= 3500) {
      return [1, 2, 3];
    }
    return [];
  }

}
