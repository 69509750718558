@if (product()?.inStock) {
  <app-qty-input
    (submitEvent)="addToCart()"
    [(qty)]="qtyInput"
    [small]="true"
    class="d-flex">
  </app-qty-input>
  <button (click)="addToCart($event)" [disabled]="addingToCart()" class="btn btn-primary"
  >Læg i kurv
  </button>
} @else if (product() && !product()?.inStock) {
  <div class="btn btn-light">
    Udsolgt
  </div>
}
@if(!hideLikeButton()) {
  <app-product-like [useAsButton]='true' [product]="this.getProduct()"></app-product-like>
}
