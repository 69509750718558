import {Component} from '@angular/core';

@Component({
  selector: 'app-user-icon',
  styles: `
    :host {
      height: 25px;
      width: 24px;
      display: inline-block;
      color: white;
      line-height: 0;
    }
  `,
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path
        d="M12.167 12.5C13.973 12.5 15.7051 11.8415 16.9821 10.6694C18.2592 9.49732 18.9767 7.9076 18.9767 6.25C18.9767 4.5924 18.2592 3.00269 16.9821 1.83058C15.7051 0.65848 13.973 0 12.167 0C10.3609 0 8.62885 0.65848 7.35178 1.83058C6.07472 3.00269 5.35727 4.5924 5.35727 6.25C5.35727 7.9076 6.07472 9.49732 7.35178 10.6694C8.62885 11.8415 10.3609 12.5 12.167 12.5ZM9.73569 14.8438C4.49542 14.8438 0.25 18.7402 0.25 23.5498C0.25 24.3506 0.95757 25 1.83006 25H22.5039C23.3764 25 24.0839 24.3506 24.0839 23.5498C24.0839 18.7402 19.8385 14.8438 14.5982 14.8438H9.73569Z"
        fill="white"/>
    </svg>
  `,
  standalone: true
})

export class UserIconComponent {

}
