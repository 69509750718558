import {Injectable} from '@angular/core';
import {Cart, CartItem, Mutation, WineProduct} from '../interfaces/generated/graphql';
import {ProductService} from './product.service';
import {PageService, PageWrapper} from './page.service';
import {Subscription} from 'rxjs';
import {formatPrice} from '../pipes/price.pipe';
import {ApolloService} from './apollo.service';
import {Router} from '@angular/router';
import {captureMessage} from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
export class TrackEventService {

  lastVirtualProductView: string;
  routerContext: { eventName: string, additionalContext?: Record<string, string | number> } | null;

  pageSub: Subscription;

  constructor(
    private productService: ProductService,
    private pageService: PageService,
    private apollo: ApolloService,
    private router: Router
  ) {
  }

  public init() {
    this.pageSub?.unsubscribe();
    this.pageSub = this.pageService.getPageAsync().subscribe(page => {
      this.pageView(page);
    })
  }

  public pageView(page: PageWrapper) {
    const title = page.page.title;
    const url = page.page.path;
    if (!title || !url) {
      return;
    }
    if (!page.isPathChanged) {
      return;
    }
    const routerContext = this.getRouterContextAndClear();
    return this.trackEvent('page_view', {
      virtualPage: url,
      pageTitle: title,
      routerContext: routerContext?.eventName,
      ...routerContext?.additionalContext
    });
  }

  public beginCheckout(cart: Cart) {
    if (!cart.id) {
      return;
    }
    return this.trackEvent('begin_checkout', {
      ecommerce: {
        value: formatPrice(cart.totalPrice),
        currency: 'DKK',
        coupon: cart.couponCode,
        items: this.getDatalayerFormatItems(cart)
      }
    });
  }

  public productView(product: WineProduct) {
    if (!product?.id) {
      return;
    }
    if (product.id === this.lastVirtualProductView) {
      return;
    }
    this.lastVirtualProductView = product.id;
    return this.trackEvent('view_item', {
      is_presale: !!product.preorderDate,
      hasAuthor: !!product?.productAuthor,
      ecommerce: {
        value: this.productService.getDiscountPriceFromProduct(product),
        currency: 'DKK',
        items: [
          this.covertProductToDataLayerItem(product)
        ],
      }
    });
  }

  public viewCart(cart: Cart) {
    if (!cart.id) {
      return;
    }
    return this.trackEvent('view_cart', {
      ecommerce: {
        value: formatPrice(cart.totalPrice),
        currency: 'DKK',
        items: this.getDatalayerFormatItems(cart)
      }
    });
  }

  public trackPurchaseEvent(maskedCartId: string) {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'trackPurchaseEventMutation',
      variables: {
        maskedCartId
      }
    });
  }

  public trackEvent(event: string, data: Record<string, any>) {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'trackingEventMutation',
      variables: {
        event,
        data: JSON.stringify(data)
      }
    }).catch((e) => {
      captureMessage('Failed to save tracking event', {
        level: 'warning',
        extra: {
          event,
          data,
          error: e,
          networkError: e.networkError?.message,
        }
      });
    });
  }

  public navigateWithTracking(commands: any[], extras: Record<string, any>, eventName: string, additionalContext: Record<string, string | number> = {}, resetPage = false) {
    this.routerContext = {
      eventName,
      additionalContext
    }
    if (resetPage) {
      this.pageService.resetPage();
    }
    this.router.navigate(commands, extras);
  }

  public trackClickEvent(clickEvent: string, data: Record<string, any> = {}) {
    data = {
      ...data,
      clickEvent
    }
    return this.trackEvent('click', data);
  }

  protected covertProductToDataLayerItem(product: WineProduct, qty = 0): object {
    return {
      item_name: product?.name,
      item_id: product?.sku,
      price: this.productService.getDefaultPriceFromProduct(product, 1),
      discount: this.productService.getDefaultPriceFromProduct(product, 1) - this.productService.getDiscountPriceFromProduct(product, qty),
      quantity: qty,
      item_category: product.meta?.productType,
      item_category2: product.meta?.productSubType,
    }
  }

  protected covertCartItemToDataLayerItem(cartItem: CartItem, qty = cartItem.qty): object {
    return {
      item_name: cartItem?.name,
      item_id: this.getSku(cartItem?.sku || ''),
      price: formatPrice(cartItem.compareAtPrice),
      discount: formatPrice(cartItem.compareAtPrice) - formatPrice(cartItem.qtyPrice),
      quantity: qty,
      item_category: cartItem.attributeSet,
      item_category2: cartItem.productSubType,
    }
  }

  protected getSku(sku: string) {
    if (sku?.includes('giftcard')) {
      return 'giftcard';
    }
    return sku;
  }

  protected getDatalayerFormatItems(cart: Cart) {
    const items = (cart?.items || []).filter(Boolean) as CartItem[];
    return items?.map(item => this.covertCartItemToDataLayerItem(item));
  }

  private getRouterContextAndClear() {
    const routerContext = this.routerContext;
    this.routerContext = null;
    return routerContext;
  }

}
