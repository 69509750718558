import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {RoutingService} from './routing.service';
import {CookieService} from './cookie.service';
import {environment} from '@env/environment';
import {isPlatformServer} from '@angular/common';
import {captureException} from '@sentry/angular-ivy';
import {SuperpayEvent, SuperpayEventService} from '@core/services/superpay-event.service';
import {Maybe} from '@core/interfaces/generated/graphql';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({providedIn: 'root'})
export class SuperpayService {

  protected readonly superpayUidCookieName = 'superpayUID';

  constructor(
    private router: RoutingService,
    protected cookieService: CookieService,
    private superpayEventService: SuperpayEventService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  public initConfig() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    (window as any).superpayConfig = {
      store: environment.superpay.domain,
      onlyUseJWTWithCustomerToken: true,
      hostname: 'https://superpay.supervin.dk',
      onEvent: (event: SuperpayEvent, data: any) => {
        console.log('Superpay event', event, data);
        this.superpayEventService.runSuperpayEvent(event, data);
      }
    }
  }

  public checkUrlForSuperpayAction() {
    const spAction = this.router.getQueryParams()['sp-action'];
    const email = this.router.getQueryParams()['email'];

    if (!spAction || !email) {
      return;
    }
    if (spAction === 'reset-password') {
      this.forgottenPasswordSendEmailModal(email);
      return;
    }
  }

  public setCustomerToken(customerToken: string | null = null, uid: string | null = null, cartId: string | null = null) {
    if (customerToken) {
      this.superpayLoginWithCustomerToken(customerToken, uid, cartId);
    }
  }

  public getSuperpayCartId(): string|null {
    return this.router.getQueryParams<{ cartid: string }>(true)?.cartid || null
  }

  public setSuperpayUUID(superpayUserId: string | undefined) {
    this.runSuperpayFunction('superpaySetCookie', [superpayUserId]);
  }

  public superpayLoginWithCustomerToken(customerToken: string, uid: string | null = null, cartId: string | null = null) {
    this.runSuperpayFunction('superpayLoginWithCustomerToken', [customerToken, uid, cartId]);
  }

  public superpayLogout() {
    this.runSuperpayFunction('superpayLogout');
  }

  public superpayIsPasskeyCredentialsAvailable(uid?: string, email?: string) {
    return this.runSuperpayFunction('isPasskeyCredentialsAvailable', [uid, email]);
  }

  public superpayPromptPasskeyLoginIfAvailable(uid?: string, email?: string, cartId?: string | null) {
    return this.runSuperpayFunction('promptPasskeyLoginIfAvailable', [uid, email, cartId]);
  }

  public superpayRefetchCart(cartId: string) {
    return this.runSuperpayFunction('superpayRefetchCart', [cartId]);
  }

  protected forgottenPasswordSendEmailModal(email: Maybe<string> | undefined = undefined) {
    this.modalService.dismissAll();
    import('@layout/components/modals/reset-password-send-email-modal/reset-password-send-email-modal.component').then(({ResetPasswordSendEmailModalComponent}) => {
      const modalReference = this.modalService.open(ResetPasswordSendEmailModalComponent);
      if (email) {
        modalReference.componentInstance.email = email;
      }
    });
  }

  public removeSuperpayUserCookie() {
    this.cookieService.delete(this.superpayUidCookieName)
  }

  private runSuperpayFunction(funcName: string, data: any[] = [], retries = 0) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (!funcName) {
      return;
    }
    const superpayFunction = (window as any)[funcName];

    if (retries > 300) {
      captureException(new Error('superpaySetCookie is not defined after 300 retries.'));
      return;
    }

    // If superpaySetCookie is not defined, wait for it to be defined
    if (typeof superpayFunction === 'undefined') {
      // If superpaySetCookie is not defined, wait for it to be defined
      setTimeout(() => {
        this.runSuperpayFunction(funcName, data, retries + 1);
      }, 200);
      return;
    }
    console.log('Running superpay function', funcName, data);
    return superpayFunction(...data);
  }

}
