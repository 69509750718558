import {computed, Injectable, Signal} from '@angular/core';
import {GroupVoucherService} from './groupVoucher.service';
import dayjs from 'dayjs';
import {PageInfoContextService} from '@core/services/page-info-context.service';
import {RoutingService} from '@core/services/routing.service';

@Injectable({
  providedIn: 'root'
})
export class StoryblokDynamicInputService {

  dynamicOptions: {
    [key: string]: Signal<any>
  } = {
    groupVoucher: computed(() => {
      if (this.pageInfoContextService.getContext('groupVoucher')()) {
        return this.pageInfoContextService.getContext('groupVoucher')()?.data;
      }
      return this.groupVoucherService.groupVoucherSignal().groupVoucher;
    }),
    currentUrl: computed(() => {
      return '/' + this.routingService.getURL();
    })
  }

  constructor(
    private groupVoucherService: GroupVoucherService,
    private pageInfoContextService: PageInfoContextService,
    private routingService: RoutingService
  ) {
  }

  replaceDynamicInputs(data: string, removeDynamicInputs = false) {
    const regex = /{{(.*?)}}/g;
    return computed(() => data.replace(regex, (match, template) => {
      const dynamicOption = template.split('.')[0];
      const dataObject = this.dynamicOptions[dynamicOption]();
      const valuePath = template
        .split('.')
        .slice(1)
        .join('.')
        .split('|')[0]
        .trim();
      const value = this.getDataFromDynamicInput(valuePath, dataObject, match, removeDynamicInputs);
      const pipe = template.split('|')[1];
      if (pipe) {
        return this.applyPipe(pipe, value);
      }
      return value;
    }));
  }

  private getDataFromDynamicInput(template: string, data: any, match: string, removeDynamicInputs: boolean): string {
    if (!template.includes('.') && typeof data === 'string') {
      return data;
    }

    const dynamicOption = template.split('.')[0];
    if (!data || !data.hasOwnProperty(dynamicOption)) {
      return removeDynamicInputs ? '' : match;
    }
    if (template.includes('.')) {
      const nextTemplate = template.split('.').slice(1).join('.');
      return this.getDataFromDynamicInput(nextTemplate, this.getDynamicInputData(data[dynamicOption]), match, removeDynamicInputs);
    }
    return this.getDynamicInputData(data[dynamicOption]);
  }

  private getDynamicInputData(data: any): any {
    if (Array.isArray(data)) {
      return data[0];
    }
    return data;
  }

  private applyPipe(pipe: string, value: string): string {
    if (pipe.includes(':')) {
      const pipeName = pipe.split(':')[0];
      const pipeValue = pipe.split(':')[1];
      if (pipeName === 'date') {
        const date = dayjs(value);
        if (date.isValid()) {
          return date.format(pipeValue);
        }
      }
    }
    return value;
  }

}
