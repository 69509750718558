import {ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {StoryblokService} from '@core/services/storyblok.service';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {StoryblokDirective} from '@core/directives/storyblok.directive';

import {DynamicModule} from 'ng-dynamic-component';
import {NgComponentOutlet, NgForOf, NgIf} from '@angular/common';
import {StoryblokComponentService} from '@core/services/storyblok-component.service';
import {StoryblokReadMoreComponent} from '@modules/storyblok/components/storyblok-read-more.component';
import {ReadMoreComponent} from '@layout/components/general/read-more/read-more.component';
import {GridService} from '@core/services/grid.service';

@Component({
  selector: 'app-row',
  templateUrl: './storyblok-row.component.html',
  styleUrls: ['./storyblok-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, NgForOf, NgIf, DynamicModule, NgComponentOutlet, ReadMoreComponent]
})

export class StoryblokRowComponent extends StoryblokReadMoreComponent implements OnInit {

  @Input() columns: any[];
  @Input() _editable: any;
  @Input() general_styling: any;

  @HostBinding('class') class: any;

  components: any;

  constructor(
    private storyblokService: StoryblokService,
    private storyblokStylingService: StoryblokStylingService,
    protected storyblokComponentService: StoryblokComponentService,
    gridService: GridService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    super(gridService, platformId);
    this.components = storyblokComponentService.getComponents();
  }

  ngOnInit() {
    this.class = this.storyblokStylingService.getClassList(this.general_styling);
  }


  get trackByFn() {
    return this.storyblokService.trackByUID()
  }
}
