<div *ngIf="product?.id" class="d-flex col-direction flex-wrap units-container">
  <ng-container *ngIf="product?.meta?.grapes as grapes">
    <div class="flex-grow-1" *ngIf="grapes.length > 0">
      <span>Drue <strong *ngFor="let grape of grapes; let last = last">
        <a [routerLink]="'/druer/' + getFriendlyName(grape)">{{grape}}</a><ng-container *ngIf="!last">, </ng-container></strong>
      </span>
    </div>
  </ng-container>
  <div *ngIf="product?.meta?.port_type as port_type">
    <span>Portvinstype <strong>{{port_type}}</strong></span>
  </div>
  <div *ngIf="product?.meta?.vintage as vintage">
    <span>Årgang <strong>{{vintage}}</strong></span>
  </div>
  <div *ngIf="product?.meta?.serving as serving">
    <span>Servering <strong>{{serving}}</strong></span>
  </div>
  <div *ngIf="product?.meta?.savingPotential as savingPotential">
    <span>Gemme potentiale <strong>{{savingPotential}}</strong></span>
  </div>
  <div *ngIf="product?.meta?.alcohol as alcohol">
    <span>Alkohol <strong>{{alcohol.includes('%') ? alcohol : alcohol + ' %' }}</strong></span>
  </div>
  <div *ngIf="product?.meta?.contentLabel as contentLabel">
    <span>Indhold <strong>{{ contentLabel }}</strong></span>
  </div>
  <div *ngIf="product?.closure as closure">
    <span>Lukketype <strong>{{closure}}</strong></span>
  </div>
  <div *ngIf="product?.packaging as packaging">
    <span>Emballage <strong>{{packaging}}</strong></span>
  </div>
</div>
