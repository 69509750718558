import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SearchPageComponent} from '../search-page/search-page.component';
import {PageInfo} from '@core/interfaces/generated/graphql';
import {CustomProductListFilter} from '@core/services/algolia.service';

@Component({
  selector: 'app-search-page-dynamic',
  templateUrl: './search-page-dynamic.component.html',
  styleUrls: ['./search-page-dynamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchPageComponent]
})
export class SearchPageDynamicComponent {

  _pageInfo: any;
  filter: CustomProductListFilter;
  title = '';

  get pageInfo(): PageInfo {
    return this._pageInfo;
  }

  @Input()
  set pageInfo(value) {
    this._pageInfo = value;
    const pageData = value?.pageData as CustomProductListFilter;
    if (pageData?.types) {
      this.filter = pageData;
    }
    if (pageData?.title) {
      this.title = pageData.title;
    }
  }

}
