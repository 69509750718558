@if (product(); as product) {
  @if (!useAsButton()) {
    <div class="user-like" [class.show-animation]="heartClicked()">
      @if (isItemInWishlist()) {
        <app-heart-solid-icon (click)="toggleWishlist(true)"/>
      } @else {
        <app-heart-empty-icon (click)="toggleWishlist(false)"/>
      }
    </div>
  }

  @if (useAsButton()) {
    <div class="user-like" [class.show-animation]="heartClicked()">
      @if (isItemInWishlist()) {
        <button class="like-button" (click)="toggleWishlist(true)"><app-heart-solid-icon [width]="'32px'" [height]="'32px'"/></button>
      } @else {
        <button class="like-button" (click)="toggleWishlist(false)"><app-heart-empty-icon [width]="'32px'" [height]="'32px'"/></button>
      }
    </div>
  }
}
