import {Component, forwardRef, HostBinding, Inject, input, OnInit, signal, WritableSignal} from '@angular/core';
import {noop} from 'rxjs';
import {connectSortBy} from 'instantsearch.js/es/connectors';
import {NgForOf, NgIf} from '@angular/common';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {TrackEventService} from '@core/services/track-event.service';
import {SortByWidgetDescription} from 'instantsearch.js/es/connectors/sort-by/connectSortBy';
import {FormsModule} from '@angular/forms';
import {AlgoliaService} from '@core/services/algolia.service';


@Component({
  selector: 'app-instantsearch-sort-by',
  templateUrl: './instantsearch-sort-by.component.html',
  styleUrls: ['./instantsearch-sort-by.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, FormsModule]
})
export class InstantsearchSortByComponent extends TypedBaseWidgetComponent<any, any> implements OnInit {

  sortOptions = input<{ label?: string | null, value?: string | null }[]>([]);

  public override state: WritableSignal<SortByWidgetDescription['renderState']> = signal({
    refine: noop,
    initialIndex: this.algoliaService.sortByIndex,
  } as any);

  @HostBinding('class') class = 'form-floating';

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    private trackEventService: TrackEventService,
    private algoliaService: AlgoliaService,
  ) {
    super('ToggleRefinement');
  }

  override ngOnInit(): void {
    this.createWidget(connectSortBy, {
      items: this.sortOptions(),
    });
    super.ngOnInit();
  }

  handleChange(event: Event) {
    const value = (event.target as HTMLSelectElement).value;
    this.trackEventService.trackClickEvent('sort_by', {
      value,
    });
    localStorage.setItem('algoliaSortIndex', value);
    this.state().refine(value);
  }
}
