import {Injectable} from '@angular/core';
import aa from 'search-insights';
import {CookiebotConsent, CookiebotService} from './cookiebot.service';

@Injectable({
  providedIn: 'root'
})
export class AlgoliaInsightsService {

  constructor(
    private cookiebotService: CookiebotService
  ) {
    this.listenOnCookiePermission();
    const cookiebotConsent = this.cookiebotService.cookiebotConsent.getValue();
    if (cookiebotConsent.statistics) {
      this.updateCookiebotConsent(cookiebotConsent);
    }
  }

  listenOnCookiePermission() {
    this.cookiebotService.cookiebotConsent.subscribe((cookiebotConsent) => {
      this.updateCookiebotConsent(cookiebotConsent);
    });
  }

  updateCookiebotConsent(value: CookiebotConsent) {
    aa('init', {
      partial: true,
      useCookie: value.statistics,
    });
  }

  clickedAddToCart(productId: string, queryId?: string) {
    if (!queryId) {
      aa('convertedObjectIDs', {
        eventName: 'Added to cart',
        index: 'Products',
        objectIDs: [productId],
      });
      return;
    }
    aa('convertedObjectIDsAfterSearch', {
      eventName: 'Added to cart',
      index: 'Products',
      objectIDs: [productId],
      queryID: queryId
    });
  }

  clickedOnProduct(productId: string, queryId?: string, position?: number) {
    if (!queryId) {
      aa('clickedObjectIDs', {
        eventName: 'Product Clicked',
        index: 'Products',
        objectIDs: [productId]
      });
      return;
    }
    aa('clickedObjectIDsAfterSearch', {
      eventName: 'Product Clicked',
      index: 'Products',
      objectIDs: [productId],
      queryID: queryId,
      positions: [position ?? 0]
    });
  }


}
