import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Cart} from '@core/interfaces/generated/graphql';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CurrencyPipe, NgForOf, NgIf} from '@angular/common';
import {ExistsPipe} from '@core/pipes/exists.pipe';
import {SuperpayBuyBtnComponent} from '@layout/components/superpay/superpay-buy-btn/superpay-buy-btn.component';
import {formatPrice, PricePipe} from '@core/pipes/price.pipe';
import {PowerStopService} from '@core/services/power-stop.service';
import {CartOverviewComponent} from '@modules/checkout/components/cart-overview/cart-overview.component';
import {CartItemComponent} from '@modules/checkout/components/cart-overview/cart-item/cart-item.component';
import {GridService} from '@core/services/grid.service';
import {
  FreeShippingIncentiveComponent
} from '@modules/checkout/components/cart-overview/free-shipping-incentive/free-shipping-incentive.component';

@Component({
  selector: 'app-cart-small-preview',
  templateUrl: './cart-small-preview.component.html',
  styleUrls: ['./cart-small-preview.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, CurrencyPipe, ExistsPipe, FontAwesomeModule, SuperpayBuyBtnComponent, PricePipe, CartOverviewComponent, CartItemComponent, FreeShippingIncentiveComponent]
})
export class CartSmallPreviewComponent {

  @Input() cart: Cart;
  @Output() goToCart = new EventEmitter();

  @Output() showPreviewChange = new EventEmitter<boolean>();

  constructor(
    private powerStopService: PowerStopService,
    private gridService: GridService
  ) {
  }

  get isMobile() {
    return !this.gridService.isSmall;
  }

  trackBy(index: number, item: any) {
    return item.id;
  }

  goToCheckout() {
    if (<number>this.cart.itemsTotalQty > 0) {
      this.powerStopService.goToPowerStop('cart_small_go_to_basket_click');
    }
    this.showPreviewChange.emit(false);
  }

  totalPrice() {
    return formatPrice(this.cart.totalPrice);
  }
}
