<div class="header-middle">
  <div class="container">
    <div class="row justify-content-between header-box">
      <div class="col-6 col-lg-2 align-self-center header-Logo" data-rr-shared="true">
        <a [appRouterContext]="'supervinLogo'" [title]="'Gå til forsiden'" class="logo-link" routerLink="/">
          <img
            [ngSrc]="this.storyblokService.getImageUrl({filename: 'https://a.storyblok.com/f/106593/5459x1241/65f115b636/supervin_hvidorange_highres.png?cv=1717662140574'})"
            alt="SuperVin"
            height="41"
            width="181"
            [priority]="true"
          />
        </a>
      </div>

      <div [class.hide-element-on-mobile]="isUserUniverse() || isTastingEvent()"
           class="col-12 col-lg-6 header-quicksearch order-1 order-lg-0">
        <app-quicksearch></app-quicksearch>
      </div>

      <div class="col-6 col-lg-4 header-icons order-0">
        <div class="header-links">
          <div class="link-firmagaver hide-element-on-mobile" data-rr-shared="true" role="button">
            <a [appRouterContext]="'headerMenu'" routerLink="/info/b2b-salg" title="Firmagaver">
              <app-gift-icon/>
              <p class="mb-0 mt-1 hide-element-on-mobile">Firmagaver</p>
            </a>
          </div>

          <app-header-middle-voucher class="lower-item" role="button"></app-header-middle-voucher>

          <div (click)="openCustomerUniverseModal()" class="user-login lower-item" role="button">
            @if (userSignal()?.id) {
              <div class="position-relative">
                <app-user-icon/>
                <app-check-marker-icon class="login-check-box"/>
              </div>
              <span class="mt-1 hide-element-on-mobile user-hello">Hej {{ customerFirstname() }}</span>
            } @else {
              <app-user-icon/>
              <span class="mt-1 hide-element-on-mobile">Min konto</span>
            }
          </div>
        </div>
        <app-cart-small class="lower-item header-Cart"></app-cart-small>
        <fa-icon (click)="toggleMenu.emit()" [icon]="faBars" class="hide-element-on-desktop burger-menu"></fa-icon>
      </div>
    </div>
  </div>
</div>


