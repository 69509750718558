import {computed, Inject, Injectable, PLATFORM_ID, signal, Signal} from '@angular/core';
import {GroupVoucherCode, Mutation,} from '../interfaces/generated/graphql';
import {BehaviorSubject, Observable} from 'rxjs';


import {Router} from '@angular/router';
import {RoutingService} from './routing.service';
import {isPlatformServer} from '@angular/common';
import {ApolloService} from './apollo.service';
import {CartService} from './cart.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CustomOneProductFilter} from '@core/services/algolia.service';

export type GroupVoucherWrapper = { groupVoucher: GroupVoucherCode | null, loading: boolean };

@Injectable({
  providedIn: 'root'
})
export class GroupVoucherService {

  private groupVoucher: BehaviorSubject<GroupVoucherCode | null> = new BehaviorSubject<GroupVoucherCode | null>(null);
  voucherSearchPageFilter = computed(() => {
    if (isPlatformServer(this.platformId)) {
      return {
        types: [],
        objectIds: [],
        title: '',
        storyblok: undefined
      };
    }
    const groupVoucher = this.groupVoucherSignal();
    const filter = groupVoucher.groupVoucher?.prices?.map((price, index) => ({
      vismaId: price?.vismaId,
      weight: index,
    })) as CustomOneProductFilter[];
    return {
      types: [],
      objectIds: filter,
      title: 'Voucher',
      storyblok: undefined
    };
  });

  constructor(
    private apollo: ApolloService,
    private router: Router,
    private routingService: RoutingService,
    private cartService: CartService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.getGroupVoucherFromUrl();
  }

  protected _groupVoucherSignal = signal<GroupVoucherWrapper>({groupVoucher: null, loading: true})

  currentGroupVoucher(): GroupVoucherCode | null {
    return this.groupVoucher.getValue();
  }

  async getGroupVoucherFromUrl() {
    const query = this.routingService.getQueryParams();
    const voucher = query['voucher'];
    if (voucher) {
      const voucherData = await this.applyGroupVoucher(voucher);
      this.router.navigateByUrl((voucherData as any)?.redirectTo || '/voucher/list');
    }
  }

  get groupVoucherSignal(): Signal<GroupVoucherWrapper> {
    return this._groupVoucherSignal.asReadonly();
  }

  setGroupVoucherLoading(loading: boolean) {
    this._groupVoucherSignal.set({groupVoucher: this.groupVoucher.getValue(), loading});
  }

  getGroupVoucher(): Observable<GroupVoucherCode | null> {
    return this.groupVoucher.asObservable()
  }

  setGroupVoucher(groupVoucher: GroupVoucherCode | null) {
    this.groupVoucher.next(groupVoucher);
    this._groupVoucherSignal.set({groupVoucher, loading: false});
  }

  async applyGroupVoucher(code: string): Promise<GroupVoucherCode | undefined> {
    this.setGroupVoucherLoading(true);
    const res = await this.apollo.mutatePromise<Mutation>({
      queryName: 'applyGroupVoucherMutation',
      variables: {
        code
      }
    }).catch(err => {
      const graphQLError = err.graphQLErrors?.[0];
      const groupVoucherCompanyProfileError = graphQLError.debugMessage === 'GROUP_VOUCHER_COMPANY_PROFILE_EXPIRED_OR_INVALID';
      if (groupVoucherCompanyProfileError && graphQLError.context.redirect) {
        this.router.navigateByUrl(graphQLError.context.redirect);
        this.modalService.dismissAll();
        return;
      }
      throw err;
    })

    const voucher = res?.data?.applyGroupVoucher;
    if (voucher?.groupVoucher?.code) {
      this.setGroupVoucher(voucher.groupVoucher);
      this.cartService.updateCart(voucher.cart)
    } else {
      this.setGroupVoucher(null);
    }
    return voucher?.groupVoucher || undefined;
  }

  removeGroupVoucher() {
    return this.apollo.mutatePromise({
      queryName: 'removeGroupVoucherFromCartMutation'
    }).then(res => {
      this.setGroupVoucher(null);
      return res;
    });
  }
}
