import {Component, ElementRef, forwardRef, Inject, Input, signal, ViewChild, WritableSignal} from '@angular/core';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {
  SearchBoxConnectorParams,
  SearchBoxRenderState,
  SearchBoxWidgetDescription
} from 'instantsearch.js/es/connectors/search-box/connectSearchBox';
import {noop} from 'instantsearch.js/es/lib/utils';
import {connectSearchBox} from 'instantsearch.js/es/connectors';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';

@Component({
  selector: 'app-instantsearch-search-box',
  standalone: true,
  template: ''
})
export class InstantSearchSearchBoxComponent extends TypedBaseWidgetComponent<SearchBoxWidgetDescription, SearchBoxConnectorParams> {
  @ViewChild('searchBox', {static: false})
  searchBox: ElementRef;
  @Input() public searchAsYouType: boolean = true;

  public override state: WritableSignal<SearchBoxRenderState> = signal({
    query: '',
    refine: noop,
    clear: noop,
    isSearchStalled: false,
  });

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent
  ) {
    super('SearchBox');
    this.createWidget(connectSearchBox, {});
  }


}
