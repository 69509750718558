import {Component, Input} from '@angular/core';
import {Rating, WineProduct} from '@core/interfaces/generated/graphql';

import {ProductRatingComponent} from '@layout/components/product/product-rating/product-rating.component';
import {NgForOf, NgIf} from '@angular/common';
import {ProductRateComponent} from '@layout/components/product/product-rate/product-rate.component';
import {ProductLikeComponent} from '@layout/components/product/product-like/product-like.component';

@Component({
  selector: 'app-product-like-and-rate',
  templateUrl: './product-like-and-rate.component.html',
  styleUrls: ['./product-like-and-rate.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, ProductRatingComponent, ProductRateComponent, ProductLikeComponent]
})
export class ProductLikeAndRateComponent {

  @Input() product: WineProduct | undefined;

  get ratings(): Rating[] {
    const ratings = this.product?.meta?.ratings;
    if (!ratings) {
      return [];
    }
    return ratings.filter(d => !!d) as Rating[];
  }

  getProduct(): WineProduct | null {
    if (!this.product) {
      return null;
    }
    return this.product;
  }
}
