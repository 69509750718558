import {ChangeDetectorRef, Component, HostBinding, Inject, input, NgZone, OnInit, PLATFORM_ID} from '@angular/core';
import {ScriptService} from '@core/services/script.service';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'div[app-orbitvu-360]',
  standalone: true,
  imports: [],
  templateUrl: './orbitvu-360.component.html',
  styleUrl: './orbitvu-360.component.scss'
})
export class Orbitvu360Component implements OnInit {

  orbitvuScriptId = input.required<string>();
  orbitvuId = input.required<string>();

  @HostBinding('attr.id')
  get hostId() {
    return 'ovContent-' + this.orbitvuId();
  }

  constructor(
    protected scriptService: ScriptService,
    @Inject(PLATFORM_ID) protected platformId: object,
    protected changeDetectorRef: ChangeDetectorRef,
    protected zone: NgZone
  ) {
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.zone.runOutsideAngular(() => {
      this.scriptService.addScript({
        type: 'text/javascript',
        src: '//cdn.orbitvu.co/orbitvu12/310612/orbitvu.js',
        async: true
      }).then(() => {
        // To prevent angular rerender this component, we need to detach the change detector
        this.changeDetectorRef.detach();
        console.log('orbitvu script loaded');


        const test = (window as any).inject_orbitvu(
          this.hostId,
          '//cdn.orbitvu.co/orbitvu12/310612',
          '',
          {
            'frame_rate': '12',
            'background_color': '0xffffff00',
            'border_color': '0x00000000',
            'preload': 'no',
            'teaser': 'static',
            'style': '4',
            'gui_background': '0x000000c8',
            'gui_foreground': '0xffffffc8',
            'gui_foreground_active': '0x00b5a7c8',
            'lang': 'en',
            'auto_rotate_dir': 'right',
            'rotate_dir': 'auto',
            'vmove': 'yes',
            'ovskin': 'no',
            'border': 'no',
            'html5_initial_info': 'no',
            'html5_hint': 'yes',
            'vmove_dir': 'down',
            'max_scale': '1',
            'width': '100%',
            'height': '100%',
            'mousewheel': 'yes',
            'color_theme': '',
            'report_bugs': 'no',
            'social_url': `https://orbitvu.co/share/${this.orbitvuId()}/${this.orbitvuScriptId()}/360/view`,
            'auto_drag_switch': 'yes',
            'partial_load': 'yes',
            'hires_forced_zoom': 'yes',
            'beacon_url': 'https://orbitvu.co/beacon/1/GvuYsbOZw1hhb6szR3WSVPQ_CFK1bpWpW0veYf6oC6J5o07CSsYquOFLPr11Dek-.jpg',
            'viewer_base': '//cdn.orbitvu.co/orbitvu12/310612/',
            'ovus_folder': `https://cdn360.orbitvu.cloud/ef6abcfe/${this.orbitvuId()}/ov3601/`,
            'content2': 'yes',
            'use_config_url': 'no',
            'content_data': {
              'scales': [{
                'cols': 2,
                'rows': 5,
                'width': 1600,
                'height': 5042,
                'tile_width': 1024,
                'tile_height': 1024,
                'value': '100.0'
              }, {
                'cols': 1,
                'rows': 1,
                'width': 325,
                'height': 1024,
                'tile_width': 1024,
                'tile_height': 1024,
                'value': '20.3'
              }, {
                'cols': 1,
                'rows': 1,
                'width': 162,
                'height': 512,
                'tile_width': 512,
                'tile_height': 512,
                'value': '10.1'
              }],
              'images': [{'new_name': 'a_', 'num': 0, 'name': 'a_0_0', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 1,
                'name': 'a_0_1',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 2, 'name': 'a_0_2', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 3,
                'name': 'a_0_3',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 4, 'name': 'a_0_4', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 5,
                'name': 'a_0_5',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 6, 'name': 'a_0_6', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 7,
                'name': 'a_0_7',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 8, 'name': 'a_0_8', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 9,
                'name': 'a_0_9',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 10, 'name': 'a_0_10', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 11,
                'name': 'a_0_11',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 12, 'name': 'a_0_12', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 13,
                'name': 'a_0_13',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 14, 'name': 'a_0_14', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 15,
                'name': 'a_0_15',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 16, 'name': 'a_0_16', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 17,
                'name': 'a_0_17',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 18, 'name': 'a_0_18', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 19,
                'name': 'a_0_19',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 20, 'name': 'a_0_20', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 21,
                'name': 'a_0_21',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 22, 'name': 'a_0_22', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 23,
                'name': 'a_0_23',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 24, 'name': 'a_0_24', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 25,
                'name': 'a_0_25',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 26, 'name': 'a_0_26', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 27,
                'name': 'a_0_27',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 28, 'name': 'a_0_28', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 29,
                'name': 'a_0_29',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 30, 'name': 'a_0_30', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 31,
                'name': 'a_0_31',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 32, 'name': 'a_0_32', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 33,
                'name': 'a_0_33',
                'ext': 'jpg'
              }, {'new_name': 'a_', 'num': 34, 'name': 'a_0_34', 'ext': 'jpg'}, {
                'new_name': 'a_',
                'num': 35,
                'name': 'a_0_35',
                'ext': 'jpg'
              }],
              'images2d': [],
              'max_height': 5042,
              'max_width': 1600,
              'hangles': 36,
              'vangles': 1
            },
            'ovus_cache_modifier': 'a66c3026a36191f8d3e154156f51ff25'
          }
        );
        console.log(test);
      })
    })

  }


}
