import {Injectable} from '@angular/core';
import {Mutation} from '../interfaces/generated/graphql';
import {ApolloService} from './apollo.service';

export enum HubspotDealStage {
  cart = 1,
  information = 2,
  payment = 3,
}

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  constructor(
    private apollo: ApolloService
  ) {
  }

  updateHubspotDeal(dealStageStep: HubspotDealStage) {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'updateHubspotDealMutation',
      variables: {
        dealStageStep
      }
    });
  }

}
