<div (click)="isPreviewVisible$.next(!isPreviewVisible$.value)" class="cart-small justify-content-end" [attr.data-rr-shared]="(cartSignal().cart?.itemsTotalQty || 0) <= 0">
  <div class="cart-title">
    <app-cart-icon/>
    <span class="num">{{ cartSignal().cart?.itemsTotalQty || '0' }}</span>
    @if (cartSignal().cart?.totalPrice) {
      <div class="cart-total">
        <span>{{ cartSignal().cart?.totalPrice | price | currency }}&nbsp;<span class="currency-c"> kr. </span></span>
      </div>
    } @else {
      <span class="cart-text hide-element-on-mobile">Kurv</span>
    }
  </div>
</div>
<app-cart-small-preview
  (showPreviewChange)="isPreviewVisible$.next($event)"
  *ngIf="(isPreviewVisibleObservable|async) && cartSignal().cart"
  [cart]="cartSignal().cart"
></app-cart-small-preview>
