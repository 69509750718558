import {
  RefinementListConnectorParams,
  RefinementListItem,
  RefinementListWidgetDescription
} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {noop} from 'instantsearch.js/es/lib/utils';
import {Component, computed, Input, signal, WritableSignal} from '@angular/core';
import {connectRefinementList} from 'instantsearch.js/es/connectors';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {parseNumberInput} from '@core/instantsearch/instantsearch-utils';

export const defaultSortBy = ['count:desc','name:asc'] as Array<'count:desc'|'name:asc'>;

@Component({
  selector: 'app-instantsearch-base-refinement-list',
  template: '<p>Should not be used directly</p>',
  standalone: true,
})
export class InstantSearchBaseRefinementListComponent extends TypedBaseWidgetComponent<
  RefinementListWidgetDescription,
  RefinementListConnectorParams
> {

  isHidden = computed(() => this.state().items.length === 0)


  @Input() public showMoreLabel: string = 'Show more';
  @Input() public showLessLabel: string = 'Show less';
  @Input() public searchable?: boolean;
  @Input() public searchPlaceholder: string = 'Search here...';

  // instance options
  @Input() public attribute: RefinementListConnectorParams['attribute'];
  @Input() public operator: RefinementListConnectorParams['operator'];
  @Input() public limit: RefinementListConnectorParams['limit'];
  @Input() public showMore: RefinementListConnectorParams['showMore'];
  @Input() public showMoreLimit: RefinementListConnectorParams['showMoreLimit'];
  @Input() public sortBy: RefinementListConnectorParams['sortBy'];
  @Input()
  public transformItems?: RefinementListConnectorParams['transformItems'];

  public override state: WritableSignal<RefinementListWidgetDescription['renderState']> = signal({
    canRefine: false,
    canToggleShowMore: false,
    createURL: () => '',
    isShowingMore: false,
    items: [],
    refine: noop,
    toggleShowMore: noop,
    searchForItems: noop,
    isFromSearch: false,
    hasExhaustiveItems: false,
    sendEvent: noop,
  });

  constructor(
    public instantSearchInstance: InstantSearchComponent
  ) {
    super('RefinementList');
  }

  public override ngOnInit() {
    this.createWidget(
      connectRefinementList,
      {
        showMore: this.showMore,
        limit: parseNumberInput(this.limit),
        showMoreLimit: parseNumberInput(this.showMoreLimit),
        attribute: this.attribute,
        operator: this.operator,
        sortBy: this.sortBy,
        escapeFacetValues: true,
        transformItems: this.transformItems,
      },
      {
        $$widgetType: 'ais.refinementList',
      }
    );

    super.ngOnInit();
  }

  public refine(event: MouseEvent, item: RefinementListItem) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state().canRefine) {
      // update UI directly, it will update the checkbox state
      item.isRefined = !item.isRefined;

      // refine through Algolia API
      this.state().refine(item.value);
    }
  }

}
