import {inject, PLATFORM_ID} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {map} from 'rxjs/operators';
import {isPlatformServer} from '@angular/common';
import {UserInitService} from '../services/user-init.service';


export const userGuard: CanActivateFn = () => {

  const userInitService = inject(UserInitService);
  const userService = inject(UserService);
  const router = inject(Router);
  const platformId = inject(PLATFORM_ID);

  // skip user guard in server side as we will not have user data
  if (isPlatformServer(platformId)) {
    return true;
  }

  return userInitService.onInitialized().pipe(
    map(() => {
      const user = userService.currentUser();
      if (user) {
        if (user.isEmailConfirmed) {
          return true;
        }
        userService.accountNotConfirmedModal();
      }
      // If no use, we redirect to frontend
      return router.createUrlTree(['/']);
    })
  )
}
