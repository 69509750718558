import {Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT, isPlatformServer} from '@angular/common';

export interface ScriptProps {
  type: string;
  src?: string;
  text?: string;
  async?: boolean
  id?: string
}

@Injectable({
  providedIn: 'root'
})
export class ScriptService {


  private renderer2: Renderer2;
  private addedScripts = [] as ScriptProps[]

  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }

  isScriptAdded(script: ScriptProps) {
    return this.addedScripts.findIndex(d => d.src === script.src) !== -1;
  }

  protected createScriptElement(script: ScriptProps): HTMLScriptElement {
    let scriptEl = this.renderer2.createElement('script') as HTMLScriptElement;

    scriptEl.type = script.type;
    scriptEl.async = script.async === undefined ? true : script.async;
    scriptEl.charset = 'utf-8'

    if (script.id) {
      scriptEl.id = script.id;
    }
    if (script.src) {
      scriptEl.src = script.src;
    }
    if (script.text) {
      scriptEl.text = script.text;
    }
    return scriptEl;
  }


  addScript(script: ScriptProps, onlyOnServer = false, addTo = 'body', force = false): Promise<'ok' | 'already-added' | 'only-on-server'> {
    return new Promise((resolve, reject) => {
      if (this.isScriptAdded(script) && !force) {
        return resolve('already-added');
      }
      if (onlyOnServer && !isPlatformServer(this.platformId)) {
        return resolve('only-on-server');
      }
      this.addedScripts.push(script)

      const scriptEl = this.createScriptElement(script);
      scriptEl.onload = () => resolve('ok')
      scriptEl.onerror = (e) => reject(e)

      if (addTo === 'head') {
        this.renderer2.appendChild(this.document.head, scriptEl);
      } else {
        this.renderer2.appendChild(this.document.body, scriptEl);
      }
    })
  }

  public removeScriptById(id: string, removeFrom = 'body'): void {
    const script = this.document.getElementById(id);
    if (!script) {
      return;
    }
    if (removeFrom === 'head') {
      this.renderer2.removeChild(this.document.head, script);
    } else {
      this.renderer2.removeChild(this.document.body, script);
    }
  }
}
