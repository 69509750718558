<div class="order-before d-flex flex-row flex-lg-column justify-content-center text-center" *ngIf="distance > 0">
  <div class="order-before-icon"></div>
  <div class="order-before-text">
    <div class="d-flex flex-row flex-lg-column">
      <p>Bestil inden</p>
      <p class="fw-semibold px-1">
        <span>{{hours}}</span>
        timer
        <span>{{minutes}}</span>
        min
      </p>
      <p>og vi sender i dag!</p>
    </div>
  </div>
</div>
