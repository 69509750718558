<ng-container *ngIf="pageInfo$|async as pageInfo; else loading">
  <ng-container *ngIf="pageInfo.type === 'STORYBLOK_PAGE' || pageInfo.type === 'NOT_FOUND'">
    <ng-template *ngIf="storyblok" [ndcDynamicInputs]="storyblok?.story?.content"
                 [ngComponentOutlet]="getStoryblokComponents(storyblok?.story?.content.component)">
    </ng-template>
  </ng-container>
  <app-product [pageInfo]="pageInfo" *ngIf="pageInfo.type === 'PRODUCT_PAGE'"></app-product>
  <app-search-page-dynamic [pageInfo]="pageInfo" *ngIf="pageInfo.type === 'LIST_PAGE'"></app-search-page-dynamic>
</ng-container>

<ng-template #loading>
  <app-loading-icon class="loading-fullscreen"></app-loading-icon>
</ng-template>
