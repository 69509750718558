<div
  *ngIf="!isHidden()"
  class="d-flex"
>
  <div (mouseenter)="keepMenu()" (mouseleave)="resetMenu()" class="col-lg-2 col-12 menu-level">
    @if (highlightLvl0Items()) {
      <app-instantsearch-hierarchical-menu-highlights
        [highlightGroup]="highlightLvl0Items()"
        (hovering)="onHighlightsHovering($event, 0)"
      />
    }
    <a class="menu-item" *ngFor="let item of items(); trackBy: tracker"
       data-rr-shared="true"
       [createURL]="state().createURL"
       [item]="item"
       [refine]="state().refine"
       [routerLink]="getItemPathname(item)"
       [additionalContext]="getAdditionalContext(item, 'type')"
       [appRouterContext]="'hierarchicalMenu'"
       app-instantsearch-hierarchical-menu-item
    >
    </a>
  </div>
  <div (mouseenter)="keepMenu()" (mouseleave)="resetMenu()" *ngIf="activeLvl0Item().count > 0"
       data-rr-shared="true"
       class="col-lg-2 menu-level">
    @if (highlightLvl1Items()) {
      <app-instantsearch-hierarchical-menu-highlights
        [highlightGroup]="highlightLvl1Items()"
        (hovering)="onHighlightsHovering($event, 1)"
      />
    }
    <a *ngFor="let child of activeLvl0Item().data; trackBy: tracker" class="menu-item"
       [createURL]="state().createURL"
       [item]="child"
       [lvl]="1"
       [refine]="state().refine"
       [queryParams]="getItemSearchParams(child)"
       [routerLink]="getItemPathname(child)"
       [additionalContext]="getAdditionalContext(child, 'country')"
       [appRouterContext]="'hierarchicalMenu'"
       app-instantsearch-hierarchical-menu-item
    >
    </a>
  </div>
  <div (mouseenter)="keepMenu()" (mouseleave)="resetMenu()" *ngIf="(activeLvl1Item().data?.length ?? 0) > 1"
       data-rr-shared="true"
       class="col-lg-2 menu-level">
    @if (highlightLvl2Items()) {
      <app-instantsearch-hierarchical-menu-highlights
        [highlightGroup]="highlightLvl2Items()"
        (hovering)="onHighlightsHovering($event, 2)"
      />
    }
    <a *ngFor="let grandChild of activeLvl1Item().data; trackBy: tracker" class="menu-item"
       [createURL]="state().createURL"
       [item]="grandChild"
       [lvl]="2"
       [refine]="state().refine"
       [queryParams]="getItemSearchParams(grandChild)"
       [routerLink]="getItemPathname(grandChild)"
       [additionalContext]="getAdditionalContext(grandChild, 'area')"
       [appRouterContext]="'hierarchicalMenu'"
       app-instantsearch-hierarchical-menu-item
    >
    </a>
  </div>
  <app-instantsearch-hierarchical-menu-last
    data-rr-shared="true"
    [class.d-none]="!isAllLevelsActive()"
    (mouseenter)="keepMenu()"
    (mouseleave)="resetMenu()"
    [path]="getFullPathLabel(activeLvl2Item())"
    class="results col-lg-6">
  </app-instantsearch-hierarchical-menu-last>
</div>
