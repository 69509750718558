import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  input,
  Input,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {StoryblokService} from '@core/services/storyblok.service';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';

import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {DynamicModule} from 'ng-dynamic-component';
import {NgComponentOutlet, NgForOf, NgIf} from '@angular/common';
import {StoryblokComponentService} from '@core/services/storyblok-component.service';
import {StoryblokDynamicInputService} from '@core/services/storyblok-dynamic-input.service';
import {GridService} from '@core/services/grid.service';
import {ReadMoreComponent} from '@layout/components/general/read-more/read-more.component';
import {StoryblokReadMoreComponent} from '@modules/storyblok/components/storyblok-read-more.component';

@Component({
  selector: 'app-storyblok-container',
  templateUrl: './storyblok-container.component.html',
  styleUrls: ['./storyblok-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgForOf, NgIf, StoryblokDirective, DynamicModule, NgComponentOutlet, ReadMoreComponent]
})
export class StoryblokContainerComponent extends StoryblokReadMoreComponent implements OnInit {
  components: any;

  background_color = input<string>();

  general_styling = input<any>();
  body = input<any[]>();
  _editable = input<any>();

  @Input()
  set elementId(value: any) {
    this.hostElementId = value;
  }

  constructor(
    private storyblok: StoryblokService,
    private storyblokStylingService: StoryblokStylingService,
    protected storyblokComponentService: StoryblokComponentService,
    private storyblokDynamicInputService: StoryblokDynamicInputService,
    gridService: GridService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    super(gridService, platformId);
    this.components = storyblokComponentService.getComponents();
  }

  @HostBinding('attr.id') hostElementId: any;
  @HostBinding('class') class: any;

  @HostBinding('style.background-color')
  get backgroundColor() {
    return this.storyblokDynamicInputService.replaceDynamicInputs(this.background_color() ?? '', true)();
  };

  ngOnInit() {
    this.class = this.storyblokStylingService.getClassList(this.general_styling());
  }

  get trackByFn() {
    return this.storyblok.trackByUID()
  }

}
