import {
  InfiniteHitsConnectorParams,
  InfiniteHitsWidgetDescription
} from 'instantsearch.js/es/connectors/infinite-hits/connectInfiniteHits';
import {
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {connectInfiniteHits} from 'instantsearch.js/es/connectors';
import {tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {Debounce} from '@core/decorators/debounce';
import {SendEventForHits} from 'instantsearch.js/es/lib/utils';
import {NgForOf, NgIf} from '@angular/common';
import {WineFestivalProductComponent} from '../wine-festival-product/wine-festival-product.component';
import {VfSecretKeyService} from '@core/services/vf-secret-key.service';
import {AlgoliaProduct} from '@core/interfaces/algolia-product';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {LoadingIconComponent} from '@layout/components/general/loading-icon/loading-icon.component';
import {ProductWidgetComponent} from '@layout/components/product/product-widget/product-widget.component';

@Component({
  selector: 'app-wine-festival-infinite-hits',
  templateUrl: './wine-festival-infinite-hits.component.html',
  styleUrls: ['./wine-festival-infinite-hits.component.scss'],
  standalone: true,
  imports: [
    LoadingIconComponent,
    NgForOf,
    NgIf,
    ProductWidgetComponent,
    WineFestivalProductComponent
  ]
})
export class WineFestivalInfiniteHitsComponent extends TypedBaseWidgetComponent<InfiniteHitsWidgetDescription<AlgoliaProduct>, InfiniteHitsConnectorParams<AlgoliaProduct>> {
  instantSearchChangeSubscription: Subscription;
  blockTriggerMore = false;
  rendering = true;
  query?: string;

  ready = computed(() => this.state()?.isFirstPage !== undefined);

  hits = computed(() => this.state()?.hits || []);

  @ViewChild('bottomElement', {static: false}) bottomElement: ElementRef<HTMLDivElement>;


  public override state: WritableSignal<{
    hits: AlgoliaProduct[];
    isLastPage: boolean;
    isFirstPage: boolean;
    showMore: () => void;
    showPrevious: () => void;
    results: any;
    sendEvent: SendEventForHits;
    bindEvent: () => string;
    currentPageHits: AlgoliaProduct[];
  } | undefined> = signal(undefined);

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    private changeDetectorRef: ChangeDetectorRef,
    private vfSecretKeyService: VfSecretKeyService,
  ) {
    super('InfiniteHits');

    this.createWidget(connectInfiniteHits as any, {});

    this.instantSearchChangeSubscription = this.instantSearchInstance.changeEvent.pipe(
      tap((data) => {
        const query = (data.results as any)?.query.toUpperCase();
        if (query === undefined || this.query === query) {
          return;
        }
        if ((data.results as any).hits < 20) {
          this.updateIsLastPage(true);
        }

        this.query = query;
        this.changeDetectorRef.detectChanges();

        const hasVfSecretKey = this.vfSecretKeyService.isVfSecretKeySet();
        const queryIsACompleteVfId = query.includes('VF') && query.length === 5;
        const product = this.state()?.hits?.find((hit) => hit.vf_id === query);

        if (hasVfSecretKey && queryIsACompleteVfId && product?.commerce_stock_bool) {
          const qtyInput = document.querySelector(`[data-vf-id="${query}"] input`) as HTMLInputElement;
          qtyInput?.focus();
          setTimeout(() => qtyInput?.select(), 5);
        }
      })
    ).subscribe();
  }

  updateIsLastPage(bool: boolean) {
    this.state.update(d => {
      if (!d) {
        return;
      }
      d.isLastPage = bool;
      return d;
    })
  }

  @HostListener('window:scroll', ['$event'])
  @Debounce(30)
  scrollEvent() {
    if (this.blockTriggerMore || !this.bottomElement) {
      return;
    }

    const bottomElement = this.bottomElement.nativeElement;

    if (this.elementInView(bottomElement)) {
      this.updateIsLastPage(false);
      this.triggerShowMore();
      // Prevent finding many pages in a row
      this.blockTriggerMore = true;
      setTimeout(() => {
        this.blockTriggerMore = false;
      }, 200);
    }
    this.detectChanges();
  }

  trackByFn(index: any, item: any) {
    return item.objectID;
  }

  triggerShowMore() {
    if (this.rendering || this.state()?.isLastPage) {
      return;
    }
    this.updateIsLastPage(true);
    this.rendering = true;
    this.state()?.showMore();
  }

  elementInView(element: HTMLElement): boolean {
    const position = element.getBoundingClientRect();
    if (position.height === 0 && position.width === 0) {
      return false;
    }

    const triggerBeforeElementInView = 300;

    return (
      position.top >= 0 &&
      position.bottom <= (window.innerHeight || document.documentElement.clientHeight) + triggerBeforeElementInView
    );
  }

  protected detectChanges() {
    this.changeDetectorRef.detectChanges();
    this.rendering = false;
  }

}
