import {Component, computed, HostBinding, Inject, input, OnInit, PLATFORM_ID, signal} from '@angular/core';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {DynamicModule} from 'ng-dynamic-component';
import {isPlatformServer, NgComponentOutlet, NgForOf, NgIf} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {StoryblokDynamicInputService} from '@core/services/storyblok-dynamic-input.service';
import {interval} from 'rxjs';
import dayjs from 'dayjs';
import {toSignal} from '@angular/core/rxjs-interop';


@Component({
  selector: 'app-countdown',
  templateUrl: './storyblok-countdown.component.html',
  styleUrls: ['./storyblok-countdown.component.scss'],
  standalone: true,
  imports: [StoryblokDirective, DynamicModule, NgForOf, NgIf, NgComponentOutlet]
})
export class StoryblokCountdownComponent extends StoryblokBaseComponent implements OnInit {

  _editable = input<any>();
  general_styling = input<any>();
  countdown_datetime = input<string>();
  countdown_dynamic_value = input<string>();
  title = input<string>();
  color_theme = input<{ value: string }>();
  show_days = input<boolean>(false);
  font_weight = input<number>(300);

  dateInput = this.getDateInput()

  date = this.getCountdown();
  interval = isPlatformServer(this.platformId) ? signal(0) : toSignal(interval(1000));

  showDaysElement = computed(() => this.date().days > 0 && this.show_days());
  dateHours = computed(() => {
    if (this.show_days()) {
      return this.date().hours
    }
    return this.date().hours + this.date().days * 24;
  });

  @HostBinding('class') class: any;
  @HostBinding('style.color') color: any;

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    private storyblokDynamicInputService: StoryblokDynamicInputService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    super();
  }

  ngOnInit() {
    this.class = computed(() => {
      return this.storyblokStylingService.getClassList(this.general_styling(), {
        desktop: {'margin-bottom': '20px', 'margin-top': '16px'},
      }, {'col-': 12});
    })();
    this.color = this.color_theme()?.value;
  }

  getCountdown() {
    return computed(() => {
      this.interval();
      const dateObject = dayjs(this.dateInput());
      if (!dateObject.isValid() || dateObject.isBefore(dayjs())) {
        return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        }
      }
      return {
        days: dateObject.diff(dayjs(), 'days'),
        hours: dateObject.diff(dayjs(), 'hours') % 24,
        minutes: dateObject.diff(dayjs(), 'minutes') % 60,
        seconds: dateObject.diff(dayjs(), 'seconds') % 60,
      }
    });
  }

  getDateInput() {
    return computed(() => {
      if (this.countdown_dynamic_value()) {
        const date = this.storyblokDynamicInputService.replaceDynamicInputs(this.countdown_dynamic_value() ?? '')();
        if (date !== this.countdown_dynamic_value()) {
          return date;
        }
      }
      if (this.countdown_datetime()) {
        return this.countdown_datetime();
      }
      return '';
    })
  }
}
