<div
  data-rr-shared="true"
  [appStoryblok]="_editable"
  [backgroundColor]="read_more_background_color()?.value ?? '#ffffff'"
  [maxBodyHeight]="maxHeight()"
  [showFadeOut]="read_more_show_fade_out()"
  [useReadMore]="read_more_active()"
  app-read-more
  eventSourceName="storyblok-card"
  class="row">
  <ng-container *ngFor="let blok of body; trackBy: trackByFn">
    <ng-template *ngIf="components" [ndcDynamicInputs]="blok"
                 [ngComponentOutlet]="components[blok.component]"></ng-template>
  </ng-container>
</div>
