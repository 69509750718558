import {Component, Inject, OnDestroy, OnInit, Optional, signal, WritableSignal} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {
  Connector,
  Renderer,
  UnknownWidgetParams,
  Unmounter,
  Widget,
  WidgetDescription,
} from 'instantsearch.js/es/types';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {bem} from '@core/instantsearch/instantsearch-utils';
import {noop} from 'instantsearch.js/es/lib/utils';

type AdditionalWidgetProperties = Partial<Widget<WidgetDescription>>;

@Component({
  selector: 'app-typed-base-widget',
  template: ''
})
export abstract class TypedBaseWidgetComponent<
  TWidgetDescription extends WidgetDescription,
  TConnectorParams extends UnknownWidgetParams
> implements OnInit, OnDestroy {

  public widget?: Widget;
  public state: WritableSignal<TWidgetDescription['renderState'] | undefined> = signal(undefined);
  public cx: ReturnType<typeof bem>;
  public abstract instantSearchInstance: InstantSearchComponent;

  protected constructor(@Inject('should-not-be-injected') @Optional() widgetName: string) {
    this.cx = bem(widgetName);
  }

  get parent() {
    return this.instantSearchInstance;
  }

  public createWidget(
    connector: Connector<TWidgetDescription, TConnectorParams>,
    options: TConnectorParams,
    additionalWidgetProperties: AdditionalWidgetProperties = {}
  ) {
    this.widget = {
      ...connector(this.updateState, noop as Unmounter)(options),
      ...additionalWidgetProperties,
    };
  }

  public ngOnInit() {
    if (!this.widget) {
      return;
    }
    this.parent.addWidgets([this.widget]);
  }

  public ngOnDestroy() {
    if (!this.widget) {
      return;
    }
    if (isPlatformBrowser(this.instantSearchInstance.platformId)) {
      this.parent.removeWidgets([this.widget]);
    }
  }

  public updateState: Renderer<
    TWidgetDescription['renderState'],
    TConnectorParams
  > = (state, isFirstRendering) => {
    this.state.set(state)
  };

  /**
   * Helper to generate class names for an item
   * @param item element to generate a class name for
   */
  public getItemClass(item: { isRefined?: boolean }): string {
    const className = this.cx('item');

    if (item.isRefined) {
      return `${className} ${this.cx('item', 'selected')}`;
    }

    return className;
  }
}
