import {
  afterRender,
  AfterRenderPhase,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  input,
  Input,
  Output,
  PLATFORM_ID,
  Signal,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {GridService} from '@core/services/grid.service';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {RefinementListComponent} from '../refinement-list/refinement-list.component';
import {RangeInputComponent} from '../range-input/range-input.component';
import {AsyncPipe, isPlatformBrowser, NgClass, NgIf} from '@angular/common';
import {AlgoliaService} from '@core/services/algolia.service';
import {RatingFilterComponent} from '../rating-filter/rating-filter.component';
import {AlgoliaFacetFilter} from '@core/types/algolia-filter';
import {
  RangeConnectorParams,
  RangeRenderState as RatingRenderState,
  RangeWidgetDescription,
  ReviewerFilter
} from '@core/instantsearch-connectors/connectRatingReviewerFilter';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {defaultSortBy} from '@core/instantsearch/components/instantsearch-base-refinement-list.component';
import {
  InstantSearchBaseRangeInputComponent
} from '@core/instantsearch/components/instantsearch-base-range-input.component';
import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {RefinementListRenderState} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import {RangeRenderState} from 'instantsearch.js/es/connectors/range/connectRange';
import {
  RangeSliderComponent
} from '@modules/page/components/search-page/components/range-slider/range-slider.component';

interface FilterSelected {
  nr: number;
  labels: string[];
}

@Component({
  selector: 'div[app-top-filter-facet]',
  templateUrl: './top-filter-facet.component.html',
  styleUrls: ['./top-filter-facet.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, FontAwesomeModule, RefinementListComponent, RangeInputComponent, RatingFilterComponent, AsyncPipe, RangeSliderComponent]
})
export class TopFilterFacetComponent {

  sortBy = defaultSortBy;

  public _isSelected = signal(false);
  private _rangeInput: InstantSearchBaseRangeInputComponent | undefined;
  private _ratingFilterInput: TypedBaseWidgetComponent<RangeWidgetDescription, RangeConnectorParams> | undefined;
  public ratingsMin: number = 80;
  public ratingsMax: number = 100;
  public hasLoadedHits = input(false);
  public hideExtraFilters = input.required<boolean>();
  public isHidden = computed(() => {
    return this.hideExtraFilters() && this.offsetTop() > 20 && this.isDesktop();
  })
  @HostBinding('class.display-none') displayNone: boolean = false;
  public selected: WritableSignal<FilterSelected> = signal({nr: 0, labels: []});
  public offsetTop = signal(0);
  showFilterSubTitle = computed(() => isPlatformBrowser(this.platformId) && !this.isDesktop());
  public shouldFacetBeVisible: Signal<boolean> = computed(() => {
    if (!this.hasLoadedHits()) {
      return true;
    }
    return this.getShouldFacetBeVisible(this.selected(), this._ratingFilterInput?.state(), this.refinementList?.state())
  });

  get refinementList(): RefinementListComponent | undefined {
    return this._refinementList;
  }

  @ViewChild('ratingFilterInput')
  set ratingFilterInput(value: TypedBaseWidgetComponent<RangeWidgetDescription, RangeConnectorParams>) {
    this._ratingFilterInput = value;
  }

  @ViewChild('rangeInput')
  set rangeInput(value: InstantSearchBaseRangeInputComponent) {
    this._rangeInput = value;
  }

  @ViewChild('refinementList')
  set refinementList(value: RefinementListComponent) {
    this._refinementList = value;
  }

  @Output() selectChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() hideFilter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() attribute: AlgoliaFacetFilter;

  @Input()
  set isSelected(value: boolean) {
    this._isSelected.set(value);
  }
  private _refinementList: RefinementListComponent | undefined;
  private lastRange = {min: 0, max: 0};

  @HostBinding('class.hidden') hidden: boolean = false;

  constructor(
    public gridService: GridService,
    private elRef: ElementRef,
    private algoliaService: AlgoliaService,
    @Inject(PLATFORM_ID)
    private platformId: object,
    @Inject(forwardRef(() => InstantSearchComponent))
    private instantSearchInstance: InstantSearchComponent,
  ) {
    effect(() => {
      if (this.isRangeInput() && this._rangeInput) {
        this.checkRangeInput(this._rangeInput.state());
      }
      if (this.isRefinementList() && this.refinementList) {
        this.checkRefinementList(this.refinementList.state())
      }
    }, {allowSignalWrites: true});

    effect(() => {
      const hide = this.isHidden();
      if (this.hidden !== hide) {
        this.hidden = hide;
        this.hideFilter.emit(hide);
      }
    }, {allowSignalWrites: true});

    effect(() => {
      this.displayNone = !this.shouldFacetBeVisible();
    });

    afterRender(() => {
      this.offsetTop.set(this.elRef.nativeElement.offsetTop);
    }, {phase: AfterRenderPhase.Read})
  }

  get isDesktop() {
    return this.gridService.isMediumSignal;
  }

  @HostListener('mouseleave', ['$event'])
  mouseleave(event: any) {
    this.mouseAction(false);
  }

  updateSelectedRange(event: { min: number, max: number }) {
    if (!this.isRangeInput() && !this.isRangeSlider()) {
      return;
    }
    const valid = this.lastRange.min !== event.min || this.lastRange.max !== event.max;
    if (!valid) {
      return;
    }
    let number = 0;
    const labels: any = [];

    if (event.min) {
      labels.push(`≥${event.min}`);
      number += 1;
    }

    if (event.max) {
      labels.push(`≤${event.max}`);
      number += 1;
    }
    this.selected.set({
      labels,
      nr: number
    });
    this.lastRange = event;
  }

  checkRangeInput(rangeInputState: RangeRenderState) {
    if (rangeInputState?.start) {
      const min: any = rangeInputState.start[0] !== -Infinity ? rangeInputState.start[0] : 0;
      const max: any = rangeInputState.start[1] !== Infinity ? rangeInputState.start[1] : 0;
      this.updateSelectedRange({min, max});
    }
  }

  checkRatingFilter(ratingFilterState: RatingRenderState|undefined, selected: FilterSelected) {
    return ratingFilterState?.canRefine || selected['nr'] !== 0;
  }

  refinementListShouldBeVisible(refinementListState: RefinementListRenderState | undefined) {
    const refinementListItemCount = refinementListState?.items?.length || 0;
    if (!refinementListState || refinementListItemCount <= 0) {
      return false;
    }

    const key = Object.keys(this.instantSearchInstance.instantSearchInstance.renderState)[0];
    const totalHits = this.instantSearchInstance.instantSearchInstance.renderState[key].stats?.nbHits || 0
    const isRefined = refinementListState.items[0].isRefined;
    const filterOptionCount = refinementListState.items[0].count as any as string;
    const filterOptionHits = parseInt(filterOptionCount.replace('(', '').replace(')', ''), 10)
    if (refinementListItemCount === 1 && !isRefined && filterOptionHits === totalHits) {
      return false;
    }
    return true;
  }

  checkRefinementList(refinementListState: RefinementListRenderState) {
    if (!this.refinementListShouldBeVisible(refinementListState)) {
      return;
    }

    let nr = 0;
    const labels: string[] = [];

    refinementListState.items?.forEach(item => {
      if (item.isRefined) {
        nr += 1;
        labels.push(this.replaceText(item.label));
      }
    });
    this.selected.set({nr, labels})
  }

  getShouldFacetBeVisible(selected: FilterSelected, ratingState: RatingRenderState|undefined, refinementListState: RefinementListRenderState | undefined) {
    if (this.isRangeInput()) {
      return true;
    }
    if (this.isRangeSlider()) {
      return true;
    }
    if (this.isRatingFilter()) {
      return this.checkRatingFilter(ratingState, selected);
    }
    if (this.isRefinementList()) {
      return this.refinementListShouldBeVisible(refinementListState);
    }
    return false;
  }

  replaceText(item: string) {
    return this.algoliaService.getLabel(item);
  }

  transformItems = (items: any[]) => {
    return items.map(item => ({
      ...item,
      count: `(${item.count})`,
      highlighted: this.replaceText(item.label) ?? item.highlighted
    }));
  }

  isRangeSlider() {
    return this.attribute.instantSearchType === 'range-slider';
  }

  isRangeInput() {
    return this.attribute.instantSearchType === 'range-input';
  }

  isRatingFilter() {
    return this.attribute.instantSearchType === 'ratings-filter';
  }

  isRefinementList() {
    return this.attribute.instantSearchType === 'refinement-list';
  }

  @HostListener('mouseenter', ['$event'])
  mouseenter(event: any) {
    this.mouseAction(true);
  }

  setHiddenState() {
    if (this.isDesktop()) {
      return;
    }
    this.selectChange.emit();
  }

  mouseAction(value: boolean) {
    if (this.isDesktop()) {
      this._isSelected.set(value);

      if (!this.refinementList) {
        return;
      }
    }
  }

  protected getSelectedByRatingChangeEvent(event: ReviewerFilter) {
    const labels: string[] = [];
    let number = 0;

    if (event.reviewers.length === 0 && event.range.min === this.ratingsMin && event.range.max === this.ratingsMax) {
      return {labels, nr: number};
    }

    if (event.range.min !== this.ratingsMin) {
      labels.push(`≥${event.range.min}`);
      number += 1;
    }
    if (event.range.max !== this.ratingsMax) {
      labels.push(`≤${event.range.max}`);
      number += 1;
    }

    if (event.reviewers.length === 0) {
      labels.push('Alle anmeldere');
    } else if (event.reviewers.length === 1) {
      labels.push(`${event.reviewers.length} Anmelder`);
      number += event.reviewers.length;
    } else {
      labels.push(`${event.reviewers.length} Anmeldere`);
      number += event.reviewers.length;
    }
    return {labels, nr: number};
  }

  onRatingFilterChange(event: ReviewerFilter) {
    this.selected.set(this.getSelectedByRatingChangeEvent(event));
  }

  protected readonly faAngleDown = faAngleDown;
  protected readonly faAngleUp = faAngleUp;
}
