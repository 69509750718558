import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {StoryblokStylingService} from '../../../../../core/services/storyblok-styling.service';
import {
  SearchPageTitleComponent
} from '../../../page/components/search-page/components/search-page-title/search-page-title.component';
import {StoryblokBaseComponent} from '../storyblok-base.component';

@Component({
  selector: 'app-storyblok-image-title',
  templateUrl: './storyblok-image-title.component.html',
  styleUrls: ['./storyblok-image-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchPageTitleComponent]
})
export class StoryblokImageTitleComponent extends StoryblokBaseComponent implements OnInit {

  @Input() title: string;
  @Input() general_styling: any;

  @HostBinding('class') class: any;

  constructor(
    private storyblokStylingService: StoryblokStylingService,
  ) {
    super()
  }

  ngOnInit(): void {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12}, this.col);
  }

}
