import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../../environments/environment';

@Pipe({
  name: 'replaceImg',
  standalone: true
})
export class ReplaceImgPipe implements PipeTransform {

  transform(value: string | undefined | null, ...args: unknown[]): string | undefined {
    if (!value) {
      return undefined;
    }
    const url = environment.supervin.assets.slice(0, -1);
    return value.replace(/(<img.*src=")(\/sites\/default)/g, `$1${url}/sites/default`);
  }

}
