import {Inject, Injectable, NgZone, PLATFORM_ID, signal} from '@angular/core';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {RoutingService} from '@core/services/routing.service';
import {UnsubscribeService} from '@core/services/unsubscribe.service';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {fromEvent} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollPosService {

  isRestored = signal(false);

  protected restoreRequested = false;

  constructor(
    protected routingService: RoutingService,
    protected unsubscribeService: UnsubscribeService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected ngZone: NgZone
  ) {
    this.routingService.onRouteChange(true).pipe(
      takeUntil(this.unsubscribeService.whileApplicationAlive()),
      tap(route => {
        this.isRestored.set(false);
      })
    ).subscribe()

    if (isPlatformBrowser(this.platformId)) {
      this.ngZone.runOutsideAngular(() => {
        window.addEventListener('load', () => {
          if (this.restoreRequested) {
            this.restoreScrollPosition();
          }
        })
        fromEvent(window.document, 'scroll').pipe(
          takeUntil(this.unsubscribeService.whileApplicationAlive()),
          debounceTime(300),
          tap(() => {
            this.saveScroll();
          })
        ).subscribe()
      })

    }
  }
  saveScroll() {
    const state = {...window.history.state, scrollY: window.scrollY};
    window.history.replaceState(state, document.title);
  }

  restoreScrollPosition(force: boolean = false) {
    const isRestored = force ? false : this.isRestored()
    if (isRestored || isPlatformServer(this.platformId)) {
      return;
    }
    if (document.readyState !== 'complete') {
      this.restoreRequested = true;
      return;
    }

    const frag = location.href.split('#')[1];

    const scrollY = history.state?.scrollY;
    if (scrollY) {
      window.scrollTo({
        left: 0,
        top: scrollY,
        behavior: 'instant'
      })
    } else if (frag && !frag.includes('=')) {
      const element = document.querySelector(`#${frag}`);
      element?.scrollIntoView({
        behavior: 'instant'
      });
    } else {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'instant'
      })
    }
    setTimeout(() => {
      this.isRestored.set(true);
    }, 100)

  }

}
