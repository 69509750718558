<app-carousel #carousel *ngIf="images()" [dataSource]="images()"
              [inputs]="carouselTile" [uniqueToken]="'p-img-thumb-' + (uniqueToken() || 'empty')"
              [trackBy]="trackBy()">
  <ngu-tile *nguCarouselDef="let item">
    <div (click)="onClick(item)" [class.active-image]="activeImageId() === item.id" class="thumbnail">
      <img [loaderParams]="{height: 500}"
           [ngSrc]="item?.small || ''" fill
           draggable="false"
           loading="lazy">
    </div>
  </ngu-tile>
  <button *ngIf="showNavigationButtons()" NguCarouselPrev [class.disabled]="carousel.isFirst"
          class="carousel-navigator carousel-left">
    <img
      *ngIf="this.storyblokService.getImageObject({filename: 'https://a.storyblok.com/f/106593/22x39/f3750f9d89/arrow-left.png'}, 22) as imageObj"
      [height]="imageObj.imgHeight" [ngSrc]="imageObj.optimizedUrl" [width]="imageObj.imgWidth">
  </button>
  <button *ngIf="showNavigationButtons()" NguCarouselNext [class.disabled]="carousel.isLast"
          class="carousel-navigator carousel-right">
    <img
      *ngIf="this.storyblokService.getImageObject({filename: 'https://a.storyblok.com/f/106593/22x39/f698c62f31/arrow-right.png'}, 22) as imageObj"
      [height]="imageObj.imgHeight" [ngSrc]="imageObj.optimizedUrl" [width]="imageObj.imgWidth">
  </button>
</app-carousel>
