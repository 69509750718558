import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {StoryblokService} from '@core/services/storyblok.service';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {NgForOf, NgIf} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {
  SpotObj,
  StoryblokSpotComponent
} from '@modules/storyblok/components/storyblok-spots/storyblok-spot/storyblok-spot.component';

@Component({
  selector: 'app-storyblok-spots',
  templateUrl: './storyblok-spots.component.html',
  styleUrls: ['./storyblok-spots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, StoryblokSpotComponent, NgForOf, NgIf]
})
export class StoryblokSpotsComponent extends StoryblokBaseComponent implements OnInit {

  spots: { spot: SpotObj, col: string, originalObj: object, _editable: any }[] = [];

  @Input()
  set body(body: any) {
    this.spots = body.map((d: any) => {
      return {
        spot: this.getSpot(d),
        col: this.getSpotCol(d),
        originalObj: d
      }
    });
  }

  @Input() _editable: any;
  @Input() general_styling: any;

  @Input()
  set elementId(value: any) {
    this.hostElementId = value
  }

  @HostBinding('attr.id') hostElementId: any

  @HostBinding('class') class: any;

  constructor(
    private storyblokService: StoryblokService,
    private storyblokStylingService: StoryblokStylingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12}, this.col);
  }

  getSpotCol(spot: any) {
    return this.storyblokService.getCols(spot.col);
  }

  protected getImageSize(spot: any): { imgHeight: number | undefined, imgWidth: number | undefined } {
    const filename = spot.backgroundImage.filename as string;
    return this.storyblokService.getImageSize(filename);
  }

  getSpot(spot: any): SpotObj {
    const {imgWidth, imgHeight} = this.getImageSize(spot);

    return {
      title: spot.title,
      description: spot.description,
      ctaText: spot.ctaText,
      ctaLink: spot.ctaLink.cached_url,
      ctaDesign: spot.ctaDesign,
      backgroundImage: spot.backgroundImage?.filename,
      height: spot.height,
      imgHeight,
      imgWidth
    };
  }

  get trackByFn() {
    return (index: any, item: any) => {
      return item.originalObj._uid;
    };
  }

}
