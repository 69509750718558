<div class="spot-wrapper">
  <img [height]="spot.imgHeight" [ngSrc]="spot.backgroundImage || ''" [ngStyle]="{'height.px': spot?.height}"
       [width]="spot.imgWidth">
  <div class="spot-content" [class.no-text-cta]="!spot.ctaText && spot.ctaLink">
    <h2 *ngIf="spot?.title">{{spot?.title}}</h2>
    <p *ngIf="spot?.description">{{spot.description}}</p>
    <a *ngIf="spot.ctaLink && !url?.isRelative" [class.purple]="spot.ctaDesign === 'purple'" [href]="url?.fullURL"
       class="btn"
       [class.transparent]="spot.ctaDesign === 'transparent'">{{spot.ctaText}}</a>
    <a *ngIf="spot.ctaLink && url?.isRelative" [class.purple]="spot.ctaDesign === 'purple'" [fragment]="url?.hash"
       [appRouterContext]="'spot'" [queryParams]="url?.search" [routerLink]="url?.pathname" class="btn"
       [class.transparent]="spot.ctaDesign === 'transparent'">{{spot.ctaText}}</a>
  </div>
</div>
