import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, Output} from '@angular/core';
import {CountdownService} from '@core/services/countdown.service';
import {NgIf} from '@angular/common';


@Component({
  selector: 'app-countdown-live',
  templateUrl: './countdown-live.component.html',
  styleUrls: ['./countdown-live.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class CountdownLiveComponent implements OnDestroy {
  get productId(): string | undefined {
    return this._productId;
  }

  @Input()
  set productId(value: any) {
    if (this._productId !== value && this._productId) {
      this.countdownService.removeCountdown(this.countdownKey);
    }
    this._productId = value;
    this.addCountdown();
  }

  private _date: Date | undefined;

  get date(): Date | undefined {
    return this._date;
  }

  @Input()
  set date(value: Date | undefined) {
    this._date = value;
    this.addCountdown();
  }

  @Output() toggleVisibility = new EventEmitter<boolean>();

  private _productId: string;

  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  distance: number;
  _countdownKey: string;

  protected wasVisible = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private countdownService: CountdownService,
    private ngZone: NgZone
  ) {
  }

  addCountdown() {
    if (!this.productId) {
      return;
    }
    const date = this.date;
    if (!date) {
      this.toggleVisibility.emit(false);
      this.countdownService.removeCountdown(this.countdownKey);
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      this.countdownService.addCountdown(this.countdownKey, {
        target: date,
        callback: result => {
          this.distance = result.distance;
          this.days = result.days;
          this.hours = result.hours;
          this.minutes = result.minutes;
          this.seconds = result.seconds;

          const isVisible = this.isVisible;

          if (isVisible) {
            this.changeDetector.detectChanges();
          }
          if (this.wasVisible !== isVisible) {
            this.toggleVisibility.emit(isVisible);
          }
          this.wasVisible = isVisible;
        }
      });
    })
  }

  get isVisible() {
    return this.distance > 0 && this.days < 7;
  }

  get countdownKey() {
    if (!this._countdownKey && this.productId) {
      this._countdownKey = this.countdownService.getKey(this.productId);
    }
    return this._countdownKey;
  }

  ngOnDestroy(): void {
    this.countdownService.removeCountdown(this.countdownKey);
  }
}
