import {Injectable} from '@angular/core';
import {ScriptService} from './script.service';
import {Mutation} from '../interfaces/generated/graphql';
import {ApolloService} from './apollo.service';

@Injectable({
  providedIn: 'root'
})
export class HsFormsService {

  constructor(
    private scriptService: ScriptService,
    private apollo: ApolloService
  ) {
  }

  async addForm(portalId: string, formId: string, target: string) {
    await this.scriptService.addScript({
      type: 'text/javascript',
      src: '//js.hsforms.net/forms/v2.js'
    })
    this.hbspt.forms.create({
      portalId,
      formId,
      target
    });
  }

  get hbspt() {
    return (window as any).hbspt;
  }

  submitHubspotFormEvent(formName: string, data: string) {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'hubspotFormEventMutation',
      variables: {
        formName,
        data
      }
    });
  }

}
