<ng-container *ngFor="let item of currentFilters(); index as i; trackBy: trackBy">
  <span (click)="removeRefinement($event, item)" *ngIf="shouldShowFilter(i, showAll())">
    {{getLabel(item.facetLabel, item.label)}}
  </span>
</ng-container>
<button (click)="toggleShowAll()" *ngIf="shouldShowAllBtn()" class="btn-filter-outlined me-2">
  {{showAll() ? 'Vis færre' : 'Vis alle'}}
</button>
<app-clear-refinements
  class="d-inline-block"
  [includedAttributes]="getFilterAttributes()"
  [resetLabel]="'Ryd filtrering'">
</app-clear-refinements>
