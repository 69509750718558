import {Component, EventEmitter, Inject, Input, Output, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser, NgClass, NgIf} from '@angular/common';
import {NouisliderComponent} from 'ng2-nouislider';
import {FormsModule} from '@angular/forms';


@Component({
  selector: 'app-rating-points-range',
  templateUrl: './rating-points-range.component.html',
  styleUrls: ['./rating-points-range.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NouisliderComponent, FormsModule]
})
export class RatingPointsRangeComponent {

  @Input() min: number;
  @Input() max: number;
  @Input() range: number[];

  @Output() rangeChange: EventEmitter<number[]> = new EventEmitter();

  get config() {
    return {
      connect: true,
      start: this.range,
      step: 1,
      tooltips: true,
      format: {
        from: (value: number) => Math.round(value),
        to: (value: number) => Math.round(value),
      },
      range: {
        min: this.min,
        max: this.max
      },
      pips: {
        mode: 'count',
        density: 5,
        values: 3,
        stepped: true
      }
    };
  }

  protected readonly isPlatformBrowser = isPlatformBrowser;

  public handleChange = (values: number[]) => {
    this.rangeChange.emit(values);
  };

  constructor(
    @Inject(PLATFORM_ID) public platformId: object
  ) {
  }
}
