<div
  [class]="cx()"
  data-rr-shared="true"
  *ngIf="!isHidden() && !hideList"
>
  <ul [class]="cx('list')">
    <li
      [class]="getItemClass(item)"
      *ngFor="let item of state().items; trackBy: trackBy"
      (click)="clickRefinement($event, item)"
    >
      <label [class]="cx('label')">
        <input
          [class]="cx('checkbox')"
          type="checkbox"
          value="{{item.value}}"
          [checked]="item.isRefined"
        />
        <span [class]="cx('labelText')">
          <img *ngIf="item.label.indexOf('icon-') !== -1" [height]="20" [ngSrc]="this.getIcon(item.label)" [width]="20"
               alt="icon">
          {{this.getLabel(item.highlighted)}}
        </span>
        <span [class]="cx('count')">{{item.count}}</span>
      </label>
    </li>
  </ul>
  <button
    [class]="cx('showMore')"
    *ngIf="showMore"
    (click)="clickShowMore()"
    [disabled]="!state().canToggleShowMore"
  >
    {{state().isShowingMore ? showLessLabel : showMoreLabel}}
  </button>
</div>
