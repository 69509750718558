import {Injectable, signal, WritableSignal} from '@angular/core';
import {ApolloService} from '@core/services/apollo.service';
import {UserService} from '@core/services/user.service';
import {CustomerReview, Maybe, WineProduct} from '@core/interfaces/generated/graphql';
import {TrackEventService} from '@core/services/track-event.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class CustomerReviewsAndWishlistService {

  customerReviews: WritableSignal<Maybe<CustomerReview>[]> = signal([]);
  wishlist: WritableSignal<WineProduct[]> = signal([]);
  currentUserId: WritableSignal<string | null> = signal(null);

  constructor(
    private apolloService: ApolloService,
    private userService: UserService,
    private trackEventService: TrackEventService,
    private modalService: NgbModal,
  ) {
    this.userService.getUser().pipe().subscribe(user => {
      if (this.currentUserId() === user?.id) {
        return;
      }

      this.currentUserId.set(user?.id ?? null);
      if (user) {
        this.customerReviews.set(user.reviews ?? []);
        this.wishlist.set((user.wishlist?.filter(Boolean) as WineProduct[]) ?? []);
      }
    });
  }

  addReviewProduct(product: WineProduct, rating: number, removeReview: boolean = false) {
    const oldReview = this.customerReviews().find(review => review?.product?.productId === product.id);
    this.updateCustomerReviews(product, rating, removeReview);
    return this.apolloService.mutatePromise({
      queryName: 'adjustCustomerReviewMutation',
      variables: {
        productId: parseInt(product.id, 10),
        rating,
        removeReview
      }
    }).catch((error) => {
      this.updateCustomerReviews(product, oldReview?.rating ?? 0, removeReview);

      const graphQLError = error.graphQLErrors[0];
      if (graphQLError?.debugMessage === 'UNAUTHENTICATED') {
        this.openLoginModal('For at tilføje en rating, skal du være logget ind på din Supervin-konto.');
      }
    })
  }

  adjustWishlist(product: WineProduct, removeFromWishlist: boolean) {
    this.updateWishlist(product, removeFromWishlist);
    return this.apolloService.mutatePromise({
      queryName: 'adjustWishlistMutation',
      variables: {
        productId: parseInt(product.id, 10),
        removeFromWishlist
      }
    }).catch((error) => {
      this.updateWishlist(product, !removeFromWishlist);

      const graphQLError = error.graphQLErrors[0];
      if (graphQLError?.debugMessage === 'UNAUTHENTICATED') {
        this.openLoginModal('For at lave en smageliste, skal du være logget ind på din Supervin-konto.');
      }
    })
  }

  private openLoginModal(message: string) {
    this.trackEventService.trackClickEvent('openLoginFromRateAndLikeClick');
    import('../../content/layout/components/modals/login-modal/login-modal.component').then(comp => {
      const modalRef = this.modalService.open(comp.LoginModalComponent);
      modalRef.componentInstance.message.set(message);
    })
  }

  private updateCustomerReviews(product: WineProduct, rating: number, removeReview: boolean) {
    this.customerReviews.update((reviews) => {
      const reviewIndex = reviews.findIndex(review => review?.product?.id === product.id);
      if (removeReview) {
        if (reviewIndex !== -1) {
          reviews.splice(reviewIndex, 1);
        }
        return [...reviews];
      }
      if (reviewIndex === -1) {
        reviews.push({rating, product: product as WineProduct});
      } else {
        reviews[reviewIndex] = {rating, product: product};
      }
      return [...reviews];
    });
  }

  private updateWishlist(product: WineProduct, removeFromWishlist: boolean) {
    this.wishlist.update((wishlist) => {
      if (removeFromWishlist) {
        return wishlist.filter(wishlistProduct => wishlistProduct?.id !== product.id);
      }
      return [...wishlist, product];
    });
  }

}
