import {Injectable} from '@angular/core';
import {captureException} from '@sentry/angular-ivy';
import errorMessageMap from './error-message-map';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  protected formatErrorMessage(message: string, context: Record<string, string>): string {
    let mappedMessage = errorMessageMap[message];

    if (!mappedMessage) {
      return message;
    }

    for (const contextKey in context) {
      mappedMessage = mappedMessage.replace(`%${contextKey}%`, context[contextKey]);
    }
    return mappedMessage;
  }

  public getErrorMessageFromGraphqlError(error: any): { message: string, context: Record<string, string> } | undefined {
    const isApollo = error.isApolloError;

    if (!isApollo) {
      return undefined;
    }

    const graphqlError = error?.graphQLErrors?.[0];
    const debugMessage = graphqlError?.debugMessage || graphqlError?.extensions?.debugMessage;

    if (!debugMessage) {
      return undefined;
    }

    const context = graphqlError.context || {};

    return {
      context,
      message: debugMessage
    }
  }


  public getErrorMessage(error: any, defaultError: string|undefined = undefined, captureOnError = true): string {
    let errorContext = this.getErrorMessageFromGraphqlError(error);

    if (errorContext) {
      const errorMessage = this.formatErrorMessage(errorContext.message, errorContext.context);
      if (errorMessage !== errorContext.message) {
        return errorMessage;
      }
    }

    if (captureOnError) {
      console.error(error);
      captureException(error);
    }

    return defaultError || this.getDefaultErrorMessage();
  }

  public getDefaultErrorMessage(): string {
    return 'Der skete en fejl... Prøv igen...'
  }


}
