import {Injectable} from '@angular/core';
import {Actions, Maybe} from '@core/interfaces/generated/graphql';
import {GroupVoucherService} from '@core/services/groupVoucher.service';

@Injectable({
  providedIn: 'root'
})
export class PageInfoActionsService {

  constructor(
    private groupVoucherService: GroupVoucherService
  ) {
  }

  runActions(actions: Maybe<Actions>[]) {
    return actions?.map(action => {
      if (action?.name === 'addGroupVoucherToCart') {
        this.addGroupVoucherToCart(action.value);
      }
    })
  }

  private addGroupVoucherToCart(value: any) {
    this.groupVoucherService.applyGroupVoucher(value);
  }

}
