import {Pipe, PipeTransform} from '@angular/core';


export function formatPrice(price: string | number | null | undefined) {
  if (!price) {
    return 0;
  }
  price = typeof price === 'string' ? parseInt(price, 10) : price;
  return price / 100;
}

@Pipe({
  name: 'price',
  standalone: true
})
export class PricePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any) {
    return formatPrice(value);
  }
}
