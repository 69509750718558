<ng-container *ngIf="breadcrumbs$|async as breadcrumbs">
  <div *ngIf="breadcrumbs.length !== 0" [class.light]="(breadcrumbStyle$|async) === 'light'"
       class="breadcrumbs container">
    <div class="item-list">
      <span>Du er her: </span>
      <ol class="breadcrumb-list">
        <ng-container *ngFor="let br of breadcrumbs; let last = last; let first = first; trackBy: track()">
          <li *ngIf="getFormattedUrl(br.path) as formattedUrl" [class.first]="first" [class.last]="last">
            <a
              [appRouterContext]="'breadcrumb'"
              [fragment]="formattedUrl.hash"
              [queryParams]="formattedUrl.search"
              [routerLink]="formattedUrl.pathname"
            >{{ br.label }}</a>
          </li>
        </ng-container>
      </ol>
    </div>
  </div>
</ng-container>
