import {Component} from '@angular/core';
import {ModalContainerComponent} from '../modal-container/modal-container.component';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cookie-consent-modal',
  templateUrl: './cookie-consent-modal.component.html',
  styleUrls: ['./cookie-consent-modal.component.scss'],
  standalone: true,
  imports: [
    ModalContainerComponent
  ]
})
export class CookieConsentModalComponent {

  constructor(
    private modalService: NgbActiveModal,
  ) {
  }

  openCookieModal() {
    this.modalService.close();
    (window as any).Cookiebot.renew();
  }

}
