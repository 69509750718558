import {Component, ElementRef, HostBinding, Input, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
  standalone: true,
  imports: [
    NgIf
  ]
})
export class ModalContainerComponent {
  @ViewChild('content') content: ElementRef;

  @Input() title: string;
  @Input() largeModal = false;

  @HostBinding('class.large-modal') get largeModalClass() {
    return this.largeModal;
  }

  constructor(public activeModal: NgbActiveModal) {
  }

}
