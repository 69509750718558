import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Page} from '../interfaces/page';
import {filter, takeUntil} from 'rxjs/operators';
import {UnsubscribeService} from './unsubscribe.service';
import {NavigationTrackingService} from '@core/services/navigation-tracking.service';

export type PageWrapper = {
  page: Page,
  isPathChanged: boolean,
  lastPage: Page | null
}

@Injectable({
  providedIn: 'root'
})
export class PageService {

  page$ = new BehaviorSubject<PageWrapper | null>(null);

  constructor(
    protected unsubscribeService: UnsubscribeService,
    protected navigationTrackingService: NavigationTrackingService,
  ) {
  }

  getCurrentPage() {
    return this.page$.getValue();
  }

  getPageAsync(): Observable<PageWrapper> {
    return this.page$.asObservable().pipe(
      takeUntil(this.unsubscribeService.whileApplicationAlive()),
      filter(page => !!page?.page?.title && !!page?.page?.path),
    ) as any;
  }

  setPage(page: Page) {
    const lastPage = this.page$.getValue()?.page || null;
    this.page$.next({
      page,
      lastPage,
      isPathChanged: lastPage?.path !== page.path
    });
  }

  resetPage() {
    const page = this.page$.getValue()?.page;
    if (!page) {
      return;
    }
    this.navigationTrackingService.resetNavigationId();
    this.page$.next(null);
  }
}
