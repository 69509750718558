import {Injectable} from '@angular/core';
import {GroupVoucherCode, WineProduct} from '@core/interfaces/generated/graphql';
import {Pricing} from '@core/services/product.service';
import {PriceLine, PriceLinePrefix} from '@layout/components/product/price-table/price-table.component';

@Injectable({
  providedIn: 'root'
})
export class ProductPriceService {

  getUnitName(product: WineProduct) {
    return product?.meta?.units?.shortName;
  }

  public priceToPriceLines(price: Pricing, product: WineProduct): PriceLine[] | undefined {
    const lines = [] as PriceLine[];
    const label = `${price.minQty} ${this.getUnitName(product) || ''} pr. stk.`;
    const isDiscounted = !!price.discount || !!price.source;
    const currencyCode = price.currencyCode;
    const showVatText = price.showVatText;

    let defaultPriceIncVat = price?.amountIncVat as string | number | undefined;

    if (!defaultPriceIncVat || !currencyCode) {
      return undefined;
    }

    if (typeof defaultPriceIncVat === 'string') {
      defaultPriceIncVat = parseInt(defaultPriceIncVat, 10);
    }

    let bestPriceIncVat = price.discount?.amountIncVat || defaultPriceIncVat;
    let prefix: PriceLinePrefix | undefined = undefined;

    if (isDiscounted) {
      prefix = price?.source ? ['PRIS', price.source] : ['BEDSTE', 'PRIS'];
    }

    if (isDiscounted && price.strikeThrough && (!price.discount?.strikeThrough || product?.defaultQuantity === 1)) {
      lines.push({
        isDiscounted: false,
        label,
        currencyCode,
        price: defaultPriceIncVat,
        isStriked: true,
        qty: price.minQty,
        unitName: this.getUnitName(product),
      })
    }

    lines.push({
      isDiscounted,
      label,
      currencyCode,
      price: bestPriceIncVat,
      prefix,
      showVatText,
      isStriked: isDiscounted ? !!price.discount?.strikeThrough : price.strikeThrough,
      qty: price.minQty,
      unitName: this.getUnitName(product),
    })

    return lines;
  }

  formatPriceLines(groupVoucher: GroupVoucherCode | null, product: WineProduct, prices: Pricing[]) {
    const lines = [] as PriceLine[];

    const mapped = prices.map(price => this.priceToPriceLines(price, product)).reduce((prev, curr) => {
      if (curr) {
        prev?.push(...curr);
      }
      return prev
    }, []) as PriceLine[];

    const suggestedRetailPrice = (product as WineProduct).suggestedRetailPrice;
    if (suggestedRetailPrice && groupVoucher && groupVoucher.showSuggestedRetailPrice) {
      return this.getSuggestedRetailLines(mapped, suggestedRetailPrice);
    }

    lines.push(...mapped);

    return lines;
  }

  protected getSuggestedRetailLines(mapped: PriceLine[], suggestedRetailPrice: string) {
    const lines = [] as PriceLine[];
    lines.push({
      isDiscounted: false,
      label: 'Vejl. salgspris',
      price: parseInt(suggestedRetailPrice, 10),
      currencyCode: 'DKK'
    });

    const price = mapped.pop();
    if (price) {
      lines.push(price)
    }
    return lines;
  }
}
