<div *ngIf="product?.id" [class.active-top-cta]="product?.topCta?.type" class="w-100">
  @if (product?.topCta?.type) {
    <app-product-top-cta [product]="product" class="hide-this-element-on-desktop"/>
  }
  <p (click)="backClicked()" class="back-btn-style hide-element-on-desktop">
    <fa-icon class="back-btn-style" [icon]="faAngleLeft"></fa-icon> &nbsp;&nbsp;Tilbage
  </p>
  <app-product-tags [tags]="product?.tags ?? []" class="hide-element-on-desktop product-tags-mobile"></app-product-tags>
  <h2 class="hide-element-on-desktop">
    <a [routerLink]="'/' + product?.nodePath" class="mobile-style" rel="bookmark">{{product?.name}}</a>
  </h2>
  <div id="print-but">
    <a (click)="printPDF()">
      <img
        [ngSrc]="this.storyblokService.getImageUrl({filename: 'https://a.storyblok.com/f/106593/28x25/56500217df/printer.png'})"
        height="25" width="28">
    </a>
  </div>
  <div [ngClass]="product?.isB2b ? 'displayCol' : 'row'" class="all-padding position-relative">
    <div
      class="text-center"
      data-rr-shared="true"
      [ngClass]="product?.isB2b ? 'fullWidth' : 'col-lg-4'">
      <app-product-images [product]="product" class="col-md-12-mb-1"></app-product-images>
      <app-product-save-label *ngIf="product?.productImageBadge?.type" [product]="product"/>
    </div>

    <app-product-right [product]="product" class="hide-element-on-desktop w-100 mt-3"></app-product-right>
    <div [ngClass]="product?.isB2b ? 'fullWidth' : 'col-lg-8'">

      <app-product-description [product]="product"></app-product-description>
    </div>
    <div class="container food" data-rr-shared="true" *ngIf="product?.meta?.foods?.length|exists">
      <app-product-foods [product]="product" style="width: 100%"></app-product-foods>
    </div>
    <div class="col-sm-12"> <!-- class="row units"-->
      <app-product-units [product]="product"></app-product-units>
    </div>
  </div>
</div>
