@if (topCta()?.type === 'text') {
  @if (iconSrc()) {
    <img [ngSrc]="iconSrc()" width="26" height="30"/>
  }
  {{ topCta()?.text }}
}
@if (topCta()?.type === 'discount_countdown') {
  <app-countdown-live
    (toggleVisibility)="toggleVisibility($event)"
    [productId]="product()?.vismaProductNumber"
    [date]="lastPrice()?.discount?.to"></app-countdown-live>
}
