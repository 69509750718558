<div
  data-rr-shared="true"
  [backgroundColor]="read_more_background_color()?.value ?? '#ffffff'"
  [maxBodyHeight]="maxHeight()"
  [showFadeOut]="read_more_show_fade_out()"
  [useReadMore]="read_more_active()"
  eventSourceName="storyblok-tinymce"
  app-read-more>
  <div
    [appStoryblok]="_editable()"
    [innerHTML]="contentData() | safeHtml"
    class="tinymceWrapper"
  >
  </div>
</div>
