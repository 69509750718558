<div #ngucarousel class="ngucarousel">
  <ng-content select="[NguCarouselPrev]"></ng-content>
  <div #touchContainer class="ngu-touch-container">
    <div #nguItemsContainer class="ngucarousel-items">
      <ng-container nguCarouselOutlet></ng-container>
    </div>
  </div>
  <div class="nguclearFix"></div>
  <ng-content select="[NguCarouselNext]"></ng-content>
</div>
<ng-content select="[NguCarouselPoint]"></ng-content>
