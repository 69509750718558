<div [appStoryblok]="_editable()">
  @if (viewType() === 'carousel') {
    <app-product-trending-items
      [facetName]="facetName()"
      [facetValue]="facetValue()"
      [maxRecommendations]="maxRecommendations()">
    </app-product-trending-items>
  } @else if (viewType() === 'list') {
    @if (trendingItems$|async; as trendingItems) {
      <app-search-page
        [filter]="trendingItems"
      />
    }
  }
</div>
