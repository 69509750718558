import {Inject, Injectable, makeStateKey, PLATFORM_ID, Signal, StateKey, TransferState} from '@angular/core';
import {
  ApolloDataParams,
  ApolloDataSubscribeParams,
  ApolloParams,
  getQueryKey,
  QueryDataParams
} from '../workers/apollo/apollo.worker.shared';
import {from, mergeMap, Observable, of, Subject, Subscriber, takeWhile} from 'rxjs';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import {isPlatformBrowser} from '@angular/common';
import {
  ApolloQueryResult,
  FetchResult,
  MutationOptions,
  NetworkStatus,
  QueryOptions,
  WatchQueryOptions
} from '@apollo/client/core';
import {ApolloHeadersService} from './apollo-headers.service';
import {UserInitService} from './user-init.service';
import {environment} from '@env/environment';
import {HostnameService} from './hostname.service';
import {toSignal} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class ApolloService {

  private worker: any;

  private dataSubject = new Subject();


  constructor(
    protected transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: object,
    private apolloHeadersService: ApolloHeadersService,
    private userInitService: UserInitService,
    private hostnameService: HostnameService,
  ) {
  }

  querySignal<T = any>(data: QueryDataParams, params?: QueryOptions): Signal<ApolloQueryResult<T> | undefined> {
    return toSignal(this.query(data, params), {manualCleanup: true});
  }

  watchQuerySignal<T = any>(data: QueryDataParams, params?: WatchQueryOptions): Signal<ApolloQueryResult<T> | undefined> {
    const obs = this.watchQuery(data, params).pipe(
      takeWhile((data: any) => data.networkStatus !== NetworkStatus.ready || data.networkStatus !== NetworkStatus.error, true)
    );
    return toSignal(obs, {manualCleanup: true});
  }

  mutationSignal<T = any>(data: QueryDataParams, params?: MutationOptions): Signal<FetchResult<T> | undefined> {
    return toSignal(this.mutate(data, params), {manualCleanup: true});
  }

  queryPromise<T = any>(data: QueryDataParams, params?: QueryOptions): Promise<ApolloQueryResult<T>> {
    return this.query(data, params).toPromise() as Promise<ApolloQueryResult<T>>
  }

  mutatePromise<T = any>(data: QueryDataParams, params?: MutationOptions): Promise<FetchResult<T>> {
    return this.mutate(data, params).toPromise() as Promise<FetchResult<T>>
  }

  watchQuery<T = any>(data: QueryDataParams, params?: WatchQueryOptions): Observable<ApolloQueryResult<T>> {
    return new Observable<any>((subscriber) => {
      const queryKey = getQueryKey('query', data);
      const stateKey = makeStateKey(queryKey);

      const isCachedInClient = this.transferState.hasKey(stateKey) && isPlatformBrowser(this.platformId);
      const apolloParams = {
        query: data,
        queryType: 'watchQuery',
        type: 'subscribe',
        apolloParams: this.applyHeadersToApolloParams(this.apolloHeadersService.getHeaders(), params)
      } as ApolloDataSubscribeParams;

      const sub = this.getDataObserverSubscriber(isCachedInClient, stateKey, apolloParams, subscriber);


      return () => {
        this.worker?.postMessage({
          query: data,
          queryType: 'watchQuery',
          type: 'unsubscribe'
        } as ApolloDataParams)
        sub.unsubscribe();
      }
    })
  }

  protected query<T = any>(data: QueryDataParams, params?: QueryOptions): Observable<ApolloQueryResult<T>> {
    const queryKey = getQueryKey('query', data);
    const stateKey = makeStateKey<ApolloQueryResult<T>>(queryKey);
    const isCachedInClient = this.transferState.hasKey(stateKey) && isPlatformBrowser(this.platformId);

    if (isCachedInClient) {
      return of(this.transferState.get<ApolloQueryResult<T>>(stateKey, undefined as any));
    }

    return this.getDataObserver<T>({
      query: data,
      queryType: 'query',
      type: 'subscribe',
      apolloParams: this.applyHeadersToApolloParams(this.apolloHeadersService.getHeaders(), params)
    }).pipe(
      take(1),
    ) as Observable<ApolloQueryResult<T>>
  }

  protected handleError(error: any) {
    if (error.isError) {
      const errorObj = new Error(error.message);
      errorObj.stack = error.stack;
      errorObj.name = error.name;
      for (let key in error) {
        (errorObj as any)[key] = error[key];
      }
      throw errorObj;
    }
    throw new Error('Apollo service error: ' + error);
  }

  protected getDataObserver<T = any>(data: ApolloDataParams): Observable<T> {
    const queryKey = getQueryKey(data.queryType, data.query);
    return from(this.createWorker()).pipe(
      tap(() => {
        this.worker?.postMessage(data);
      }),
      switchMap(() => this.dataSubject),
      filter((data: any) => queryKey === data.key),
      map((d: any) => {
        if (d.error) {
          this.handleError(d.error);
        }
        return d.data
      })
    )
  }

  protected mutate<T = any>(data: QueryDataParams, params?: MutationOptions): Observable<FetchResult<T>> {
    return this.userInitService.onInitialized().pipe(
      mergeMap((dat) => {
        return this.getDataObserver<T>({
          query: data,
          queryType: 'mutate',
          type: 'subscribe',
          apolloParams: this.applyHeadersToApolloParams(this.apolloHeadersService.getHeaders(), params)
        }) as Observable<{ data: T }>;
      }),
      take(1),
    );
  }

  protected async createWorker() {
    if (this.worker) {
      return;
    }
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../workers/apollo/apollo.worker', import.meta.url), {type: 'module'});
    } else {
      const res = await import('../workers/apollo/apollo.worker.adapter');
      this.worker = new res.ApolloWorkerAdapter();
    }

    const hostname = this.hostnameService.getHostname();
    this.worker?.postMessage({isPreview: this.isPreview(), type: 'init', hostname});

    this.worker?.addEventListener('message', (data: any) => {
      this.dataSubject.next(data?.data);
    })
  }

  protected isPreview() {
    let isPreview = false;
    if (isPlatformBrowser(this.platformId)) {
      if ((window as any).Cypress) {
        return true;
      }
      isPreview = window.location.href.includes(environment.supervin.previewHostname);
    }
    return isPreview;
  }

  protected getDataObserverSubscriber(isCachedInClient: boolean, stateKey: StateKey<any>, apolloParams: ApolloDataSubscribeParams, subscriber: Subscriber<any>) {
    let isFirstTime = true;
    const sub = this.getDataObserver(apolloParams).subscribe(data => {
      const isValidData = Object.keys(data.data || {}).length > 0;
      // We only want to data if there is any
      if (isFirstTime && isCachedInClient && !isValidData) {
        return;
      }
      isFirstTime = false;
      subscriber.next(data);
      this.transferState.set(stateKey, data);
    })

    if (isCachedInClient) {
      const data = this.transferState.get<any>(stateKey, null);
      data.networkStatus = NetworkStatus.loading;
      subscriber.next(data);
    }

    return sub;
  }

  protected applyHeadersToApolloParams(headers: Record<string, string | boolean>, params: ApolloParams | undefined): ApolloParams {
    const parsed = params || {} as ApolloParams;
    if (!parsed.context) {
      parsed.context = {};
    }
    parsed.context['headers'] = headers;
    return parsed;
  }


}
