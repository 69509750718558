@if (rating().label?.length|exists) {
  {{ rating().label }}
}
@if (stars().length > 0) {
  <div *ngFor="let star of stars()" class="stars">
    <app-star-solid-icon *ngIf="star.isSolid" [color]="'#FF9812'" [width]="'20px'" [height]="'20px'"></app-star-solid-icon>
    <app-star-empty-icon *ngIf="showEmptyStars() && !star.isSolid" [color]="'#FF9812'" [width]="'20px'" [height]="'20px'"></app-star-empty-icon>
  </div>
}
@if (stars().length == 0 && !rating().label) {
  {{ rating().rating }} Point
}
