import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-loading-icon',
  templateUrl: './loading-icon.component.html',
  styleUrls: ['./loading-icon.component.scss'],
  standalone: true,
})
export class LoadingIconComponent {

  @Input() grey = false;

  @Input() small = false;

}
