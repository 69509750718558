<div class="container">
  <div class="d-flex justify-content-between">
    <a [appRouterContext]="'header-top'" [routerLink]="'/info/betingelser'">
      <i class="supervin-icon-bottle-check"></i> <span>Supervins unikke vingaranti</span>
    </a>
    <a [appRouterContext]="'header-top'" [routerLink]="'/info/kontakt-os'">
      <app-comments-icon/>
      <span>Kundeservice: 98 92 18 53 | Erhverv: 81 61 16 38</span>
    </a>
    <a [appRouterContext]="'header-top'" [routerLink]="'/info/leveringsinformation'">
      <i class="supervin-icon-truck"></i> <span>Fragt fra 19,- | Fri fragt 1.000,-</span>
    </a>
  </div>
</div>
