import {computed, Inject, Injectable, PLATFORM_ID, signal, Signal, WritableSignal} from '@angular/core';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SplitTestService {

  splitTestsVersions: {
    key: string,
    versions: number
  }[] = [
    {
      key: 'fakeSplitTest',
      versions: 2
    },
  ]

  splitTests: WritableSignal<Record<string, number>> = signal({});

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
  }

  getSplitTestVersion(key: string): Signal<number | null> {
    return computed(() => {
      const localStorageSplitTests = this.getSplitTestsFromLocalStorage();
      if (localStorageSplitTests[key]) {
        return localStorageSplitTests[key];
      }
      const splitTest = this.splitTests()[key];
      if (splitTest) {
        return splitTest;
      }
      return null;
    });
  }

  setSplitTestCases(splitTests?: string | null) {
    if (!splitTests) {
      this.splitTests.set({});
      return;
    }
    this.splitTests.set(JSON.parse(splitTests));
  }

  getSplitTestsFromLocalStorage(): Record<string, number> {
    if (isPlatformServer(this.platformId)) {
      return {};
    }
    const splitTests = localStorage.getItem('splitTests');
    if (!splitTests) {
      return {};
    }
    return JSON.parse(splitTests);
  }
}
