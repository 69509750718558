import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {StoryblokService} from '../../../../../core/services/storyblok.service';
import {StoryblokStylingService} from '../../../../../core/services/storyblok-styling.service';
import {StoryblokDirective} from '../../../../../core/directives/storyblok.directive';
import {StoryblokBaseComponent} from '../storyblok-base.component';

@Component({
  selector: 'app-storyblok-text',
  templateUrl: './storyblok-text.component.html',
  styleUrls: ['./storyblok-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective]
})
export class StoryblokTextComponent extends StoryblokBaseComponent implements OnInit {

  @Input() body: any;
  @Input() _editable: any;
  @Input() textSize: string;
  @Input() transparentBackground: boolean;
  @Input() general_styling: any;

  @HostBinding('class') class: any;

  constructor(
    private storyblokService: StoryblokService,
    private storyblokStylingService: StoryblokStylingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.class = this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12}, this.col);
    this.class.push('text-' + this.textSize);
  }

  get getCols() {
    return this.storyblokService.getCols(this.col);
  }

}
