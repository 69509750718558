<app-product-tags [tags]="product?.tags ?? []" class="hide-element-on-mobile"></app-product-tags>
<h1 class="hide-element-on-mobile">
  <a [routerLink]="'/' + product?.nodePath" rel="bookmark">{{product?.name}}</a>
</h1>

<div class="badges btn-group">
  <app-product-badges [product]="product"></app-product-badges>
</div>
@if (product?.productAuthor) {
  <app-product-author [productAuthor]="product?.productAuthor"></app-product-author>
}
<p class="hide-element-on-desktop body-read-more" style="margin: 0 !important; padding-top: 15px;">
  Beskrivelse:
</p>
<div class="field-name-body long-text-description"
     [class.dont-copy]="!isAdmin()"
     [class.b2b-description]="product?.isB2b"
     [maxBodyHeight]="maxBodyHeight"
     [showFadeOut]="true"
     [useReadMore]="true"
     app-read-more
     eventSourceName="product-description"
>
  <div [innerHTML]="description | replaceImg | safeHtml" data-rr-shared="true" class="field-items"></div>
</div>
