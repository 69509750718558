<ng-container *ngIf="productStoryblok$|async as productStoryblok">
  <div class="row" *ngIf="product?.id">
    <div class="food-title col-md-2 col-sm-12">
      <p>Passer godt til</p>
    </div>
    <div class="food-images col-md-10 col-sm-12">
      <div class="food-image" *ngFor="let food of product?.meta?.foods">
        <a (click)="trackEvent(food?.label)" [appRouterContext]="'food-icon'" [routerLink]="food?.path">
          <img [ngSrc]="productService.getItemIcon(food?.iconName ?? '', productStoryblok)" height="40" width="40"/>
          <p>{{food?.label}}</p>
        </a>
      </div>
    </div>
  </div>
</ng-container>
