import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faComments} from '@fortawesome/free-solid-svg-icons';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {CommentsIconComponent} from '@core/svg-icons/comments-icon.component';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule, FontAwesomeModule, RouterContextDirective, CommentsIconComponent]
})
export class HeaderTopComponent {

  protected readonly faComments = faComments;
}
