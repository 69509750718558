import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserSession} from '../interfaces/generated/graphql';
import {CookieService} from './cookie.service';
import {SuperpayService} from './superpay.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  sessionSubject: BehaviorSubject<UserSession | null | undefined> = new BehaviorSubject<UserSession | null | undefined>(undefined);

  constructor(
    private cookieService: CookieService,
    private superpayService: SuperpayService,
  ) {
  }

  setSession(session?: UserSession | null) {
    this.updateSessionCookies(session);
    this.sessionSubject.next(session);
  }

  getCurrentSession(): UserSession | null | undefined {
    return this.sessionSubject.getValue();
  }

  getSessionObservable() {
    return this.sessionSubject.asObservable();
  }

  updateSessionCookies(session: UserSession | null | undefined) {
    if (session?.sessionId) {
      const expiresDate = this.cookieService.getExpiresDate(365);
      this.cookieService.set('SVSessionID', session.sessionId, expiresDate, '/');
    } else {
      this.cookieService.delete('SVSessionID');
    }

    if (session?.algoliaId) {
      const expiresDate = this.cookieService.getExpiresDate(365);
      this.cookieService.set('_ALGOLIA', session.algoliaId, expiresDate, '/');
    } else {
      this.cookieService.delete('_ALGOLIA');
    }

    if (session?.superpayId) {
      this.superpayService.setSuperpayUUID(session.superpayId);
    } else {
      this.superpayService.removeSuperpayUserCookie();
    }
  }
}
