<a
  (click)="productClicked()"
  [state]="{queryID: product.__queryID}"
  [routerLink]="'/' + product.node_path"
  [additionalContext]="{hitPosition: product.__position}"
  [appRouterContext]="'quickSearchHit'"
  [title]="product.title[0].value"
  class="search-result">
  <div class="hit-image">
    <img [ngSrc]="imgURL" fill [loaderParams]="{height: 50}" [alt]="'mangler billede'">
  </div>
  <div class="pricings">
    <p>
      {{ product.title[0].value }}
    </p>
    <h6>
      @if (!product.hide_price_table) {
        <ng-container *ngIf="priceTableBase as base">
          <small>1 {{ product.price_table.unit }} {{ 'pr. stk.' }} {{ base |currency }} {{ 'DKK' }}</small><br/>
        </ng-container>
        <span
          style="font-size: 83%; font-weight: normal">{{ getDef(product) }} {{ product.price_table.unit }} {{ 'pr. stk.' }}
        </span> <b>{{ priceTableCheap |currency }} {{ 'DKK' }}</b>
      }
    </h6>
  </div>
</a>

