import {
  Component,
  effect,
  EventEmitter,
  Inject,
  input,
  Output,
  PLATFORM_ID,
  signal,
  WritableSignal
} from '@angular/core';
import {isPlatformBrowser, NgClass, NgIf} from '@angular/common';
import {
  InstantsearchBaseRangeSliderComponent
} from '@core/instantsearch/components/instantsearch-base-range-slider.component';
import {NouisliderComponent} from 'ng2-nouislider';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {FormsModule} from '@angular/forms';


@Component({
  selector: 'app-search-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NouisliderComponent, FormsModule]
})
export class RangeSliderComponent extends InstantsearchBaseRangeSliderComponent {

  @Output() inputSubmit: EventEmitter<any> = new EventEmitter();
  protected readonly isPlatformBrowser = isPlatformBrowser;
  hide = input(true);
  range: WritableSignal<number[] | null> = signal(null);

  constructor(
    @Inject(PLATFORM_ID) public platformId: object,
    instantSearchInstance: InstantSearchComponent
  ) {
    super(instantSearchInstance);
    effect(() => {
      const min = this.state().start[0] !== -Infinity ? this.state().start[0] : this.state().range.min;
      const max = this.state().start[1] !== Infinity ? this.state().start[1] : this.state().range.max;
      if (min !== undefined && max !== undefined) {
        this.range.set([min, max]);
      }
    }, {allowSignalWrites: true});
  }

  get config() {
    return {
      connect: true,
      start: this.state().start ?? [0, 0],
      step: 1,
      tooltips: true,
      format: {
        from: (value: number) => Math.round(value),
        to: (value: number) => Math.round(value),
      },
      range: {min: this.state().range.min ?? 0, max: this.state().range.max ?? 0},
      pips: {
        mode: 'count',
        density: 5,
        values: 3,
        stepped: true
      }
    };
  }
}
