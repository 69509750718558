<div [appStoryblok]="_editable()" class="container">
  <div
    [backgroundColor]="read_more_background_color()?.value ?? '#ffffff'"
    [maxBodyHeight]="maxHeight()"
    [showFadeOut]="read_more_show_fade_out()"
    [useReadMore]="read_more_active()"
    app-read-more
    eventSourceName="storyblok-container"
    class="row">
    <ng-container *ngFor="let blok of body(); trackBy: trackByFn">
      <ng-template *ngIf="components" [ngComponentOutlet]="components[blok.component]" [ndcDynamicInputs]="blok">
      </ng-template>
    </ng-container>
  </div>
</div>
