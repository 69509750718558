import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ActivationEnd, ActivationStart, NavigationEnd, Params, Router} from '@angular/router';
import {filter, map, startWith, takeUntil, tap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {environment} from '@env/environment';
import {URLSearchParams} from 'url';
import {UnsubscribeService} from './unsubscribe.service';
import {REQUEST, RESPONSE} from '../../../express.tokens';
import {Request, Response} from 'express';
import {IncomingHttpHeaders} from 'http';
import {toSignal} from '@angular/core/rxjs-interop';

export interface UrlFormatted {
  isRelative: boolean;
  search: Params;
  fullURL: string;
  hash: string | undefined;
  pathname: string;
}

export function getHostnameFromHeaders(headers: IncomingHttpHeaders): string {
  const host = (headers['x-forwarded-host'] || headers['host']) as string;
  return host?.split(':')[0];
}

@Injectable({
  providedIn: 'root'
})
export class RoutingService implements OnDestroy {

  routerSub: Subscription;

  protected activationStarts = 0;

  routerSignal = toSignal(this.onRouteChange().pipe(
    takeUntil(this.unsubscribeService.whileApplicationAlive())
  ));

  constructor(
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
    private router: Router,
    private location: Location,
    protected unsubscribeService: UnsubscribeService,
  ) {
    this.routerSub = this.router.events?.pipe(
      filter((e: any) => e instanceof ActivationEnd),
      takeUntil(this.unsubscribeService.whileApplicationAlive()),
      tap((e: ActivationStart) => {
        this.activationStarts++;
        this.locationPreviewCheck();
      })
    ).subscribe();
  }

  public isFirstRoute() {
    return this.activationStarts === 1;
  }

  public getQueryParams<T extends { [key: string]: any }>(toLowerCase = false): T {
    const params: { [index: string]: any } = {};
    const path = this.location.path(false);
    const query = path.indexOf('?') !== -1 ? path.substring(path.indexOf('?') + 1) : '';

    if (query.length === 0) {
      return {} as T;
    }

    const vars = query.split('&');
    for (const v of vars) {
      const pair = v.split('=');
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    if (toLowerCase) {
      return Object.keys(params).reduce((previousValue, currentValue) => {
        previousValue[currentValue.toLowerCase()] = params[currentValue];
        return previousValue;
      }, {} as Record<string, string>) as T;
    }

    return params as T;
  }

  ngOnDestroy() {
    this.routerSub?.unsubscribe();
  }

  getUrlFormattedObjectByPath(path: string): UrlFormatted | undefined {
    if (!path) {
      return undefined;
    }

    const formattedPath = this.formatUrl(path);
    const isAbsolute = this.isAbsoluteURL(formattedPath);

    const urlObj = isAbsolute ? new URL(path) : new URL(`https://${environment.supervin.frontendHostname}${formattedPath}`);
    const searchParams = this.mapSearchParamsToObject(urlObj.searchParams);
    const hash = urlObj.hash.substring(1)
    return {
      fullURL: formattedPath,
      pathname: urlObj.pathname,
      hash: hash || undefined,
      isRelative: !isAbsolute,
      search: searchParams
    }
  }

  public onRouteChange(ignoreQueryChange = true, ignoreAnchorChange = true): Observable<string> {
    const urlFn = (urlArg?: string) => {
      return this.getURL(urlArg, !ignoreAnchorChange, !ignoreQueryChange);
    };
    let lastURL = urlFn();
    return this.router.events.pipe(
      filter(value => {
        if (!(value instanceof NavigationEnd)) {
          return false;
        }
        const newUrl = urlFn(value.url);
        if (lastURL !== undefined && lastURL === newUrl) {
          return false;
        }
        lastURL = newUrl;

        return true;
      }),
      map(() => urlFn()),
      startWith(lastURL)
    );
  }

  getURL(url?: string, includeHash = false, includeQuery = false, decodeUrl = true) {
    if (!url) {
      url = this.router.url;
    }

    if (!includeQuery) {
      // Remove query from url
      const hash = url.split('#')[1];
      url = url.split('?')[0]?.substr(1) + (hash ? `#${hash}` : '') ?? '';
    }

    if (!includeHash) {
      // Remove hash from url
      url = url.split('#')[0] ?? '';
    }

    if (decodeUrl) {
      url = url
        .replace('%C3%A6', 'æ')
        .replace('%C3%86', 'Æ')
        .replace('%C3%B8', 'ø')
        .replace('%C3%98', 'Ø')
        .replace('%C3%A5', 'å')
        .replace('%C3%85', 'Å');
    }

    return url;
  }

  isAbsoluteURL(urlString: string): boolean {
    return urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0
  }

  protected mapSearchParamsToObject(searchParams: URLSearchParams): Record<any, string> {
    return Array.from(searchParams.keys()).reduce((p: any, c: string) => {
      p[c] = searchParams.get(c);
      return p;
    }, {});
  }

  protected isHostnameEqualToCurrent(path: string): boolean {
    const pattern = new RegExp(`(http|https):\/\/${environment.supervin.frontendHostname}.*`);
    return pattern.test(path);
  }

  protected formatUrl(path: string): string {
    let formattedPath = path;

    if (this.isHostnameEqualToCurrent(path)) {
      const url = new URL(path);
      formattedPath = `${url.pathname}${url.search}${url.hash}`
    }

    const isAbsolute = this.isAbsoluteURL(formattedPath);

    if (!isAbsolute && formattedPath.indexOf('/') !== 0) {
      formattedPath = '/' + formattedPath;
    }
    return formattedPath;
  }

  public get hostname(): string {
    if (this.request?.headers) {
      const headers = this.request.headers;
      return getHostnameFromHeaders(headers);
    }
    if (window?.location?.hostname) {
      const location = window.location;
      const host = location?.host?.split(':')?.[0];
      return host ?? '';
    }
    return '';
  }

  protected locationPreviewCheck() {
    if (this.request) {
      this.locationPreviewCheckServerSide();
    } else if (window?.location) {
      this.locationPreviewCheckClientSide();
    }
  }

  protected locationPreviewCheckServerSide() {
    const host = this.hostname;
    if (!host) {
      return;
    }
    const path = this.request.url.split('/').slice(1).join('/');
    const isPreviewHost = host === environment.supervin.previewHostname;
    const hasPreviewQuery = this.request.query['preview'] === 'true';
    const hasStoryblokQuery = this.request.query['_storyblok_release'] !== undefined;
    if (isPreviewHost && !(hasPreviewQuery || hasStoryblokQuery)) {
      this.response.redirect(`https://${environment.supervin.frontendHostname}/${path}`);
    }
  }

  protected locationPreviewCheckClientSide() {
    const host = this.hostname;
    if (!host) {
      return;
    }
    const path = location.pathname + location.search + location.hash;
    const isPreviewHost = host === environment.supervin.previewHostname;
    const hasPreviewQuery = this.getQueryParams<{ preview?: string }>().preview === 'true';
    const hasStoryblokQuery = this.getQueryParams<{ _storyblok_release?: string }>()?._storyblok_release !== undefined;
    if (isPreviewHost && !(hasPreviewQuery || hasStoryblokQuery)) {
      location.href = `https://${environment.supervin.frontendHostname}${path}`;
    }
  }

}
