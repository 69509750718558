import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

export interface CountdownResult {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  distance: number;
}

export interface CountdownDTO {
  callback: (result: CountdownResult) => any;
  target: Date;
}

@Injectable({
  providedIn: 'root'
})
export class CountdownService {

  protected interval: any;
  protected data: { [key: string]: CountdownDTO } = {};


  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
  }

  getKey(id: string) {
    let key = id;
    let identify = 1;
    do {
      key = 'countdown-live-' + id + '-' + identify;
      identify++;
    } while (this.data[key]);
    return key;
  }

  addCountdown(key: string, data: CountdownDTO) {
    this.data[key] = data;
    this.startIfNotStarted();
    this.executeSingleCountdown(key);
  }

  removeCountdown(key: string) {
    delete this.data[key];
    this.clearIfEmpty();
  }

  protected startIfNotStarted() {
    if (this.interval) {
      return;
    }
    this.startInterval();
  }

  protected getCountdownDate(target: any) {
    if (typeof target === 'string') {
      return new Date(target).getTime();
    }
    return target.getTime();
  }

  public calculateDistance(target: Date) {
    const result = {} as CountdownResult;

    const now = new Date().getTime();
    const countdownDate = this.getCountdownDate(target);

    result.distance = countdownDate - now;
    result.days = Math.floor(result.distance / (1000 * 60 * 60 * 24));
    result.hours = Math.floor((result.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    result.minutes = Math.floor((result.distance % (1000 * 60 * 60)) / (1000 * 60));
    result.seconds = Math.floor((result.distance % (1000 * 60)) / 1000);
    return result;
  }

  protected startInterval() {
    this.clearInterval();
    if (isPlatformBrowser(this.platformId)) {
      this.interval = setInterval(() => {
        this.runInterval();
      }, 1000);
    }
  }

  protected executeSingleCountdown(key: string) {
    const val = this.data[key];
    const result = this.calculateDistance(val.target);
    val.callback(result);
  }

  protected runInterval() {
    this.clearIfEmpty();
    const keys = Object.keys(this.data);

    for (const key of keys) {
      this.executeSingleCountdown(key);
    }
  }

  protected clearIfEmpty() {
    if (Object.keys(this.data ?? {}).length === 0) {
      this.clearInterval();
    }
  }

  protected clearInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

}
