<app-instantsearch [attr.data-rr-shared]="!isResultsVisible()" (changeEvent)="stateChange($event)" [config]="searchConfig">
  <app-searchbox
    #searchBox
    (displaySearchContainer)="displaySearchContainer($event)"
    (searchInputIsOnFocus)="setSearchboxFocus($event)">
  </app-searchbox>
  <app-hits-container
    *ngIf="state() as st"
    (disableResultsVisibility)="disableResultsVisibility()"
    [searchQuery]="searchBox?.inputElement?.nativeElement?.value || ''"
    [state]="st"
    [hits]="hits()"
    [class.d-none]="!isResultsVisible()"
  ></app-hits-container>
</app-instantsearch>
