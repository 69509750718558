import {Attribute, Directive, ElementRef, input, Renderer2} from '@angular/core';
import {ActivatedRoute, NavigationExtras, Router, RouterLink, UrlTree} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {clone} from 'lodash-es';
import {TrackEventService} from '@core/services/track-event.service';
import {PageService} from '@core/services/page.service';

@Directive({
  selector: '[appRouterContext]',
  standalone: true
})
export class RouterContextDirective extends RouterLink {

  appRouterContext = input.required<string>();
  additionalContext = input<Record<string, string | number>>();
  resetPage = input<boolean>();

  constructor(
    private trackEventService: TrackEventService,
    private pageService: PageService,
    router: Router,
    route: ActivatedRoute,
    @Attribute('tabindex') tabIndexAttribute: string | null | undefined,
    renderer: Renderer2,
    el: ElementRef,
    locationStrategy?: LocationStrategy,
  ) {
    const routerCopy = clone(router);
    const navigateByUrl = routerCopy.navigateByUrl;
    routerCopy.navigateByUrl = (url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> => {
      this.trackLinkClick();
      return navigateByUrl.call(router, url, extras);
    }
    super(routerCopy, route, tabIndexAttribute, renderer, el, locationStrategy);
  }

  private trackLinkClick() {
    if (this.resetPage()) {
      this.pageService.resetPage();
    }
    this.trackEventService.routerContext = {
      eventName: this.appRouterContext(),
      additionalContext: this.additionalContext()
    };
  }

}
