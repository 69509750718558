import {Injectable, Signal} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {toSignal} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class UserInitService {
  protected isInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  protected initializedSignal: Signal<boolean | undefined>

  constructor() {
    this.initializedSignal = toSignal(this.onInitialized());
  }

  public get onInitializedSignal() {
    return this.initializedSignal;
  }

  public onInitialized() {
    return this.isInitialized.asObservable().pipe(
      filter(isInitialized => isInitialized),
    );
  }

  public onInitializedPromise() {
    return this.onInitialized().pipe(
      filter(isInitialized => isInitialized),
      take(1)
    ).toPromise();
  }

  public setInitialized() {
    if (this.isInitialized.value) {
      return;
    }
    this.isInitialized.next(true);
  }

}
