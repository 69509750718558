<a
  *ngIf="url && !showAnnounceKit()"
  [routerLink]="url"
  class="menu-item"
  [class.sub-menu-active]="showSubMenu() && hasSubMenu"
  [appRouterContext]="'headerMenu'"
  [additionalContext]="getAdditionalContext(name)"
  (click)="closeMenu()"
>
  <span>{{name}}
    <fa-icon *ngIf="hasSubMenu" [icon]="faAngleDown"></fa-icon>
  </span>
  <div class="sub-menu" *ngIf="hasSubMenu">
    <ng-content></ng-content>
  </div>
</a>
<a
  *ngIf="url && showAnnounceKit()"
  class="menu-item"
>
  <span
    (click)="announceKitClick($event)"
    [appClickTracking]="'announcekitClick'">
    {{name}}
    <app-menu-announcekit #announcekitComponent/>
  </span>
  <div *ngIf="hasSubMenu" class="sub-menu">
    <ng-content></ng-content>
  </div>
</a>
<div
  *ngIf="!url"
  class="menu-item"
  [class.sub-menu-active]="showSubMenu() && hasSubMenu"
  (click)="closeMenu()"
  [additionalContext]="{name}"
  [appClickTracking]="'headerMenuClick'"
>
  <span (click)="menuItemClick()">{{name}}
    <fa-icon *ngIf="hasSubMenu" [icon]="faAngleDown"></fa-icon>
  </span>
  <div class="sub-menu" *ngIf="hasSubMenu">
    <ng-content></ng-content>
  </div>
</div>
