<div [appStoryblok]="_editable()" class="wrapper">
  <div [style.width]="icon_width() + 'px'">
    <img [ngSrc]="icon().filename" alt="icon" fill>
  </div>
  <div class="ms-md-3">
    @if (title()) {
      <p class="title">{{ title() }}</p>
    }
    @if (textHtml()) {
      <p class="text">{{ textHtml() }}</p>
    }
  </div>
</div>
