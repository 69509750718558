import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  Inject,
  input,
  Input,
  OnInit,
  PLATFORM_ID,
  signal
} from '@angular/core';
import {ListPage, ListPageTypeTypes,} from '@core/interfaces/generated/graphql';
import {SearchPageComponent} from '../../../page/components/search-page/search-page.component';
import {GroupVoucherService} from '@core/services/groupVoucher.service';
import {AsyncPipe, isPlatformBrowser, NgIf} from '@angular/common';
import {AlgoliaService, CustomProductListFilter} from '@core/services/algolia.service';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {AlgoliaFacetFilter} from '@core/types/algolia-filter';
import {LoadingIconComponent} from '@layout/components/general/loading-icon/loading-icon.component';
import {TrackEventService} from '@core/services/track-event.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-storyblok-algolia-filter',
  templateUrl: './storyblok-algolia-filter.component.html',
  styleUrls: ['./storyblok-algolia-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SearchPageComponent, NgIf, AsyncPipe, LoadingIconComponent]
})
export class StoryblokAlgoliaFilterComponent extends StoryblokBaseComponent implements OnInit {

  filter = signal<ListPage | null>(null);
  loading = signal(true);

  @Input() elementId: any;
  @Input() general_styling: any;
  @Input() filter_country: string[];
  @Input() filter_facet_types: string[];
  @Input() filter_district: string[];
  @Input() filter_area: string[];
  @Input() filter_producer: string[];
  @Input() filter_field_grapes: string[];
  @Input() filter_field_klassikere: string[];
  @Input() filter_not_wine: string[];
  @Input() filter_categories: string[];
  @Input() filter_field_non_alcoholic: string[];
  @Input() filter_on_discount: boolean;
  @Input() filter_field_food: string[];
  @Input() filter_visma_ids: string[];
  @Input() filter_sparkling_type: string[];
  @Input() filter_port_type: string[];
  @Input() filter_reviewer: string[];
  @HostBinding('class') class: any;

  only_show_leftovers = input<boolean>(false);
  voucher_list = input<boolean>(false);
  hide_sold_out = input<boolean>(false);
  groupVoucherSignal = this.groupVoucherService.groupVoucherSignal;

  @Input()
  set id(value: any) {
    this.hostId = value;
  }

  @HostBinding('attr.id') hostId: any;

  filters = computed(() => {
    const algoliaFacets = this.algoliaService.algoliaFacets();
    if (this.voucher_list() && !isPlatformBrowser(this.platformId)) {
      return;
    }
    return this.generateFilter(algoliaFacets);
  });

  loadingGroupVoucher = computed(() => this.voucher_list() && this.groupVoucherSignal().loading);

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private algoliaService: AlgoliaService,
    private groupVoucherService: GroupVoucherService,
    private trackEventService: TrackEventService,
    private modalService: NgbModal
  ) {
    super()
  }

  ngOnInit(): void {
    this.class = 'col-12';
  }

  openGroupVoucherModal() {
    this.trackEventService.trackClickEvent('voucher_modal_open', {eventSourceName: 'storyblok-algolia-filter'});
    import('../../../../layout/components/general/header/components/header-middle/components/header-middle-voucher-modal/header-middle-voucher-modal.component').then(comp => {
      this.modalService.open(comp.HeaderMiddleVoucherModalComponent);
    });
  }

  generateFilter(filters: AlgoliaFacetFilter[]): CustomProductListFilter {

    const types = [
      ...this.inputToFilter(filters, 'filter_country', 'country'),
      ...this.inputToFilter(filters, 'filter_facet_types', 'facet_types'),
      ...this.inputToFilter(filters, 'filter_district', 'district'),
      ...this.inputToFilter(filters, 'filter_area', 'area'),
      ...this.inputToFilter(filters, 'filter_producer', 'producer'),
      ...this.inputToFilter(filters, 'filter_field_grapes', 'field_grapes'),
      ...this.inputToFilter(filters, 'filter_field_klassikere', 'field_klassikere'),
      ...this.inputToFilter(filters, 'filter_field_non_alcoholic', 'field_non_alcoholic'),
      ...this.inputToFilter(filters, 'filter_on_discount', 'on_discount'),
      ...this.inputToFilter(filters, 'filter_not_wine', 'field_non_wine'),
      ...this.inputToFilter(filters, 'filter_categories', 'categories'),
      ...this.inputToFilter(filters, 'filter_field_food', 'field_food'),
      ...this.inputToFilter(filters, 'filter_visma_ids', 'visma_id'),
      ...this.inputToFilter(filters, 'filter_sparkling_type', 'sparkling_type'),
      ...this.inputToFilter(filters, 'filter_port_type', 'port_type'),
      ...this.inputToFilter(filters, 'filter_reviewer', 'wine_ratings.reviewer'),
    ];

    if (this.hide_sold_out()) {
      types.push({
        attribute: 'commerce_stock_bool',
        name: [1] as any
      });
    }

    if (this.only_show_leftovers()) {
      types.push({
        attribute: 'is_leftover',
        name: [true] as any
      });
    }

    const filter: CustomProductListFilter = {
      objectIds: [],
      types,
      title: '',
      storyblok: undefined
    };

    if (!this.voucher_list()) {
      return filter;
    }
    const voucherFilter = this.groupVoucherService.voucherSearchPageFilter();
    return {
      ...filter,
      objectIds: voucherFilter.objectIds ?? []
    }
  }

  protected inputToFilter(filters: AlgoliaFacetFilter[], key: keyof this, attribute: string): ListPageTypeTypes[] {
    const facet = filters.find(value => value.algoliaAttribute === attribute);
    if (!facet) {
      return [];
    }
    if (facet.instantSearchType === 'toggle') {
      return [
        {
          attribute,
          name: this[key] as any
        }
      ];
    } else {
      let value = this[key] as string | string[];
      if (typeof value === 'string') {
        value = value.split(',').map(d => d.trim());
      }
      return value?.map(name => ({attribute, name})) ?? [];
    }
  }
}
