<div class="searchbox-wrapper">
  <form (submit)="handleSubmit($event)" class="position-relative">
    <div class="input-group mb-3">
      <span class="input-group-text">VF</span>
      <input
        (input)="handleChange($event)"
        [placeholder]="placeholder"
        [value]="query"
        class="form-control"
        max="999"
        spellcheck="false"
        type="number"
        id="vf-searchbox"
      />
    </div>
    <div class="icons">
      <fa-icon (click)="handleReset($event)" *ngIf="query" class="reset-icon" [icon]="faTimes"></fa-icon>
      <fa-icon (click)="handleSubmit($event)" class="search-icon" [icon]="faSearch"></fa-icon>
    </div>
  </form>
</div>
