import {Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = signal(false);

  printDocument() {
    if (this.isPrinting()) {
      window.print();
      return;
    }
    this.isPrinting.set(true);
  }

  setIsPrinting(value: boolean) {
    this.isPrinting.set(value)
  }
}
