import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgFor, NgIf, NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-rating-review-filter',
  templateUrl: './rating-review-filter.component.html',
  styleUrls: ['./rating-review-filter.component.scss'],
  standalone: true,
  imports: [NgOptimizedImage, NgIf, NgFor]
})
export class RatingReviewFilterComponent {

  @Input() title: string;
  @Input() checked: boolean = false;

  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();

  onClick() {
    this.checked = !this.checked;
    this.setFilter(this.checked);
  }

  setFilter(checked: boolean) {
    this.checkedChange.emit(checked);
  }
}
