import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-heart-solid-icon',
  styles: `
    :host {
      display: inline-block;
      line-height: 0;
    }
  `,
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      [attr.fill]="fill">
      <path
        d="M21 8.25C21 5.765 18.901 3.75 16.312 3.75C14.377 3.75 12.715 4.876 12 6.483C11.285 4.876 9.623 3.75 7.687 3.75C5.1 3.75 3 5.765 3 8.25C3 15.47 12 20.25 12 20.25C12 20.25 21 15.47 21 8.25Z"
        [attr.stroke]="stroke"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
  `,
  standalone: true
})
export class HeartSolidIconComponent {
  @Input() width: string = '24px';
  @Input() height: string = '24px';
  @Input() stroke: string = '#5C0A31';
  @Input() fill: string = '#5C0A31';
}
