import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-star-empty-icon',
  styles: `
    :host {
      display: inline-block;
      line-height: 0;
    }
  `,
  template: `
    <svg
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 24 24"
      fill="none">
      <path d="M11.48 3.499C11.5223 3.39583 11.5944 3.30758 11.687 3.24546C11.7796 3.18334 11.8885 3.15018 12 3.15018C12.1115 3.15018 12.2205 3.18334 12.3131 3.24546C12.4057 3.30758 12.4777 3.39583 12.52 3.499L14.645 8.61C14.6848 8.70564 14.7502 8.78844 14.834 8.84931C14.9178 8.91018 15.0168 8.94675 15.12 8.955L20.638 9.397C21.137 9.437 21.339 10.06 20.959 10.385L16.755 13.987C16.6765 14.0542 16.618 14.1417 16.5859 14.24C16.5538 14.3382 16.5493 14.4434 16.573 14.544L17.858 19.929C17.8839 20.037 17.8771 20.1503 17.8386 20.2545C17.8001 20.3587 17.7316 20.4491 17.6417 20.5144C17.5518 20.5797 17.4446 20.6169 17.3336 20.6212C17.2226 20.6256 17.1128 20.597 17.018 20.539L12.293 17.654C12.2048 17.6001 12.1034 17.5716 12 17.5716C11.8967 17.5716 11.7953 17.6001 11.707 17.654L6.98204 20.54C6.8873 20.598 6.7775 20.6266 6.6665 20.6222C6.5555 20.6179 6.44829 20.5807 6.35841 20.5154C6.26853 20.4501 6.2 20.3597 6.16149 20.2555C6.12298 20.1513 6.11621 20.038 6.14204 19.93L7.42704 14.544C7.45087 14.4434 7.44649 14.3382 7.41438 14.2399C7.38227 14.1416 7.32368 14.0541 7.24504 13.987L3.04104 10.385C2.95638 10.3128 2.89505 10.2172 2.8648 10.1101C2.83455 10.0031 2.83676 9.88945 2.87113 9.78365C2.9055 9.67785 2.9705 9.58464 3.05789 9.51581C3.14528 9.44698 3.25114 9.40563 3.36204 9.397L8.88004 8.955C8.98329 8.94675 9.08226 8.91018 9.16607 8.84931C9.24987 8.78844 9.31526 8.70564 9.35504 8.61L11.48 3.499Z"
            [attr.stroke]="color"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"/>
    </svg>
  `,
  standalone: true
})
export class StarEmptyIconComponent {
  @Input() width: string = '24px';
  @Input() height: string = '24px';
  @Input() color: string = '#FDCA00';
}
