<h3 class="container" *ngIf="errorMessage">{{errorMessage}}</h3>

<ng-container *ngIf="isAdmin()">
  <div class="container">
    <div *ngIf="productUpdated" class="product-updated">
      <p>
        Produktet {{currentProduct?.name}} er blevet opdateret.
        <span (click)="removeUpdated()">X</span>
      </p>
    </div>
    <span (click)="copyToClipboard(currentProduct?.vismaProductNumber)" class="btn btn-primary mr-2">
        Visma ID: {{currentProduct?.vismaProductNumber}}
      </span>
    <span (click)="copyToClipboard(currentProduct?.sku)" class="btn btn-primary mr-2">
        SKU: {{currentProduct?.sku ?? 'No SKU'}}
      </span>
    <span (click)="copyToClipboard(stock())" *ngIf="stock()"
          class="btn btn-primary mr-2">Stock: {{ stock() }}</span>
    <a class="btn btn-primary mr-2" type="button" [href]="getProductPreviewURL(currentProduct)">Preview</a>
    <a class="btn btn-primary mr-2" type="button" [href]="getProductEditUrl(currentProduct?.id)">Edit</a>
  </div>
</ng-container>
<article class="container product-display" *ngIf="currentProduct?.id|exists">
  <div class="d-flex col-direction">
    <app-product-left [product]="currentProduct"></app-product-left>
    <app-product-right [product]="currentProduct" class="hide-element-on-mobile"></app-product-right>
  </div>
</article>

<div class="container">
  <div class="row">
    <div class="d-flex justify-content-center matching-products-title">
      <span *ngIf="currentProduct && showRecommendations" class="font-serif">Lignende produkter</span>
      <span *ngIf="showTrendingProducts" class="font-serif">Mest populære lige nu</span>
    </div>
    <app-product-recommendations *ngIf="currentProduct && showRecommendations"
                                 (hitsChange)="recommendationsHitsChange($event)"
                                 [objectID]="currentProduct.id"></app-product-recommendations>
    <app-product-trending-items *ngIf="showTrendingProducts"></app-product-trending-items>
  </div>
</div>
<app-print-pdf *ngIf="printPdf.isPrinting() && currentProduct" [baPercentage]="baPercentage()"
               [product]="currentProduct"></app-print-pdf>
<ng-template #loading>
  <app-loading-icon class="loading-fullscreen"></app-loading-icon>
</ng-template>
<app-product-sticky-buy-bar *ngIf="isBrowser && isMobile && currentProduct"
                            [product]="currentProduct"></app-product-sticky-buy-bar>
