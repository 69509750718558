import {Directive, HostListener, input} from '@angular/core';
import {TrackEventService} from '@core/services/track-event.service';

@Directive({
  selector: '[appClickTracking]',
  standalone: true
})
export class ClickTrackingDirective {

  appClickTracking = input.required<string>();
  additionalContext = input<Record<string, string | number>>();

  constructor(
    private trackEventService: TrackEventService
  ) {
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    this.trackEventService.trackClickEvent(this.appClickTracking(), this.additionalContext() ?? {});
  }

}
