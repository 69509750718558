<div (mouseleave)="setActiveSubMenuItem()" class="menu-wrapper">
  <div *ngFor="let column of menuStructure()?.sub_links; trackBy: groupedMenuTracker" class="menu-col">
    <ng-container *ngFor="let item of column.links; trackBy: menuItemTracker">
      <div *ngIf="getMenuType(item) === 'subMenu'">
        <div (click)="subMenuItemClick($event, item)"
             (mouseenter)="subMenuItemMouseEnter(item)"
             (mouseleave)="subMenuItemMouseLeave()"
             [class.active]="activeSubMenuItem() === item"
             [additionalContext]="getAdditionalContext(item)"
             [appClickTracking]="'headerSubMenu'"
             class="sub-menu">
          <div class="menu-item">
            <img *ngIf="item.Image?.filename" [ngSrc]="item.Image?.filename"
                 height="50"
                 width="50"/>
            <span style="flex-grow: 1">{{item.Name}}</span>
            <fa-icon *ngIf="activeSubMenuItem() === item" class="hide-element-on-desktop" [icon]="faAngleUp"></fa-icon>
            <fa-icon *ngIf="activeSubMenuItem() !== item" class="hide-element-on-desktop" [icon]="faAngleDown"></fa-icon>
            <fa-icon class="hide-element-on-mobile" [icon]="faAngleRight"></fa-icon>
          </div>
        </div>
        <app-header-menu-sub-menu
          *ngIf="activeSubMenuItem() === item"
          [menuStructure]="activeSubMenuItem()"
          [pathWithLabels]="getPath(item)">
        </app-header-menu-sub-menu>
      </div>
      <ng-container *ngIf="getMenuType(item) === 'menu'">
        <a
          (click)="closeMenu()"
          *ngIf="!isExternalURL(item.Link.url)"
          [additionalContext]="getAdditionalContext(item)"
          [appRouterContext]="'headerSubMenu'"
          [routerLink]="item.Link.url"
          class="menu-item">
          <img *ngIf="item.Image?.filename" [ngSrc]="item.Image?.filename"
               height="50"
               width="50"/>
          {{item.Name}}
        </a>
        <a
          *ngIf="isExternalURL(item.Link.url)"
          [additionalContext]="getAdditionalContext(item, {externalLink: item.Link.url})"
          [appClickTracking]="'headerSubMenu'"
          [href]="item.Link.url"
          class="menu-item">
          <img *ngIf="item.Image?.filename" [ngSrc]="item.Image?.filename"
               height="50"
               width="50"/>
          {{item.Name}}
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>
