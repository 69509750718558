import {Component, computed, EventEmitter, Output} from '@angular/core';
import {UserService} from '@core/services/user.service';
import {QuicksearchComponent} from '../quicksearch/quicksearch.component';
import {RouterModule} from '@angular/router';

import {HeaderMiddleVoucherComponent} from './components/header-middle-voucher/header-middle-voucher.component';
import {CartSmallComponent} from '../cart-small/cart-small.component';
import {AsyncPipe, NgIf, NgOptimizedImage} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StoryblokService} from '@core/services/storyblok.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {TrustpilotComponent} from '@layout/components/general/trustpilot/trustpilot.component';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {TrackEventService} from '@core/services/track-event.service';
import {UserIconComponent} from '@core/svg-icons/user-icon.component';
import {GiftIconComponent} from '@core/svg-icons/gift-icon.component';
import {CheckMarkerIconComponent} from '@core/svg-icons/check-marker-icon.component';
import {RoutingService} from '@core/services/routing.service';


@Component({
  selector: 'app-header-middle',
  templateUrl: './header-middle.component.html',
  styleUrls: ['./header-middle.component.scss'],
  standalone: true,
  imports: [QuicksearchComponent, RouterModule, NgIf, AsyncPipe, HeaderMiddleVoucherComponent, CartSmallComponent, NgOptimizedImage, FontAwesomeModule, TrustpilotComponent, RouterContextDirective, UserIconComponent, GiftIconComponent, CheckMarkerIconComponent]
})
export class HeaderMiddleComponent {

  userSignal = this.userService.userSignal;

  customerFirstname = this.userService.customerFirstnameSignal;

  isUserUniverse = computed(() => this.routingService.routerSignal()?.includes('kundeportal'));
  isTastingEvent = computed(() => this.routingService.routerSignal()?.startsWith('event'));


  @Output() toggleMenu: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    public storyblokService: StoryblokService,
    private trackEventService: TrackEventService,
    private routingService: RoutingService
  ) {
  }

  openCustomerUniverseModal() {
    if (this.userService.currentUser()?.id) {
      this.openWelcomeModal();
      return;
    }
    this.openLoginModal();
  }

  openLoginModal() {
    this.trackEventService.trackClickEvent('login_modal_open');
    import('../../../../modals/login-modal/login-modal.component').then(comp => {
      this.modalService.open(comp.LoginModalComponent);
    })
  }

  openWelcomeModal() {
    this.trackEventService.trackClickEvent('welcome_modal_open');
    import('../../../../modals/welcome-modal/welcome-modal.component').then(comp => {
      this.modalService.open(comp.WelcomeModalComponent);
    })
  }

  protected readonly faBars = faBars;
}
